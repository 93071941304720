import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class MitService {
    constructor(private http: HttpClient) { }
    roleId = localStorage.getItem(`${environment.appName}` + '_role');

    getDownload(page, search, category, tshirt, attendee_type, membership_period): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excel?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type + `&membership_period=` + membership_period, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getDashboard(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user));
    }

    getDownloadswim(page, search, category, tshirt, attendee_type, membership_period): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type + `&membership_period=` + membership_period, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getDownloadpending(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelpending?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getDownloadpendingswim(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelpendingswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getDownloadoutdate(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-exceloutdate?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

      
    getDownloadoutdateswim(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-exceloutdateswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getDownloadapprove(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelapprove?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }


    getDownloadapproveswim(page, search, category, tshirt, attendee_type): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelapproveswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }
























    
    getBeneficiary(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getBeneficiary/` + id).pipe(map(user => user));
    }

    getUsersingle(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/userlist/` + id).pipe(map(user => user));
    }


    getadhocSingle(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/adhoc/` + id).pipe(map(user => user));
    }

    getUserdropdown(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/userlist`).pipe(map(user => user));
    }

    getUserlist(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-list?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getoutdated(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getOutdatedmarena?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getoutdatedswim(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getOutdatedswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getapproveswim(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getApprovedswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getUserlistpending(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getPendingmarena?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getUserlistapproved(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getApprovedmarena?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getMarathon(page, search, category, tshirt, attendee_type, membership_period): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-list?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type + `&membership_period=` + membership_period).pipe(map(user => user));
    }

    getSwim(page, search, category, tshirt, attendee_type, membership_period): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-listswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type + `&membership_period=` + membership_period).pipe(map(user => user));
    }

    getPayment(page, search, roleId, filter_date, filter_status): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getpayment?page=` + page + '&search=' + search + '&roleId=' + roleId + '&date=' + filter_date + '&status=' + filter_status).pipe(map(user => user));
    }

    getMarathonCancel(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getCancel?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getMarathonAccept(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getAccept?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getMarathonRefund(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getRefund?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getMarathonPending(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getPendingmarena?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }


    getMarathonPendingswim(page, search, category, tshirt, attendee_type): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getPendingswim?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type).pipe(map(user => user));
    }

    getCategory() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category`).pipe(map(user => user));
    }


    getmarenaCategory() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getmarenacategories`).pipe(map(user => user));
    }

    getswimCategory() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getswimcategories`).pipe(map(user => user));
    }

    getSent() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sent-mail`).pipe(map(user => user));
    }
    submitEmail(subject, content, attachment) {
        const formData: FormData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment, attachment.name);
        }
        formData.append('subject', subject);
        formData.append('content', content);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/send-mail`, formData).pipe(map(user => { return user }));
    }

    product_download(stock_filter, sku_filter, main_cat_filter, sub_cat_filter): Promise<Blob> {
        var formData = {};
        formData['stock_filter'] = stock_filter;
        formData['sku_filter'] = sku_filter;
        formData['main_cat_filter'] = main_cat_filter;
        formData['sub_cat_filter'] = sub_cat_filter;

        formData['download'] = 1;

        const file = this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/excel/product_download?` + $.param(formData),
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    getCategoryId(ids) {
        var formData = {};
        formData = {
            'ids': ids
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getcategories`, formData).pipe(map(user => { return user }));
    }

  
    getAmount() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/gettotalCount`).pipe(map(user => user));
    }

    getRegisterView(id: any, roleId: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-lists?id=` + id + `&roleId=` + roleId).pipe(map(user => user));
    }

    getRegisterView1(id: any, roleId: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/viewPendingandapproved?id=` + id + `&roleId=` + roleId).pipe(map(user => user));
    }

    getPaymentView(orderID: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getpaymentsingle?orderID=` + orderID).pipe(map(user => user));
    }



    getOldRecords(page, search, tshirt): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/old-records?page=` + page + '&search=' + search + `&tshirt=` + tshirt).pipe(map(user => user));
    }


    

    gethomeDash(): Observable<any> {
       
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/gethomecount?roleId=`+this.roleId).pipe(map(user => user));
    }

    acceptUpdate(id: any, accept_status: any) {
        const formData: FormData = new FormData();
        formData.append('accept_status', accept_status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/accept/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    acceptStatus(id: any, marenaStatus: any) {
        const formData: FormData = new FormData();
        formData.append('marenaStatus', marenaStatus);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/status/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    acceptStatusswim(id: any, swimmingStatus: any) {
        const formData: FormData = new FormData();
        formData.append('swimmingStatus', swimmingStatus);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/swimstatus/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    canelUpdate(id: any, cancel_status: any, status: any, refund_status: any) {
        const formData: FormData = new FormData();
        formData.append('cancel_status', cancel_status);
        formData.append('status', status);
        formData.append('refund_status', refund_status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/cancel/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    statusUpdate(id: any, status: any, refund_status: any, cancel_status: any) {
        const formData: FormData = new FormData();
        formData.append('status', status);
        formData.append('refund_status', refund_status);
        formData.append('cancel_status', cancel_status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/status/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    refundUpdate(id: any, refund_status: any, status: any) {
        const formData: FormData = new FormData();
        formData.append('refund_status', refund_status);
        formData.append('status', status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/refund/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    uploadExcel(file) {
        var formData = new FormData();
        formData.append('file', file);
        // formData.append('type', type);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/upload-excel`, formData).pipe(map(user => user));
    }
    //old-records

    getEdit(id: any, roleId: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-lists?id=` + id + `&roleId=` + roleId).pipe(map(user => user));
    }

    download(search = "", filter = null, statefilter = null): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/report?search=` + search + `&filter=` + String(filter) + '&statefilter=' + statefilter, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }


   
    // this.cur_page,this.search_txt,this.roleId,this.filter_date
    getPaymentListDownload(page, search, roleId, filter_date, filter_status): Promise<Blob> {
        var formData = {};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/downloadpaymentlogs?page=` + page + '&search=' + search + `&roleId=` + roleId + `&date=` + filter_date + `&status=` + filter_status, formData,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

  

   






    // getDownloadcancel(page, search, category, tshirt, attendee_type): Promise<Blob> {
    //     var formData = {};
    //     const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelcancel?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
    //         { responseType: 'blob' as 'json' }).toPromise();
    //     return file;
    // }

   

  


    // getDownloadrefund(page, search, category, tshirt, attendee_type): Promise<Blob> {
    //     var formData = {};
    //     const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelrefund?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
    //         { responseType: 'blob' as 'json' }).toPromise();
    //     return file;
    // }

    update(id: any,
        name: any, email: any, gender: any, age: any, category: any,
        phone_number: any, blood_group: any, t_shirt_size: any,
        em_contact_name: any, em_contact_num: any, type: any, vaccine_status: any,
        attendee_type: any,
        name_of_institute: any, reasearch_associate: any, register_no: any,
        year: any, course: any, employee_id: any, designation: any, department: any) {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('gender', gender);
        formData.append('age', age);
        formData.append('category', category);
        formData.append('phone_number', phone_number);
        formData.append('blood_group', blood_group);
        formData.append('t_shirt_size', t_shirt_size);
        formData.append('em_contact_name', em_contact_name);
        formData.append('em_contact_num', em_contact_num);
        formData.append('type', type);
        formData.append('vaccine_status', vaccine_status);
        formData.append('attendee_type', attendee_type);
        formData.append('name_of_institute', name_of_institute);
        formData.append('reasearch_associate', reasearch_associate);
        formData.append('register_no', register_no);
        formData.append('year', year);
        formData.append('course', course);
        formData.append('employee_id', employee_id);
        formData.append('designation', designation);
        formData.append('department', department);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    // name: any,email: any, gender:any,dob:any,phone_no:any,address:any,start_date:any,

    updatemarena(id: any,
        end_date: any, roleId: any) {
        const formData: FormData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('gender', gender);
        // formData.append('dob', dob);
        // formData.append('phone_no', phone_no);
        // formData.append('address', address);
        // formData.append('start_date', start_date);
        formData.append('validTill', end_date);
        // formData.append('roleId', roleId);



        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration?id=` + id + `&roleId=` + roleId, formData).pipe(map(user => {
            return user;
        }));
    }

    updateadhoc(id: any, userId: any, details: any, place: any, date: any, amount: any) {
        const formData: FormData = new FormData();
        formData.append('userId', userId);
        formData.append('details', details);
        formData.append('date', date);
        formData.append('amount', amount);
        formData.append('place', place);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/updateAdhoc/` + id, formData).pipe(map(user => {
            return user;
        }));
    }


    saveadhoc(userId: any, details: any, place: any, date: any, amount: any) {
        const formData: FormData = new FormData();
        formData.append('userId', userId);
        formData.append('details', details);
        formData.append('date', date);
        formData.append('amount', amount);
        formData.append('place', place);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/createadhoc`, formData).pipe(map(user => {
            return user;
        }));
    }

    getAdhoc(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getAdhoc`).pipe(map(user => user));
    }

    deleteadhoc(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/adhoc/` + id).pipe(map(user => {
            return user;
        }));
    }


    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/user/` + id).pipe(map(user => {
            return user;
        }));
    }

    delete1(id: string, roleId: any) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/membership?id=` + id + `&roleId=` + roleId).pipe(map(user => {
            return user;
        }));
    }

    getMareenaCategoryDetail(page, search): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/list?page=` + page + '&search=' + search).pipe(map(user => user));
    }

    getMareenaCategoryEdit(id: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/view/` + id).pipe(map(user => user));
    }

    marenaCategoryAdd(name: any, one_month_amount: any,two_month_amount:any,three_month_amount:any,six_month_amount:any,year_amount:any,current_status:any) {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('one_month_amount', one_month_amount);
        formData.append('two_month_amount', two_month_amount);
        formData.append('three_month_amount', three_month_amount);
        formData.append('six_month_amount', six_month_amount);
        formData.append('year_amount', year_amount);
        formData.append('status', current_status);
        // formData.append('roleId', roleId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/add`, formData).pipe(map(user => {
            return user;
        }));
    }

   
    marenaCategoryUpdate(id: any,name: any, one_month_amount: any,two_month_amount:any,three_month_amount:any,six_month_amount:any,year_amount:any,current_status:any) {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('one_month_amount', one_month_amount);
        formData.append('two_month_amount', two_month_amount);
        formData.append('three_month_amount', three_month_amount);
        formData.append('six_month_amount', six_month_amount);
        formData.append('year_amount', year_amount);
        formData.append('status', current_status);
        // formData.append('roleId', roleId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/update/` + id , formData).pipe(map(user => {
            return user;
        }));
    }

    getSwimmingCategoryDetail(page, search): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/list?page=` + page + '&search=' + search).pipe(map(user => user));
    }

    getSwimmingCategoryEdit(id: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/view/` + id).pipe(map(user => user));
    }

    swimmingCategoryUpdate(id: any,name: any, studenthalfyear_fees: any,studentyear_fees:any,staffhalfyear_fees:any,staffyear_fees:any,otherhalfyear_fees:any,otheryear_fees:any,coaching_fees:any,month_fees:any,year_fees:any,current_status:any) {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('studenthalfyear_fees', studenthalfyear_fees);
        formData.append('studentyear_fees', studentyear_fees);
        formData.append('staffhalfyear_fees', staffhalfyear_fees);
        formData.append('staffyear_fees', staffyear_fees);
        formData.append('otherhalfyear_fees', otherhalfyear_fees);
        formData.append('otheryear_fees', otheryear_fees);
        formData.append('coaching_fees', coaching_fees);
        formData.append('month_fees', month_fees);
        formData.append('year_fees', year_fees);
        formData.append('status', current_status);
        // formData.append('roleId', roleId);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/update/` + id , formData).pipe(map(user => {
            return user;
        }));
    }
}