<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">

    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Details</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>


    <section class="content">
        <div class="container-fluid page_section">
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content reg_view_form">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <!-- <div class="register_view_top">
                                            <div class="registartion_vleft">
                                                <div class="registartion_id">Registration ID: {{edit && edit.reg_ID}}</div>
                                            </div>
                                            
                                            <div class="filler"></div>
                                            <div class="total_amount_sec">
                                                <div class="totamt_txt">Total Amount</div>
                                                <div id="amt_underline">
                                                    <div></div>
                                                </div>
                                                <div class="rupee_section">
                                                    <img src="assets/images/rupee-symbol.png" class="img-responsive" alt="rupee_symbol">
                                                    {{edit && edit.total_amount}}
                                                </div>
                                            </div>
                                        </div> -->
                                        <form class="registration_view_form" [formGroup]="registration_form" #myForm="ngForm"
                                            (ngSubmit)="onSubmit(registration_form, myForm)">
                                            <div class="col-sm-12">
                                                <div class="row">

                                                    <!-- <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="type">Membership Category</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="category"
                                                                [ngClass]="{ 'is-invalid': (f.category.touched || submitted) && f.category.errors }" readonly>
                                                            <div *ngIf="(f.category.touched || submitted) && f.category.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.category.errors?.required">Category is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee name">Registration ID</label>
                                                            <select id="status" class="form-control"
                                                                (change)="changeRegistration($event,'tshirt')"
                                                                formControlName="regid" *ngIf="!regId">
                                                                <option [selected]>Select Registration ID</option>
                                                                <option *ngFor="let t of dropdownuser; let x=index"
                                                                    [value]="t._id">{{t.reg_ID}}
                                                                </option>
                                                            </select>

                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="regid" *ngIf="regId" readonly [value]="regId">
                                                            <!-- <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="name"
                                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.name.errors?.pattern">Empty space is not allowed
                                                            </div>
                                                        </div> -->
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee email">Details</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="details"
                                                                [ngClass]="{ 'is-invalid': (f.details.touched || submitted) && f.details.errors }">
                                                            <div *ngIf="(f.details.touched || submitted) && f.details.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.details.errors?.required">Details is required</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee email">Place</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="place"
                                                                [ngClass]="{ 'is-invalid': (f.place.touched || submitted) && f.place.errors }">
                                                            <div *ngIf="(f.place.touched || submitted) && f.place.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.place.errors?.required">Place
                                                                    is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row">
                                                  
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="age">Date</label>
                                                            <input autocomplete="off" type="date" class="form-control"
                                                                formControlName="date"
                                                                [ngClass]="{ 'is-invalid': (f.date.touched || submitted) && f.date.errors }">
                                                            <div *ngIf="(f.date.touched || submitted) && f.date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.date.errors?.required">Date is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="phone number">Amount</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="amount"
                                                                [ngClass]="{ 'is-invalid': (f.amount.touched || submitted) && f.amount.errors }"
                                                                >
                                                            <div *ngIf="(f.amount.touched || submitted) && f.amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.amount.errors?.required">
                                                                    Amount is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>