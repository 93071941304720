<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">

    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/institution-list">Institution List</a></li>
                        <li class="breadcrumb-item active">Institution Add</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>


    <section class="content">
        <div class="container-fluid page_section">
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content reg_view_form">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <div class="register_view_top">
                                            <div class="registartion_vleft">

                                            </div>

                                            <div class="filler"></div>

                                        </div>
                                        <form class="registration_view_form" [formGroup]="categoryAddForm" #myForm="ngForm"
                                            (ngSubmit)="onSubmit(categoryAddForm, myForm)">
                                          
                                            <div class="col-sm-12" *ngIf="roleId == '103'">
                                                <div class="row">

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee name">Name</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="name"
                                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.pattern">Empty space is not
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="current_status">Status</label>
                                                            <select id="status" class="normal_background form-control"
                                                                [ngClass]="{ 'is-invalid': (f.current_status.touched || submitted) && f.current_status.errors }"
                                                                formControlName="current_status">
                                                                <!-- <option value='' selected disabled>Select Status</option> -->
                                                                <option value='1'>Active</option>
                                                                <option value='0'>Inactive</option>
                                                              
                                                            </select>
                                                            <div *ngIf="(f.current_status.touched || submitted) && f.current_status.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.current_status.errors?.required">Status is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    
                                                   


                                                </div>


                                               
                                          

                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>