import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    homePage: any;
    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.status.code == 0) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);

                localStorage.setItem(`${environment.appName}` + '_adminName', user.data.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.data.user.status);
                localStorage.setItem(`${environment.appName}` + '_MainAdminID', user.data.user.roleId);

                if (user.data.user.roleId == '99') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '99');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'marena');
                    localStorage.setItem(`${environment.appName}` + '_role', '100');
                }else if (user.data.user.roleId == '100') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '100');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'marena');

                }else if (user.data.user.roleId == '101') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '101');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'swim');

                }else if (user.data.user.roleId == '102') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '102');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'coursera');

                }else if (user.data.user.roleId == '103') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '103');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'adhoc');

                }
                else if (user.data.user.roleId == '104') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '104');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'almashine');

                }
                else if (user.data.user.roleId == '107') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '107');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'kala_fest');

                }
                else if (user.data.user.roleId == '106') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '106');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'chess_event');

                }
                else if (user.data.user.roleId == '108') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '108');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'iqac-mit');

                }
                else if (user.data.user.roleId == '109') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '109');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'TDWFE');

                }
                else if (user.data.user.roleId == '110') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '110');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'Construction-Law-blr');

                }
                else if (user.data.user.roleId == '111') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '111');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'Adhikshana');

                }
                else if (user.data.user.roleId == '119') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '119');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'Falak');

                }
                else if (user.data.user.roleId == '121') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '121');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'basketball');


                }
                else if (user.data.user.roleId == '122') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '122');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'chess_event');

                }
                else if (user.data.user.roleId == '123') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '123');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'faculty');
    
                }
                else if (user.data.user.roleId == '124') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '124');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'reflekta');
    
                }
                else if (user.data.user.roleId == '125') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '125');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'aley');
    
                }
                else if (user.data.user.roleId == '126') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '126');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'cina');
    
                }
                else if (user.data.user.roleId == '127') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '127');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'vehicle');
    
                }
                else if (user.data.user.roleId == '128') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '128');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'bazaar');
    
                }
                else if (user.data.user.roleId == '129') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '129');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'cricket');
    
                }

                else if (user.data.user.roleId == '130') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '130');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'tessellate');
    
                }
                else if (user.data.user.roleId == '113') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '113');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'Mujo');

                }
                else if (user.data.user.roleId == '114') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '114');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'Tech-Solstice-Nexus');

                }
                else if (user.data.user.roleId == '115') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '115');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'agiledevops');

                }

                else if (user.data.user.roleId == '116') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '116');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'conference');

                }
                else if (user.data.user.roleId == '117') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '117');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'hostel-list');

                }
                else if (user.data.user.roleId == '118') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '118');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'daycare-reg-list');

                }
                else if (user.data.user.roleId == '200') {
                    localStorage.setItem(`${environment.appName}` + 'AdminClass', '200');
                    localStorage.setItem(`${environment.appName}` + 'activeClass', 'bangloremarena');

                }
                // kala_fest
                
            }




            return user;
        }));

    }




    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_status');
        location.reload();
    }

    public isAuth() {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
        } else {
            return false;
        }
    }

   

}
