import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  save(
    firstName: string,
    lastName: string,
    phoneNumber: string,
    whichCountry: string,
    addressNumber: string,
    addressPrefix: string,
    streetName: string,
    addressType: string,
    addressSuffix: string,
    city: string,
    state: any,
    zip: string,
    country: any,
    idProof: File,
    emailID: string,
    password: string,
    client: any,
    userType: string,
    status: string,


  ) {
    console.log(idProof)
    const formData: FormData = new FormData();

    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('password', password);
    formData.append('email', emailID);

    formData.append('mobile_no', phoneNumber);

    formData.append('address_no', addressNumber);
    formData.append('address_prefix', addressPrefix);
    formData.append('street_name', streetName);
    formData.append('address_type', addressType);
    formData.append('address_suffix', addressSuffix);

    formData.append('city', city);
    console.log(state + ' and ' + country)
    if (state != undefined) {
      formData.append('state', state);
    }

    if (country != undefined) {
      formData.append('country', country);
    }
    formData.append('zip', zip);
    formData.append('active', status);
    formData.append('country_type', whichCountry);

    for (var i = 0; i < client.length; i++) {
      formData.append('client_id[]', client[i]);
    }


    formData.append('user_type', userType);
    // formData.append('id_proof', idProof);
    if (idProof) {
      formData.append('id_proof', idProof, idProof.name);
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user`, formData).pipe(map(user => {
      return user;
    }));

  }

  update(
    id: string,
    firstName: string,
    lastName: string,

    phoneNumber: string,
    whichCountry: string,
    addressNumber: string,
    addressPrefix: string,
    streetName: string,
    addressType: string,
    addressSuffix: string,
    city: string,
    state: any,
    zip: string,
    country: any,
    idProof: File,
    emailID: string,
    password: string,
    client: any,
    userType: string,
    status: string,
  ) {




    console.log('This is array' + client);
    const formData: FormData = new FormData();

    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    if (password != '1234567890') {
      console.log(password)
      formData.append('password', password);
    }

    formData.append('email', emailID);

    formData.append('mobile_no', phoneNumber);
    formData.append('address_no', addressNumber);
    formData.append('address_prefix', addressPrefix);
    formData.append('street_name', streetName);
    formData.append('address_type', addressType);
    formData.append('address_suffix', addressSuffix);

    formData.append('city', city);
    console.log(state)

    if (state == undefined) {
      console.log(state + ' and ' + country)
      formData.append('state', '');
    }

    if (country == undefined) {

      formData.append('country', '');
    }



    formData.append('zip', zip);
    formData.append('active', status);
    formData.append('country_type', whichCountry)
    // console.log('this clients array '+ typeof(client))

    for (var i = 0; i < client.length; i++) {
      formData.append('client_id[]', client[i]);
    }

    // formData.append('client_id[]', client);
    formData.append('user_type', userType);
    // formData.append('id_proof', idProof);
    if (idProof) {
      formData.append('id_proof', idProof, idProof.name);
    }
    // console.log('this clients array ' + formData);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/` + id, formData).pipe(map(user => {
      return user;
    }));

  }
  delete(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/user/` + id).pipe(map(user => {
      return user;
    }));

  }
  getUsers(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/user`).pipe(map(user => user));
  }

  getClientProducts(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client/products/` + id).pipe(map(user => user));
  }

  getReglist(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/registration-list`).pipe(map(user => user));
}


}
