<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active"><a href="/category-list">Category List</a></li>
                        <li class="breadcrumb-item active">Category Details</li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <section class="content">
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head">Payment Details</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="attendee_detail_edit">
                        <form class="attendee_det_form">
                            <div class="form_left">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Order ID</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.order_id}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Tracking ID</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.tracking_id}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Bank reference No </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4 bank_cls">
                                        {{register && register.bank_ref_no}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Order Status </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.order_status}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Payment Mode </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.payment_mode}}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Card Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.card_name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Currency</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.currency}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Amount </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.amount}}
                                    </div>
                                </div>
 

                            </div>
                            
                        </form>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="attendee_detail_edit">
                        <form class="attendee_det_form">
                            <div class="form_left">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Date-Time</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.createdAt | date: 'dd-MM-yyyy h:mm a'}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing Name</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing Address </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_address}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing City </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_city}}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing State </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_state}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing Zip </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_zip}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing Country</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_country}} 
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Billing Telephone</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.billing_tel}}
                                    </div>
                                </div>

                                


                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <!-- category details start -->

        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head">Category Details</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6" *ngFor="let data of register.category">
                    <div class="attendee_detail_edit att_detail">
                        <form class="attendee_det_form">
                            <div class="form_left">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Category Name</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{data && data.name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Membership Period</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.membership && register.membership && register.membership.membershipPeriod}}
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <!-- category details end  -->
    </section>
    <!-- /.content -->
</div>
