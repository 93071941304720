<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a>Home</a></li>
            <li
              *ngIf="roleId == '100' || roleId == '101' || roleId == '103' || roleId == '200'"
              class="breadcrumb-item active"
            >
              Dashboard
            </li>
            <li *ngIf="roleId == '104'" class="breadcrumb-item active">
              Almashine Dashboard
            </li>
            <li *ngIf="roleId == '102'" class="breadcrumb-item active">
              Coursera Dashboard
            </li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="row">
        <div class="col-sm-12">
          <div class="dashboard_head">Dashboard</div>
        </div>
      </div>
      <div class="row">
        <!---->
        <!-- <div class="col-sm-3"><a>
                        <div class="admin_home_card flt">
                            <div class="home_card">
                                <div class="admin-himag">
                                    <img src="assets/images/admin-shirt.png" class="img-responsive" alt="t-shirt_image">
                                </div>
                                <div class="filler"></div>
                                <div class="hcard_right">
                                    <span class="card_righttxt1">T-Shirts </span>
                                    <div id="hcard_head_underline1">
                                        <div></div>
                                    </div>
                                    <span class="hcard_count1">{{dashboard.total_t_count}}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> -->
        <!---->
        <div class="col-sm-4">
          <a href="/almashine-registration-lists" *ngIf="roleId == '104'">
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/admin-user.png"
                    class="img-responsive"
                    alt="user_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt2">Users</span>
                  <div id="hcard_head_underline2">
                    <div></div>
                  </div>
                  <span class="hcard_count2">{{
                    homecount && homecount.users
                      ? (homecount.users | number : "1.0" : "en-US")
                      : 0
                  }}</span>
                </div>
              </div>
            </div>
          </a>
          <a href="/coursera-registration-lists" *ngIf="roleId == '102'">
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/admin-user.png"
                    class="img-responsive"
                    alt="user_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt2">Users</span>
                  <div id="hcard_head_underline2">
                    <div></div>
                  </div>
                  <span class="hcard_count2">{{
                    homecount && homecount.users
                      ? (homecount.users | number : "1.0" : "en-US")
                      : 0
                  }}</span>
                </div>
              </div>
            </div>
          </a>
          <a
            href="/registration-list"
            *ngIf="roleId != '104' && roleId != '102'"
          >
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/admin-user.png"
                    class="img-responsive"
                    alt="user_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt2">Users</span>
                  <div id="hcard_head_underline2">
                    <div></div>
                  </div>
                  <span class="hcard_count2">{{
                    homecount && homecount.users
                      ? (homecount.users | number : "1.0" : "en-US")
                      : 0
                  }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
        <!---->
        <div class="col-sm-4">
          <a href="/coursera-payment-list" *ngIf="roleId == '102'">
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/admin-payments.png"
                    class="img-responsive"
                    alt="payments_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt3">Payments </span>
                  <div id="hcard_head_underline3">
                    <div></div>
                  </div>
                  <span class="hcard_count3">{{
                    homecount && homecount.tot_payment
                      ? (homecount.tot_payment | number : "1.0" : "en-US")
                      : 0
                  }}</span>
                </div>
              </div>
            </div>
          </a>
          <a href="/payment-list" *ngIf="roleId != '102'">
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/admin-payments.png"
                    class="img-responsive"
                    alt="payments_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt3">Payments </span>
                  <div id="hcard_head_underline3">
                    <div></div>
                  </div>
                  <span class="hcard_count3">{{
                    homecount && homecount.tot_payment
                      ? (homecount.tot_payment | number : "1.0" : "en-US")
                      : 0
                  }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-sm-4">
          <a>
            <div class="admin_home_card flt">
              <div class="home_card">
                <div class="admin-himag">
                  <img
                    src="assets/images/amt.png"
                    class="img-responsive"
                    alt="payments_image"
                  />
                </div>
                <div class="filler"></div>
                <div class="hcard_right">
                  <span class="card_righttxt4">Amount </span>
                  <div id="hcard_head_underline4">
                    <div></div>
                  </div>
                  <span class="hcard_count4"
                    >₹
                    {{
                      homecount && homecount.tot_amount
                        ? (homecount.tot_amount | number)
                        : 0
                    }}</span
                  >
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <!-- <div class="row mt-4">
                <div class="col-sm-4">
                    <table class="table table-bordered bg-white">
                        <tbody>
                            <tr>
                                <td>
                                    <b>XS</b>
                                </td>
                                <td>
                                    {{dashboard.xs}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>S</b>
                                </td>
                                <td>
                                    {{dashboard.s}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>M</b>
                                </td>
                                <td>
                                    {{dashboard.m}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>L</b>
                                </td>
                                <td>
                                    {{dashboard.l}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>XL</b>
                                </td>
                                <td>
                                    {{dashboard.xl}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>XXL</b>
                                </td>
                                <td>
                                    {{dashboard.xxl}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
    </div>
  </section>
</div>
