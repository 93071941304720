import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

declare var jquery: any;
declare var $: any;
@Component({
    templateUrl: 'category_list.component.html',
    styleUrls: ['category_list.component.css']
})
export class CategoryListComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cancel_data: any;
    cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    // filter_date= null;
    filter_status = null;
    category_list = [];
    cat_array = [];
    category = [];
    cat_data: any;
    // t_shirt = [];
    selected_category = null;
    selected_tshirt = null;
    media_domain = environment.media_domain;
    view_image = null;
    show_excel_upload = false;
    dashboad: any;
    type: any;
    pendingstate = false;
    new_page: any;
    //  totalcount:any;
    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
    pagevalue: any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        this.loading = true;
        const product = [];
        // const blob = await this._marathon.getDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt);
        let binaryData = [];
        //   binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        // const url = window.URL.createObjectURL(blob);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'registration.xlsx';
        link.click();
        // window.URL.revokeObjectURL(url);
        this.loading = false;
        document.body.classList.remove('jw-modal-open');
        // link.stop();
    }
    ngOnInit() {
        // this._marathon.getDashboard().subscribe(data => {
        //     this.dashboad = data.data;
        // })
        this.refresh(true);

        var that = this;
    }







    edit(e, user) {
        location.href = "edit/" + user._id;
        // this.router.navigate(['/edit/' + user._id]);
    }



    acceptStatus(e, user) {
        e.stopPropagation();
        console.log(user)
        this._marathon.acceptUpdate(user._id, 1).subscribe(data => {
            console.log(data)
            // this._marathon.getDashboard().subscribe(data => {
            //     this.dashboad = data.data;
            // })
        })
    }


    refresh(reset = false) {
        // this._marathon.getDashboard().subscribe(data => {
        //     this.dashboad = data.data;
        // })


        if (this.roleId == '99' || this.roleId == '100') {
            this._marathon.getMareenaCategoryDetail(this.cur_page, this.search_txt).subscribe(data => {
                this.category_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
            }, error => {

            });
        }
        else if (this.roleId == '200') {
            this._marathon.getbanglrMareenaCategoryDetail(this.cur_page, this.search_txt).subscribe(data => {
                this.category_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
            }, error => {

            });
        } 
        else if (this.roleId == '101') {
            this._marathon.getSwimmingCategoryDetail(this.cur_page, this.search_txt).subscribe(data => {
                this.category_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
            }, error => {

            });
        }
    }

    uploadExcelToggle(e) {
        this.show_excel_upload = true;
        this.resetFilter(null);
    }

    changeType(e) {

    }

    chooseFile(e) {

    }

    viewImage(e, obj) {
        e.stopPropagation();
        this.view_image = null;
        if (!obj.emp_id_image) {
            return false;
        }
        this.view_image = this.media_domain + obj.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }

    search(e) {
        this.search_txt = e.target.value;
        this.refresh();
    }

    // filterDate(e){
    //     this.filter_date=e.target.value;
    //     this.refresh();
    // }

    // filterStatus(e){
    //     this.filter_status=e.target.value;
    //     if(e.target.value == '')
    //     {
    //         this.filter_status=null;
    //     }
    //     this.refresh();
    // }

    changePage(page) {
        this.cur_page = page;
        this.refresh();


    }



    changeFilter(e, type) {
        e.stopPropagation();
        if (type === 'category') {
            if (e.target.value === '-1') {
                this.selected_category = null;
                this.refresh();
            } else {
                this.selected_category = e.target.value;
            }
        } else if (type === 'tshirt') {
            if (e.target.value === '-1') {
                this.selected_tshirt = null;
                this.refresh();
            } else {
                this.selected_tshirt = e.target.value;
            }
        }
        this.refresh();
    }

    resetFilter(e) {
        this.selected_category = null;
        this.selected_tshirt = null;
        this.search_txt = '';
        // this.filter_date= null;
        // this.filter_status= null;
        $('#search1').val('');
        $('#status').val('');
        $("input[type=date]").val("")
        this.refresh(true);
    }

    // public async downloadExcel() {


    //         if(this.roleId == '100'){
    //             // const blob = await this._marathon.getPaymentListDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt,"fhg");
    //             const blob = await this._marathon.getPaymentListDownload(this.cur_page,this.search_txt,this.roleId);
    //             let binaryData = [];
    //             binaryData.push(blob);
    //             const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    //             const link = this.downloadZipLink.nativeElement;
    //             link.href = url;
    //             link.download = 'Payment_logs.xlsx';
    //             link.click();
    //         }

    // }


}
