import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'register_list.component.html',
    styleUrls: ['register_list.component.css']
})
export class RegisterListComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cancel_data: any;
    cur_page = 1;
    // totalPages = 1;
    // cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    categorylist = [];
    register_list = [];
    cat_array = [];
    category = [];
    $category = [];
    cat_data: any;
    att_type = [];
    // t_shirt = [];
    // totalcount: any;
    catarray = [];
    catarray1 = [];
    finalcat = [];
    selected_category = null;
    selected_tshirt = null;
    selected_attendee_type = null;
    media_domain = environment.media_domain;
    view_image = null;
    show_excel_upload = false;
    dashboad: any;
    ismem = false;
    type: any;
    pendingstate = false;
    new_page: any;
    is_reg = false;
    is_pend = false;
    is_downloadreg = false;
    is_downloadpend = false;
    is_downloadref = false;
    is_downloadcan = false;
    is_downloadapprove = false;
    is_downloadoutdate = false;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    activeclass = "reg";
    downloadStatus = "Registration";
    updateForm: FormGroup;
    buttonEnable:any;

    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

    pagevalue: any;
    membership_period = null;
    rejected_id: any;
    rejectedstatus: boolean=false;
    is_downloadreject: boolean=false;
    tabtype: string;

    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
        public async reportDown(): Promise<void> {
        this.submitted = true;
        this.loading = true;
        const product = [];
        const blob = await this._marathon.getDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period);
        console.log(blob)
        let binaryData = [];
        binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        // const url = window.URL.createObjectURL(blob);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'registration.xlsx';
        link.click();
        // window.URL.revokeObjectURL(url);
        this.loading = false;
        document.body.classList.remove('jw-modal-open');
        // link.stop();
    }
    ngOnInit() {
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'reg');
        if(this.roleId == '100'){
           this.tabtype="marena" 
        }
        if(this.roleId == '101'){
            this.tabtype="swimming" 
         }
         if(this.roleId == '200'){
            this.tabtype = "marena"
         }
      

        this.refresh(true);

        var that = this;
        this.updateForm = this.formBuilder.group({
            reason: ['']
          });
    }


    public async downloadExcel() {

        if (this.is_downloadreg == true) {
            if (this.roleId == '100') {
                const blob = await this._marathon.getDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'registration.xlsx';
                link.click();
            }

            if (this.roleId == '200') {
                const blob = await this._marathon.getbanglrMarathonDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'registration.xlsx';
                link.click();
            }

            if (this.roleId == '101') {
                const blob = await this._marathon.getDownloadswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'registration.xlsx';
                link.click();
            }

        }

 

        if (this.is_downloadpend == true) {
            if (this.roleId == '100') {
                const blob = await this._marathon.getDownloadpending(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'pending.xlsx';
                link.click();
            }
          else  if(this.roleId == '200'){
                const blob = await this._marathon.getbanglrDownloadpending(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'pending.xlsx';
                link.click();
            }

           else if (this.roleId == '101') {
                const blob = await this._marathon.getDownloadpendingswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'pending.xlsx';
                link.click();
            }

        }

        if (this.is_downloadoutdate == true) {
            // this.is_downloadapprove = false;
            if (this.roleId == '100') {
                const blob = await this._marathon.getDownloadoutdate(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'outdate.xlsx';
                link.click();
            }

            if (this.roleId == '200') {
                const blob = await this._marathon.getbanglrDownloadoutdate(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'outdate.xlsx';
                link.click();
            }

            if (this.roleId == '101') {
                const blob = await this._marathon.getDownloadoutdateswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'outdate.xlsx';
                link.click();
            }
        }

        if (this.is_downloadapprove == true) {
            if (this.roleId == '101') {
                const blob = await this._marathon.getDownloadapproveswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'approve.xlsx';
                link.click();
            }
            if (this.roleId == '100') {
                const blob = await this._marathon.getDownloadapprove(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'approve.xlsx';
                link.click();
            }

            if (this.roleId == '200') {
                const blob = await this._marathon.getbanglrDownloadapprove(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'approve.xlsx';
                link.click();
            }
        }

        if (this.is_downloadreject == true) {
            if (this.roleId == '101') {
                const blob = await this._marathon.getDownloadrejectswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'reject.xlsx';
                link.click();
            }
            if (this.roleId == '100') {
                const blob = await this._marathon.getDownloadreject(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'reject.xlsx';
                link.click();
            }

            if (this.roleId == '200') {
                const blob = await this._marathon.getbanglrDownloadreject(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'reject.xlsx';
                link.click();
            }
        }

        // if (this.is_downloadref == true) {
        //     const blob = await this._marathon.getDownloadrefund(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type);
        //     let binaryData = [];
        //     binaryData.push(blob);
        //     const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        //     const link = this.downloadZipLink.nativeElement;
        //     link.href = url;
        //     link.download = 'registration.xlsx';
        //     link.click();
        // }
    }

    outDate(e) {
        this.rejectedstatus=false;
        this.membership_period = null;
        e.stopPropagation();
        this.downloadStatus = "Outdated"
        this.activeclass = 'outdate';
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'outdate');
        this.is_downloadreg = false;
        this.is_downloadpend = false;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadapprove = false;
        this.is_downloadreject = false;
        this.is_downloadoutdate = true;
        this.register_list = [];
        this.is_reg = false;
        this.is_pend = false;
        this.pendingstate = false;
        this.ismem = true;
        this.loading = true;
        //   this.is_downloadoutdate = true;
        if (this.roleId == '100' || this.roleId == '99') {
            this._marathon.getoutdated(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {

                this._marathon.getDashboard( this.tabtype).subscribe(data => {
                    this.dashboad = data.data;
                    this.loading = false;
                })

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'outdate';
                this.register_list && this.register_list.forEach(element => {
                    element.membership && element.membership.forEach(ele => {
                        this.catarray = [];
                        if (ele.beneficiary == null) {
                            this.catarray.push(ele.category)
                            this._marathon.getCategoryId(ele.category).subscribe(data => {
                                this.categorylist = data.data;
                            })

                        } else {
                            this.categorylist = [];
                        }
                    })

                })



            }, error => {
            });
        }

        if (this.roleId == '200') {
            this._marathon.getbanglroutdated(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {

                this._marathon.getbangloreDashboard( this.tabtype).subscribe(data => {
                    this.dashboad = data.data;
                    this.loading = false;
                })

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'outdate';
                this.register_list && this.register_list.forEach(element => {
                    element.membership && element.membership.forEach(ele => {
                        this.catarray = [];
                        if (ele.beneficiary == null) {
                            this.catarray.push(ele.category)
                            this._marathon.getCategoryId(ele.category).subscribe(data => {
                                this.categorylist = data.data;
                            })

                        } else {
                            this.categorylist = [];
                        }
                    })

                })



            }, error => {
            });
        }
        if (this.roleId == '101') {


            this._marathon.getoutdatedswim(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {

                this._marathon.getDashboard( this.tabtype).subscribe(data => {
                    this.dashboad = data.data;
                    this.loading = false;
                })

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'outdate';
                this.register_list && this.register_list.forEach(element => {
                    element.membership && element.membership.forEach(ele => {
                        this.catarray = [];
                        if (ele.beneficiary == null) {
                            this.catarray.push(ele.category)
                            this._marathon.getCategoryId(ele.category).subscribe(data => {
                                this.categorylist = data.data;
                            })

                        } else {
                            this.categorylist = [];
                        }
                    })
                })
            }, error => {
            });
        }

    }


    // cancelD(e) {
    //     e.stopPropagation();
    //     this.activeclass = 'cancelD';
    //     this.is_downloadreg = false;
    //     this.is_downloadpend = false;
    //     this.is_downloadref = false;
    //     this.is_downloadcan = true;
    //     this.is_downloadoutdate = false;

    //     this.register_list = [];
    //     this.is_reg = false;
    //     this.is_pend = true;
    //     this.pendingstate = true;
    //     this._marathon.getMarathonCancel(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
    //         this.register_list = data.data.docs;
    //         this.totalcount = data.data.totalDocs;
    //         this.cur_page = data.data.page;
    //         this.totalPages = data.data.totalPages;
    //         this.pagevalue = 'cancel';
    //     }, error => {
    //     });
    //     //   this.changePage(1);
    // }

    accept(e) {
        e.stopPropagation();
        this.register_list = [];
        this.is_reg = false;
        this.is_pend = false;
        this.pendingstate = true;
        this._marathon.getMarathonAccept(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
            this.register_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.from = data.data.pagingCounter;
            this.pagevalue = 'accept';
        }, error => {
        });
        // this.changePage(1);
    }
    // refund(e) {
    //     this.ismem = false;
    //     e.stopPropagation();
    //     this.activeclass = "refund";
    //     this.is_downloadreg = false;
    //     this.is_downloadpend = false;
    //     this.is_downloadref = true;
    //     this.is_downloadcan = false;
    //     this.is_downloadoutdate = false;
    //     this.register_list = [];
    //     this.pendingstate = false;
    //     this.is_pend = false;
    //     this.is_reg = true;
    //     this._marathon.getMarathonRefund(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
    //         this.register_list = data.data.docs;
    //         this.totalcount = data.data.totalDocs;
    //         this.cur_page = data.data.page;
    //         this.totalPages = data.data.totalPages;
    //         this.pagevalue = 'refund';
    //     }, error => {
    //     });
    //     // this.changePage(1);
    // }

    pending(e) {
        this.rejectedstatus=false;
        // e.stopPropagation();
        this.membership_period = null;
        this.downloadStatus = "Pending"
        this.ismem = false;
        this.activeclass = "pending";
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'pending');
        this.is_downloadreg = false;
        this.is_downloadpend = true;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadoutdate = false;
        this.is_downloadreject = false;
        this.register_list = [];
        this.is_reg = false;
        this.is_pend = false;
        this.pendingstate = true;
        this.loading = true;
        var that = this;

        if (that.roleId == '100' || that.roleId == '99') {
            that._marathon.getMarathonPending(1, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                that.register_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.from = data.data.pagingCounter;
                that.pagevalue = 'pending';
                this.loading = false;
                // that._marathon.getDashboard().subscribe(data => {
                //     that.dashboad = data.data;
                //  
                // })


            }, error => {
            });
        }
        else if (that.roleId == '101') {
            that._marathon.getMarathonPendingswim(1, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                that.register_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.from = data.data.pagingCounter;
                that.pagevalue = 'pending';
                this.loading = false;
                // that._marathon.getDashboard().subscribe(data => {
                //     that.dashboad = data.data;
                //     this.loading = false;
                // })
            }, error => {
            });
        }
        else  if(that.roleId =='200'){
            that._marathon.getbanglrMarathonPending(1, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                that.register_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.from = data.data.pagingCounter;
                that.pagevalue = 'pending';
                this.loading = false;
                


            }, error => {
            });
        }

        // this.changePage(1);
    }

    approve(e) {
        this.rejectedstatus=false;
        e.stopPropagation();
        this.membership_period = null;
        this.downloadStatus = "Approved"
        this.ismem = false;
        this.activeclass = "approve";
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'approve');
        this.is_downloadreg = false;
        this.is_downloadpend = false;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadreject = false;
        this.is_downloadapprove = true;
        this.is_downloadoutdate = false;
        this.register_list = [];
        this.is_reg = false;
        this.is_pend = false;
        this.pendingstate = false;
        this.loading = true;

        if (this.roleId == '101') {
            this._marathon.getapproveswim(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'approve';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })

            }, error => {
            });
        }
        if (this.roleId == '200') {
            this._marathon.getbanglrUserlistapproved(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'approve';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })
            }, error => {
            });
        }
        else if (this.roleId == '100' || this.roleId == '99') {
            this._marathon.getUserlistapproved(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'approve';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })
            }, error => {
            });
        }

     

        // this.changePage(1);
    }

    reject(e) {
        this.rejectedstatus=true;
        e.stopPropagation();
        this.membership_period = null;
        this.downloadStatus = "Rejected"
        this.ismem = false;
        this.activeclass = "reject";
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'reject');
        this.is_downloadreg = false;
        this.is_downloadpend = false;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadapprove = false;
        this.is_downloadreject = true;
        this.is_downloadoutdate = false;
        this.register_list = [];
        this.is_reg = false;
        this.is_pend = false;
        this.pendingstate = false;
        this.loading = true;

        if (this.roleId == '101') {
            this._marathon.getrejectswim(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                if(data.status.code==0){
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'reject';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })
            }
            else{
                this.loading = false;
            }

            }, error => {
            });
        }
         if (this.roleId == '200') {
            this._marathon.getbanglrUserlistrejected(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                if(data.status.code==0){
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'reject';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })
            }
            else{
                this.loading = false;
            }
            }, error => {
            });
        }
        else if (this.roleId == '100' || this.roleId == '99') {
            this._marathon.getUserlistrejected(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                if(data.status.code==0){
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'reject';
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })
            }
            else{
                this.loading = false;
            }
            }, error => {
            });
        }

        // this.changePage(1);
    }

    reg(e) {
        this.rejectedstatus=false;
        e.stopPropagation();
        this.downloadStatus = "Registration"
        this.activeclass = "reg";
        this.membership_period = null;

        localStorage.setItem(`${environment.appName}` + 'activeClick', 'reg');
        this.cur_page = 1;
        this.is_downloadreg = true;
        this.is_downloadpend = false;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadapprove = false;
        this.is_downloadoutdate = false;
        this.register_list = [];
        this.is_reg = true;
        this.is_pend = false;
        this.ismem = true;
        this.pendingstate = false;
        this.loading = true;

        if (this.roleId == '100' || this.roleId == '99') {
            this._marathon.getMarathon(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //    
                // })

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'registration';
                if (this.register_list.length) {
                    this.register_list && this.register_list.forEach(element => {
                        element && element.membership && element.membership.forEach(ele => {
                            this.catarray = [];
                            if (ele.beneficiary == null) {
                                this.catarray.push(ele.category)
                                this._marathon.getCategoryId(ele.category).subscribe(data => {
                                    this.categorylist = data.data;
                                })

                            } else {
                                this.categorylist = [];
                            }
                        })
                    })
                }

            }, error => {
            });
        }
        if (this.roleId == '101') {
            this._marathon.getSwim(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                this.loading = false;
                // this._marathon.getDashboard().subscribe(data => {
                //     this.dashboad = data.data;
                //     this.loading = false;
                // })

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'registration';
                if (this.register_list.length) {
                    this.register_list && this.register_list.forEach(element => {
                        element && element.membership && element.membership.forEach(ele => {
                            this.catarray = [];
                            if (ele.beneficiary == null) {
                                this.catarray.push(ele.category)
                                this._marathon.getCategoryId(ele.category).subscribe(data => {
                                    this.categorylist = data.data;
                                })

                            } else {
                                this.categorylist = [];
                            }
                        })
                    })
                }

            }, error => {
            });
        }
        if (this.roleId == '200') {
            this._marathon.getbanglrMarathon(1, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                this.loading = false;

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'registration';
                if (this.register_list.length) {
                    this.register_list && this.register_list.forEach(element => {
                        element && element.membership && element.membership.forEach(ele => {
                            this.catarray = [];
                            if (ele.beneficiary == null) {
                                this.catarray.push(ele.category)
                                this._marathon.getCategoryId(ele.category).subscribe(data => {
                                    this.categorylist = data.data;
                                })

                            } else {
                                this.categorylist = [];
                            }
                        })
                    })
                }

            }, error => {
            });
        }
        //this.changePage(1);
    }

    // move(e, user) {
    //     e.stopPropagation();
    //     this._marathon.statusUpdate(user._id, 1, 0, 0).subscribe(data => {
    //         this._marathon.getDashboard().subscribe(data => {
    //             this.dashboad = data.data;
    //         })
    //     })
    // }

    // edit(e, user) {
    //     location.href = "edit/" + user._id;
    //     // this.router.navigate(['/edit/' + user._id]);
    // }

    // refundStatus(e, user) {
    //     var that = this;
    //     e.stopPropagation();
    //     if (e.target.value == 1) {

    //         alertify.confirm('Refund', 'Are you sure want to refund this registration', function () {
    //             that._marathon.refundUpdate(user._id, 1, 1).subscribe(data => {
    //                 that.register_list = [];
    //                 that.pendingstate = true;
    //                 that.is_pend = false;
    //                 that.is_reg = true;
    //                 that._marathon.getMarathonRefund(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
    //                     that.register_list = data.data.docs;
    //                     that.cur_page = data.data.page;
    //                     that.totalcount = data.data.totalDocs;
    //                     that.totalPages = data.data.totalPages;
    //                     that.pagevalue = 'refund';
    //                     that._marathon.getDashboard().subscribe(data => {
    //                         that.dashboad = data.data;
    //                     })
    //                 }, error => {
    //                 });
    //             })
    //         }, function () {
    //             // console.log(user)
    //             // if (user.status == 1 && user.cancel_status == 1 && user.refund_status == 0) {
    //             //     that.cancelD(e);
    //             // }
    //             // that.toastr.error('Refund action cancelled')
    //         });
    //     } else if (e.target.value == 2) {
    //         that.pendingstate = false;
    //         alertify.confirm('Accept', 'Are you sure want to accept this registration', function () {
    //             that._marathon.acceptUpdate(user._id, 1).subscribe(data => {

    //                 that._marathon.getDashboard().subscribe(data => {
    //                     that.dashboad = data.data;
    //                 })
    //             })
    //         }, function () {
    //             that.toastr.error('Accept action cancelled')
    //         });
    //     } else if (e.target.value == 4) {
    //         that.pendingstate = true;
    //         alertify.confirm('Complete', 'Are you sure want to complete this registration', function () {
    //             that._marathon.statusUpdate(user._id, 1, 0, 0).subscribe(data => {

    //                 that.register_list = [];
    //                 that.is_reg = true;
    //                 that.is_pend = false;
    //                 that.pendingstate = false;
    //                 that._marathon.getMarathon(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
    //                     that.register_list = data.data.docs;
    //                     that.cur_page = data.data.page;
    //                     that.totalcount = data.data.totalDocs;
    //                     that.totalPages = data.data.totalPages;
    //                     that.pagevalue = 'registration';
    //                     that._marathon.getDashboard().subscribe(data => {
    //                         that.dashboad = data.data;
    //                     })
    //                 }, error => {
    //                 });
    //             })
    //         }, function () {
    //             if (user.status == 0 && user.cancel_status == 0 && user.refund_status == 0) {
    //                 that.pending(e);
    //             }
    //             // if (user.status == 1 && user.cancel_status == 1 && user.refund_status == 0) {
    //             //     that.cancelD(e);
    //             // }
    //             if (user.status == 1 && user.cancel_status == 1 && user.refund_status == 1) {
    //                 that.refund(e);
    //             }
    //             that.toastr.error('Complete action cancelled')
    //         });
    //     }
    //     else {
    //         //  that.pendingstate = false;
    //         alertify.confirm('Cancel', 'Are you sure want to cancel this registration', function () {
    //             that._marathon.canelUpdate(user._id, 1, 1, 0).subscribe(data => {
    //                 that.register_list = [];
    //                 that.is_reg = false;
    //                 that.is_pend = true;
    //                 that.pendingstate = true;
    //                 that._marathon.getMarathonCancel(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
    //                     that.register_list = data.data.docs;
    //                     that.totalcount = data.data.totalDocs;
    //                     that.cur_page = data.data.page;
    //                     that.totalPages = data.data.totalPages;
    //                     that.pagevalue = 'cancel';
    //                     that._marathon.getDashboard().subscribe(data => {
    //                         that.dashboad = data.data;
    //                     })
    //                 }, error => {
    //                 });
    //             })
    //         }, function () {

    //             if (user.status == 1 && user.cancel_status == 0 && user.refund_status == 0) {
    //                 that.refresh();
    //             }
    //             if (user.status == 1 && user.cancel_status == 1 && user.refund_status == 1) {
    //                 that.refund(e);
    //             }
    //             that.toastr.error('Cancel action cancelled')
    //         });
    //     }

    // }

    deleteReg(e, user) {
        e.stopPropagation();
        var that = this;
        alertify.confirm('Delete', 'Are you sure want to delete this registration?', function () {
            if (that.activeclass == 'reg' || that.activeclass == 'outdate') {
                console.log("New api call");
                if (that.roleId == '99') {
                    that.roleId = '100'
                }

                that._marathon.delete1(user._id, that.roleId)
                    .subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.toastr.success('Deleted Successfully')
                                if (user.cancel_status == 1) {
                                    that.register_list = [];
                                    that.is_downloadreg = false;
                                    that.is_downloadpend = false;
                                    that.is_downloadref = false;
                                    that.is_downloadcan = true;

                                    that.is_reg = false;
                                    that.is_pend = true;
                                    that.pendingstate = true;
                                    that._marathon.getMarathonCancel(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                        that.register_list = data.data.docs;
                                        that.cur_page = data.data.page;
                                        that.totalcount = data.data.totalDocs;
                                        that.totalPages = data.data.totalPages;
                                        that.from = data.data.pagingCounter;
                                        that.pagevalue = 'cancel';
                                        that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                            that.dashboad = data.data;
                                        })
                                    }, error => {
                                    });

                                } else if (user.accept_status == 1) {

                                    that.register_list = [];
                                    that.pendingstate = false;
                                    that._marathon.getMarathonAccept(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                        that.register_list = data.data.docs;
                                        that.cur_page = data.data.page;
                                        that.totalcount = data.data.totalDocs;
                                        that.totalPages = data.data.totalPages;
                                        that.from = data.data.pagingCounter;
                                        that.pagevalue = 'accept';
                                        that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                            that.dashboad = data.data;
                                        })
                                    }, error => {
                                    });

                                }
                                // else if (user.refund_status == 1) {
                                //     that.register_list = [];

                                //     that.is_downloadreg = false;
                                //     that.is_downloadpend = false;
                                //     that.is_downloadref = true;
                                //     that.is_downloadcan = false;

                                //     that.pendingstate = true;
                                //     that.is_pend = false;
                                //     that.is_reg = true;
                                //     that._marathon.getMarathonRefund(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                //         that.register_list = data.data.docs;
                                //         that.cur_page = data.data.page;
                                //         that.totalcount = data.data.totalDocs;
                                //         that.totalPages = data.data.totalPages;
                                //         that.pagevalue = 'refund';
                                //         that._marathon.getDashboard().subscribe(data => {
                                //             that.dashboad = data.data;
                                //         })
                                //     }, error => {
                                //     });

                                // } 
                                else if (user.status == 0) {
                                    that.register_list = [];
                                    that.is_downloadreg = false;
                                    that.is_downloadpend = true;
                                    that.is_downloadref = false;
                                    that.is_downloadcan = false;

                                    that.pendingstate = true;
                                    if (that.roleId == '100' || '99') {
                                        that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                            that.register_list = data.data.docs;
                                            that.cur_page = data.data.page;
                                            that.totalcount = data.data.totalDocs;
                                            that.totalPages = data.data.totalPages;
                                            that.from = data.data.pagingCounter;
                                            that.pagevalue = 'pending';
                                            that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                                that.dashboad = data.data;
                                            })
                                        }, error => {
                                        });
                                    }
                                    if (that.roleId == '101') {
                                        that._marathon.getMarathonPendingswim(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                            that.register_list = data.data.docs;
                                            that.cur_page = data.data.page;
                                            that.totalcount = data.data.totalDocs;
                                            that.totalPages = data.data.totalPages;
                                            that.from = data.data.pagingCounter;
                                            that.pagevalue = 'pending';
                                            that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                                that.dashboad = data.data;
                                            })
                                        }, error => {
                                        });
                                    }

                                } else {
                                    that.refresh();
                                }
                            } else {
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error)

                        });

            } else {

                that._marathon.delete(user._id)
                    .subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.toastr.success('Deleted Successfully')
                                if (user.cancel_status == 1) {
                                    that.register_list = [];
                                    that.is_downloadreg = false;
                                    that.is_downloadpend = false;
                                    that.is_downloadref = false;
                                    that.is_downloadcan = true;

                                    that.is_reg = false;
                                    that.is_pend = true;
                                    that.pendingstate = true;
                                    that._marathon.getMarathonCancel(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                        that.register_list = data.data.docs;
                                        that.cur_page = data.data.page;
                                        that.totalcount = data.data.totalDocs;
                                        that.totalPages = data.data.totalPages;
                                        that.from = data.data.pagingCounter;
                                        that.pagevalue = 'cancel';
                                        that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                            that.dashboad = data.data;
                                        })
                                    }, error => {
                                    });

                                } else if (user.accept_status == 1) {

                                    that.register_list = [];
                                    that.pendingstate = false;
                                    that._marathon.getMarathonAccept(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                        that.register_list = data.data.docs;
                                        that.cur_page = data.data.page;
                                        that.totalcount = data.data.totalDocs;
                                        that.totalPages = data.data.totalPages;
                                        that.from = data.data.pagingCounter;
                                        that.pagevalue = 'accept';
                                        that._marathon.getDashboard( this.tabtype).subscribe(data => {
                                            that.dashboad = data.data;
                                        })
                                    }, error => {
                                    });

                                }
                                // else if (user.refund_status == 1) {
                                //     that.register_list = [];

                                //     that.is_downloadreg = false;
                                //     that.is_downloadpend = false;
                                //     that.is_downloadref = true;
                                //     that.is_downloadcan = false;

                                //     that.pendingstate = true;
                                //     that.is_pend = false;
                                //     that.is_reg = true;
                                //     that._marathon.getMarathonRefund(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                //         that.register_list = data.data.docs;
                                //         that.cur_page = data.data.page;
                                //         that.totalcount = data.data.totalDocs;
                                //         that.totalPages = data.data.totalPages;
                                //         that.pagevalue = 'refund';
                                //         that._marathon.getDashboard().subscribe(data => {
                                //             that.dashboad = data.data;
                                //         })
                                //     }, error => {
                                //     });

                                // } 
                                else if (user.status == 0) {
                                    that.register_list = [];
                                    that.is_downloadreg = false;
                                    that.is_downloadpend = true;
                                    that.is_downloadref = false;
                                    that.is_downloadcan = false;

                                    that.pendingstate = true;
                                    if (that.roleId == '100' || '99') {
                                        that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                            that.register_list = data.data.docs;
                                            that.cur_page = data.data.page;
                                            that.totalcount = data.data.totalDocs;
                                            that.totalPages = data.data.totalPages;
                                            that.from = data.data.pagingCounter;
                                            that.pagevalue = 'pending';
                                            that._marathon.getDashboard(this.tabtype).subscribe(data => {
                                                that.dashboad = data.data;
                                            })
                                        }, error => {
                                        });
                                    }
                                    if (that.roleId == '101') {
                                        that._marathon.getMarathonPendingswim(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                                            that.register_list = data.data.docs;
                                            that.cur_page = data.data.page;
                                            that.totalcount = data.data.totalDocs;
                                            that.totalPages = data.data.totalPages;
                                            that.from = data.data.pagingCounter;
                                            that.pagevalue = 'pending';
                                            that._marathon.getDashboard(this.tabtype).subscribe(data => {
                                                that.dashboad = data.data;
                                            })
                                        }, error => {
                                        });
                                    }

                                } else {
                                    that.refresh();
                                }
                            } else {
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error)

                        });

            }

        }
            , function () {
                that.toastr.error('Delete action cancelled')
            })


    }

    acceptStatus(e, user) {
        e.stopPropagation();
        console.log(user)
        this._marathon.acceptUpdate(user._id, 1).subscribe(data => {
            console.log(data)
            this._marathon.getDashboard(this.tabtype).subscribe(data => {
                this.dashboad = data.data;
            })
        })
    }
    cancelStatus(e, user) {
        e.stopPropagation();
        this._marathon.canelUpdate(user._id, 1, 1, 0).subscribe(data => {
            console.log(data)
            this._marathon.getDashboard( this.tabtype).subscribe(data => {
                this.dashboad = data.data;
            })
        })
    }

    refresh(reset = false) {
        this.ismem = true;
        this.activeclass = "reg";
        this.is_downloadreg = true;
        this.is_downloadpend = false;
        this.is_downloadref = false;
        this.is_downloadcan = false;
        this.is_downloadapprove = false;
        if(this.roleId == '200'){
            this._marathon.getbangloreDashboard(this.tabtype).subscribe(data => {
                this.dashboad = data.data;
        })

        this._marathon.getbanglrMarathon(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
            this.loading = false;

            this.register_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.from = data.data.pagingCounter;
            this.pagevalue = 'registration';
            if (this.register_list.length) {
                this.register_list && this.register_list.forEach(element => {
                    element && element.membership && element.membership.forEach(ele => {
                        this.catarray = [];
                        if (ele.beneficiary == null) {
                            this.catarray.push(ele.category)
                            this._marathon.getCategoryId(ele.category).subscribe(data => {
                                this.categorylist = data.data;
                            })
                        } else {
                            this.categorylist = [];
                        }
                    })
                })
            }
        }, error => {
        });
        }
        else{
        this._marathon.getDashboard(this.tabtype).subscribe(data => {
            this.dashboad = data.data;
        })
         }

        this.is_reg = true;
        this.is_pend = false;
        this.pendingstate = false;
        this.loading = true;

        if (this.roleId == '100' || this.roleId == '99') {
            this._marathon.getMarathon(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                this.loading = false;

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'registration';
                if (this.register_list.length) {
                    this.register_list && this.register_list.forEach(element => {
                        element && element.membership && element.membership.forEach(ele => {
                            this.catarray = [];
                            if (ele.beneficiary == null) {
                                this.catarray.push(ele.category)
                                this._marathon.getCategoryId(ele.category).subscribe(data => {
                                    this.categorylist = data.data;
                                })
                            } else {
                                this.categorylist = [];
                            }
                        })
                    })
                }
            }, error => {
            });
        }
        if (this.roleId == '101') {
            this._marathon.getSwim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {

                this.loading = false;

                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'registration';
                if (this.register_list && this.register_list.length) {
                    this.register_list && this.register_list.forEach(element => {
                        element && element.membership && element.membership.forEach(ele => {
                            this.catarray = [];
                            if (ele.beneficiary == null) {
                                this.catarray.push(ele.category)
                                this._marathon.getCategoryId(ele.category).subscribe(data => {
                                    this.categorylist = data.data;
                                })
                            } else {
                                this.categorylist = [];
                            }
                        })
                    })
                }
            }, error => {
            });
        }

        // get the same api in banglrmarena api
        if (reset) {
            if(this.roleId == '200'){
                this._marathon.getbanglrmarenaCategory().subscribe(data => {
                    this.category = data.data;
                }, error => {
                });
                this.att_type = [
                    { 'type': 1, 'name': 'Students of MAHE' },
                    { 'type': 2, 'name': 'Staff' },
                    // { 'type': 3, 'name': 'Others' }, commented temporarily
                ];
            }
            else{
                this._marathon.getmarenaCategory().subscribe(data => {
                    this.category = data.data;
                }, error => {
                });
    
                this._marathon.getswimCategory().subscribe(data => {
                    this.$category = data.data;
    
                }, error => {
                });

                this.att_type = [
                    { 'type': 1, 'name': 'Students of MAHE' },
                    { 'type': 2, 'name': 'Staff' },
                    { 'type': 3, 'name': 'Others' },
                ];
            }
          

            // this._marathon.getTShirtSize().subscribe(data => {
            //     this.t_shirt = data.data;
            // }, error => {

            // });

          
        }


    }

    status(e, user) {
        e.stopPropagation();
        var that = this;
        console.log(that.roleId)
        alertify.confirm('Accept', 'Are you sure want to accept this registration', function () {
            if (that.roleId == '100' || that.roleId == '99') {
                that._marathon.acceptStatus(user._id, 1).subscribe(data => {
                    if (data.status.code == 0) {
                        if (that.roleId == '99') {
                            that.toastr.success('User is approved by Superadmin');
                        }
                        if (that.roleId == '100') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                        if (that.roleId == '101') {
                            that.toastr.success('User is approved by MIT Swimming Pool Admin');
                        }
                        if (that.roleId == '200') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                    }

                    that._marathon.getDashboard(that.tabtype).subscribe(data => {
                        that.dashboad = data.data;
                    })

                    that.pending("");
                    // location.reload();
                })
            }

            if (that.roleId == '200') {
                that._marathon.acceptbanglrStatus(user._id, 1).subscribe(data => {
                    if (data.status.code == 0) {
                        if (that.roleId == '99') {
                            that.toastr.success('User is approved by Superadmin');
                        }
                        if (that.roleId == '100') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                        if (that.roleId == '101') {
                            that.toastr.success('User is approved by MIT Swimming Pool Admin');
                        }
                        if (that.roleId == '200') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                    }

                    that._marathon.getbangloreDashboard(that.tabtype).subscribe(data => {
                        that.dashboad = data.data;
                    })

                    that.pending("");
                    // location.reload();
                })
            }
            if (that.roleId == '101') {

                that._marathon.acceptStatusswim(user._id, 1).subscribe(data => {
                    if (data.status.code == 0) {
                        if (that.roleId == '99') {
                            that.toastr.success('User is approved by Superadmin');
                        }
                        if (that.roleId == '100') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                        if (that.roleId == '101') {
                            that.toastr.success('User is approved by MIT Swimming Pool Admin');
                        }
                        if (that.roleId == '200') {
                            that.toastr.success('User is approved by Marena Admin');
                        }
                    }

                    that._marathon.getDashboard( that.tabtype).subscribe(data => {
                        that.dashboad = data.data;
                    })

                    that.pending("");
                    // location.reload();
                })
            }

        }, function () {
            that.toastr.error('Accept action cancelled')
        });
    }

    rejectstatus(e, user){
        this.buttonEnable=true;

        this.rejected_id=user._id;
        $('#modal').modal("show");
        console.log(this.rejected_id)
    }

    txtChangerj(e) {
        if (e.target.value) {
          this.buttonEnable = false;
        } else {
          this.buttonEnable = true;
        }
      }
      get f() { return this.updateForm.controls; }
    onSubmit(){
console.log(this.updateForm.get('reason').value);
// e.stopPropagation();
var that = this;
console.log(that.rejected_id)
alertify.confirm('Reject', 'Are you sure want to reject this registration', function () {
    if (that.roleId == '100' || that.roleId == '99') {
        that._marathon.rejectStatus(that.rejected_id, 3, that.f.reason.value ? that.f.reason.value : '').subscribe(data => {
            if (data.status.code == 0) {
                if (that.roleId == '99') {
                    that.toastr.success('User is rejected by Superadmin');
                }
                if (that.roleId == '100') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
                if (that.roleId == '101') {
                    that.toastr.success('User is rejected by MIT Swimming Pool Admin');
                }
                if (that.roleId == '200') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
            }
            $('#modal').modal("hide");
            that.updateForm.patchValue({
                reason: '',
              });
            that._marathon.getDashboard(that.tabtype).subscribe(data => {
                that.dashboad = data.data;
            })

            that.pending("");
            // location.reload();
        })
    }

    if (that.roleId == '200') {
        that._marathon.rejectbanglrStatus(that.rejected_id, 3, that.f.reason.value ? that.f.reason.value : '').subscribe(data => {
            if (data.status.code == 0) {
                if (that.roleId == '99') {
                    that.toastr.success('User is rejected by Superadmin');
                }
                if (that.roleId == '100') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
                if (that.roleId == '101') {
                    that.toastr.success('User is rejected by MIT Swimming Pool Admin');
                }
                if (that.roleId == '200') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
            }
            $('#modal').modal("hide");
            that.updateForm.patchValue({
                reason: '',
              });
            that._marathon.getbangloreDashboard(that.tabtype).subscribe(data => {
                that.dashboad = data.data;
            })

            that.pending("");
            // location.reload();
        })
    }
    if (that.roleId == '101') {

        that._marathon.rejectStatusswim(that.rejected_id, 3,that.f.reason.value ? that.f.reason.value : '').subscribe(data => {
            if (data.status.code == 0) {
                if (that.roleId == '99') {
                    that.toastr.success('User is rejected by Superadmin');
                }
                if (that.roleId == '100') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
                if (that.roleId == '101') {
                    that.toastr.success('User is rejected by MIT Swimming Pool Admin');
                }
                if (that.roleId == '200') {
                    that.toastr.success('User is rejected by Marena Admin');
                }
            }
            that.updateForm.patchValue({
                reason: '',
              });
            $('#modal').modal("hide");
            that._marathon.getDashboard(that.tabtype).subscribe(data => {
                that.dashboad = data.data;
            })

            that.pending("");
            // location.reload();
        })
    }

}, function () {
    that.toastr.error('Reject action cancelled')
});
    }



    uploadExcelToggle(e) {
        this.show_excel_upload = true;
        this.resetFilter(null);
    }

    viewImage(e, obj) {
        e.stopPropagation();
        this.view_image = null;
        if (!obj.emp_id_image) {
            return false;
        }
        this.view_image = this.media_domain + obj.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }

    search(e) {
        e.stopPropagation();

        console.log(e.target.value);
        console.log(this.pagevalue);
        
        
        // e.stopPropagation();
   

        // this._marathon.getDashboard().subscribe(data => {
        //     this.dashboad = data.data;
        // })


        //uncomment this if got any error in search 
        // this.changePage(1);


        this.search_txt = e && e.target && e.target.value ? e.target.value : '';
        //   this.refresh();
        if (this.pagevalue == 'registration') {
            this.is_downloadreg = true;
            this.is_downloadpend = false;
            this.is_downloadreject = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            // this.refresh();
            var that=this
            if (that.roleId == '100' || that.roleId == '99') {
                that._marathon.getMarathon(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (that.roleId == '101') {
                that._marathon.getSwim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (that.roleId == '200') {
                that._marathon.getbanglrMarathon(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type, this.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
        } else if (this.pagevalue == 'cancel') {
            this.register_list = [];
            this.pendingstate = false;
            this.is_downloadreg = false;
            this.is_downloadreject = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadcan = true;
            this._marathon.getMarathonCancel(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'cancel';
            }, error => {
            });
        } else if (this.pagevalue == 'accept') {
            this.register_list = [];
            this.pendingstate = false;
            this._marathon.getMarathonAccept(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'accept';
            }, error => {
            });
        }
        // else if (this.pagevalue == 'refund') {
        //     this.register_list = [];
        //     this.pendingstate = false;
        //     this.is_downloadreg = false;
        //     this.is_downloadpend = false;
        //     this.is_downloadref = true;
        //     this.is_downloadcan = false;
        //     this._marathon.getMarathonRefund(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
        //         this.register_list = data.data.docs;
        //         this.cur_page = data.data.page;
        //         this.totalcount = data.data.totalDocs;
        //         this.totalPages = data.data.totalPages;
        //         this.pagevalue = 'refund';
        //     }, error => {
        //     });
        // } 
        else if (this.pagevalue == 'pending') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadreject = false;
            this.is_downloadpend = true;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            var that = this;
            if (that.roleId == '100' || that.roleId == '99') {
                that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'pending';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (that.roleId == '101') {
                that._marathon.getMarathonPendingswim(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'pending';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else if (this.pagevalue == 'approve') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadreject = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = true;
            this.is_downloadoutdate = false;
            // var that = this;
            // if(that.roleId == '100' || '99'){
            //     that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
            //         that.register_list = data.data.docs;
            //         that.cur_page = data.data.page;
            //         that.totalcount = data.data.totalDocs;
            //         that.totalPages = data.data.totalPages;
            //         that.pagevalue = 'approve';
            //         that._marathon.getDashboard().subscribe(data => {
            //             that.dashboad = data.data;
            //         })
            //     }, error => {
            //     });
            //   }
            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getUserlistapproved(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'approve';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getapproveswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'approve';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else if (this.pagevalue == 'reject') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadreject = true;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadoutdate = false;
            // var that = this;
            // if(that.roleId == '100' || '99'){
            //     that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
            //         that.register_list = data.data.docs;
            //         that.cur_page = data.data.page;
            //         that.totalcount = data.data.totalDocs;
            //         that.totalPages = data.data.totalPages;
            //         that.pagevalue = 'approve';
            //         that._marathon.getDashboard().subscribe(data => {
            //             that.dashboad = data.data;
            //         })
            //     }, error => {
            //     });
            //   }
            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getUserlistrejected(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'reject';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getrejectswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'reject';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }

        else if (this.pagevalue == 'outdate') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadoutdate = true;

            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getoutdated(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'outdate';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getoutdatedswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'outdate';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else {

            console.log("else is called");
            
            this.refresh();
        }

    }

    changePage(page) {

        this.cur_page = page;
        // console.log(this.pagevalue);

        if (this.pagevalue == 'registration') {
            this.is_downloadreg = true;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadreject = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadoutdate = false;
            // this.refresh();
            var that = this;
            if (that.roleId == '100' || that.roleId == '99') {
                that._marathon.getMarathon(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type, that.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }

            if (that.roleId == '200') {
                that._marathon.getbanglrMarathon(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type, that.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (that.roleId == '101') {
                that._marathon.getSwim(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type, that.membership_period).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'registration';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
        } else if (this.pagevalue == 'cancel') {
            this.register_list = [];
            this.pendingstate = false;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadreject = false;
            this.is_downloadcan = true;
            if(this.roleId == '200'){
                this._marathon.getbanglrMarathonCancel(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.totalcount = data.data.totalDocs;
                    this.cur_page = data.data.page;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'cancel';
                }, error => {
                });
            }
            else{
            this._marathon.getMarathonCancel(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.totalcount = data.data.totalDocs;
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'cancel';
            }, error => {
            });
        }
        } else if (this.pagevalue == 'accept') {
            this.register_list = [];
            this.pendingstate = false;
            if(this.roleId == '200'){
                this._marathon.getbanglrMarathonAccept(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'accept';
                }, error => {
                }); 
            }
            else{
            this._marathon.getMarathonAccept(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                this.register_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.totalPages = data.data.totalPages;
                this.from = data.data.pagingCounter;
                this.pagevalue = 'accept';
            }, error => {
            });
        }
        }
        // else if (this.pagevalue == 'refund') {
        //     this.register_list = [];
        //     this.pendingstate = false;
        //     this.is_downloadreg = false;
        //     this.is_downloadpend = false;
        //     this.is_downloadref = true;
        //     this.is_downloadcan = false;
        //     this._marathon.getMarathonRefund(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
        //         this.register_list = data.data.docs;
        //         this.cur_page = data.data.page;
        //         this.totalcount = data.data.totalDocs;
        //         this.totalPages = data.data.totalPages;
        //         this.pagevalue = 'refund';
        //     }, error => {
        //     });
        // } 
        else if (this.pagevalue == 'pending') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = true;
            this.is_downloadref = false;
            this.is_downloadreject = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadoutdate = false;
            // this._marathon.getMarathonPending(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
            //     this.register_list = data.data.docs;
            //     this.totalcount = data.data.totalDocs;
            //     this.cur_page = data.data.page;
            //     this.totalPages = data.data.totalPages;
            //     this.pagevalue = 'pending';
            // }, error => {
            // });

            var that = this;
            if (that.roleId == '100' || that.roleId == '99') {
                that._marathon.getMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'pending';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }

            if (that.roleId == '200') {
                that._marathon.getbanglrMarathonPending(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'pending';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (that.roleId == '101') {
                that._marathon.getMarathonPendingswim(that.cur_page, that.search_txt, that.selected_category, that.selected_tshirt, that.selected_attendee_type).subscribe(data => {
                    that.register_list = data.data.docs;
                    that.cur_page = data.data.page;
                    that.totalcount = data.data.totalDocs;
                    that.totalPages = data.data.totalPages;
                    that.from = data.data.pagingCounter;
                    that.pagevalue = 'pending';
                    // that._marathon.getDashboard().subscribe(data => {
                    //     that.dashboad = data.data;
                    // })
                }, error => {
                });
            }
        }
        else if (this.pagevalue == 'approve') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadreject = false;
            this.is_downloadapprove = true;
            this.is_downloadoutdate = false;

            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getUserlistapproved(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'approve';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '200') {
                this._marathon.getbanglrUserlistapproved(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'approve';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getapproveswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'approve';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else if (this.pagevalue == 'reject') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadreject = true;
            this.is_downloadoutdate = false;

            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getUserlistrejected(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'reject';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '200') {
                this._marathon.getbanglrUserlistrejected(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'reject';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getrejectswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'reject';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else if (this.pagevalue == 'outdate') {
            this.register_list = [];
            this.pendingstate = true;
            this.is_downloadreg = false;
            this.is_downloadpend = false;
            this.is_downloadref = false;
            this.is_downloadcan = false;
            this.is_downloadapprove = false;
            this.is_downloadreject = false;
            this.is_downloadoutdate = true;

            if (this.roleId == '100' || this.roleId == '99') {
                this._marathon.getoutdated(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'outdate';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '200') {
                this._marathon.getbanglroutdated(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'outdate';
                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }
            if (this.roleId == '101') {
                this._marathon.getoutdatedswim(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt, this.selected_attendee_type).subscribe(data => {
                    this.register_list = data.data.docs;
                    this.cur_page = data.data.page;
                    this.totalcount = data.data.totalDocs;
                    this.totalPages = data.data.totalPages;
                    this.from = data.data.pagingCounter;
                    this.pagevalue = 'outdate';

                    // this._marathon.getDashboard().subscribe(data => {
                    //     this.dashboad = data.data;
                    // })
                }, error => {
                });
            }

        }
        else {
            this.refresh();
        }
    }



    changeFilter(e, type) {
        e.stopPropagation();
        if (type === 'category') {
            if (e.target.value === '-1') {
                this.selected_category = null;
                this.register_list = [];
                this.refresh();
            } else {
                this.selected_category = e.target.value;
                if(this.roleId == '200'){
                    this._marathon.getbangloreDashboard( this.tabtype).subscribe(data => {
                        this.dashboad = data.data;
                    })
                }

                else{
                    this._marathon.getDashboard( this.tabtype).subscribe(data => {
                        this.dashboad = data.data;
                    })
                }
               
               
                this.changePage(1);

            }
        }
        else if (type == 'attendee_type') {
            console.log(type)

            if (e.target.value === '-1') {

                this.selected_attendee_type = null;
                this.register_list = [];
                this.refresh();
            } else {

                this.selected_attendee_type = e.target.value;
                if(this.roleId == '200'){
                    this._marathon.getbangloreDashboard( this.tabtype).subscribe(data => {
                        this.dashboad = data.data;
                    })
                }
                else{
                this._marathon.getDashboard( this.tabtype).subscribe(data => {
                    this.dashboad = data.data;
                })
            }
                this.changePage(1);

            }
        } else if (type == 'membership_period') {
            console.log(type)

            if (e.target.value === '-1') {

                this.membership_period = null;
                this.register_list = [];
                this.refresh();
            } else {
                this.membership_period = e.target.value;

                if(this.roleId == '200'){
                    this._marathon.getbangloreDashboard( this.tabtype).subscribe(data => {
                        this.dashboad = data.data;
                    })
                }
                else{
                this._marathon.getDashboard( this.tabtype).subscribe(data => {
                    this.dashboad = data.data;
                })

            }
                this.changePage(1);
            }
        }
        else {
            this.refresh();
        }
    }

    resetFilter(e) {
        this.selected_category = null;
        this.selected_tshirt = null;
        this.selected_attendee_type = null;
        this.membership_period = null;
        this.search_txt = '';
        $('#member_p').val('-1');
        $('#search1').val('');
        this.refresh(true);

    }


  
}



