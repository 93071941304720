<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">

    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/category-list">Category List</a></li>
                        <li class="breadcrumb-item active">Category Add</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>


    <section class="content">
        <div class="container-fluid page_section">
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content reg_view_form">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <div class="register_view_top">
                                            <div class="registartion_vleft">

                                            </div>

                                            <div class="filler"></div>

                                        </div>
                                        <form class="registration_view_form" [formGroup]="categoryAddForm" #myForm="ngForm"
                                            (ngSubmit)="onSubmit(categoryAddForm, myForm)">
                                            <!-- mareena start-->
                                            <div class="col-sm-12" *ngIf="roleId == '99' || roleId == '100' || roleId == '200'">
                                                <div class="row">

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee name">Name <span class="mandatory">*</span></label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="name"
                                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.pattern">Empty space is not
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="one_month_amount">One Month Amount <span class="mandatory">*</span></label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="one_month_amount"
                                                                [ngClass]="{ 'is-invalid': (f.one_month_amount.touched || submitted) && f.one_month_amount.errors }">
                                                            <div *ngIf="(f.one_month_amount.touched || submitted) && f.one_month_amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.one_month_amount.errors?.required">One
                                                                    Month Amount is
                                                                    required</div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.email.touched || submitted) && f.email.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.email.errors?.pattern">Enter valid email pattern</div>
                                                        </div> -->
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="year_amount">Year Amount <span class="mandatory">*</span></label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="year_amount"
                                                                [ngClass]="{ 'is-invalid': (f.year_amount.touched || submitted) && f.year_amount.errors }">
                                                            <div *ngIf="(f.year_amount.touched || submitted) && f.year_amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.year_amount.errors?.required">
                                                                    Year Amount is required</div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.phone_no.touched || submitted) && f.phone_no.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.phone_no.errors?.pattern">
                                                               Enter only digits</div>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="two_month_amount">Two Month Amount</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                id="two_month_amount" formControlName="two_month_amount"
                                                                [ngClass]="{ 'is-invalid': (f.two_month_amount.touched || submitted) && f.two_month_amount.errors }">
                                                            <div *ngIf="(f.two_month_amount.touched || submitted) && f.two_month_amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.two_month_amount.errors?.required">Two
                                                                    Month Amount is required</div>
                                                            </div>
                                                        </div>
                                                    </div> -->


                                                </div>


                                                <div class="row">
                                                    <!-- <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="three_month_amount">Three Month Amount</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="three_month_amount"
                                                                [ngClass]="{ 'is-invalid': (f.three_month_amount.touched || submitted) && f.three_month_amount.errors }">
                                                            <div *ngIf="(f.three_month_amount.touched || submitted) && f.three_month_amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.three_month_amount.errors?.required">Three
                                                                    Month Amount
                                                                    is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="six_month_amount">Six Month Amount</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="six_month_amount"
                                                                [ngClass]="{ 'is-invalid': (f.six_month_amount.touched || submitted) && f.six_month_amount.errors }">
                                                            <div *ngIf="(f.six_month_amount.touched || submitted) && f.six_month_amount.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.six_month_amount.errors?.required">Six
                                                                    Month Amount is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div> -->
                                                    
                                                </div>

                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="current_status">Status <span class="mandatory">*</span></label>
                                                            <!-- <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="current_status"
                                                                [ngClass]="{ 'is-invalid': (f.current_status.touched || submitted) && f.current_status.errors }"> -->

                                                            <select id="status" class="normal_background form-control"
                                                                [ngClass]="{ 'is-invalid': (f.current_status.touched || submitted) && f.current_status.errors }"
                                                                formControlName="current_status">
                                                                <option value='' selected disabled>Select Status</option>
                                                                <option value='1'>Active</option>
                                                                <option value='0'>Inactive</option>
                                                                <!-- <option *ngFor="let cat of category; let x=index"
                                                                    [value]="cat.type">
                                                                    {{cat.name | titlecase}}
                                                                </option> -->
                                                            </select>
                                                            <div *ngIf="(f.current_status.touched || submitted) && f.current_status.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.current_status.errors?.required">Status is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div>
                                            <!-- mareena end-->


                                            <!-- swimmming start-->
                                            <!-- first -->
                                            <!-- <div class="col-sm-12" *ngIf="roleId == '101' && types=='1' ">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="swimming1_name">Name</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="swimming1_name"
                                                                [ngClass]="{ 'is-invalid': (f.swimming1_name.touched || submitted) && f.swimming1_name.errors }">
                                                            <div *ngIf="(f.swimming1_name.touched || submitted) && f.swimming1_name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.swimming1_name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.swimming1_name.touched || submitted) && f.swimming1_name.errors?.pattern"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.swimming1_name.errors?.pattern">Empty space is not
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="studenthalfyear_fees">Student Half Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="studenthalfyear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.studenthalfyear_fees.touched || submitted) && f.studenthalfyear_fees.errors }">
                                                            <div *ngIf="(f.studenthalfyear_fees.touched || submitted) && f.studenthalfyear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.studenthalfyear_fees.errors?.required">Student Half Year Fees is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="studentyear_fees">Student Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                id="studentyear_fees" formControlName="studentyear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.studentyear_fees.touched || submitted) && f.studentyear_fees.errors }">
                                                            <div *ngIf="(f.studentyear_fees.touched || submitted) && f.studentyear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.studentyear_fees.errors?.required">Student Year Fees is required</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="staffhalfyear_fees">Staff Half Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="staffhalfyear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.staffhalfyear_fees.touched || submitted) && f.staffhalfyear_fees.errors }">
                                                            <div *ngIf="(f.staffhalfyear_fees.touched || submitted) && f.staffhalfyear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.staffhalfyear_fees.errors?.required">Staff Half Year Fees is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="staffyear_fees">Staff Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="staffyear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.staffyear_fees.touched || submitted) && f.staffyear_fees.errors }">
                                                            <div *ngIf="(f.staffyear_fees.touched || submitted) && f.staffyear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.staffyear_fees.errors?.required">Staff Year Fees is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="otherhalfyear_fees">Other Half Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="otherhalfyear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.otherhalfyear_fees.touched || submitted) && f.otherhalfyear_fees.errors }">
                                                            <div *ngIf="(f.otherhalfyear_fees.touched || submitted) && f.otherhalfyear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.otherhalfyear_fees.errors?.required">
                                                                    Other Half Year Fees is required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="otheryear_fees">Other Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="otheryear_fees"
                                                                [ngClass]="{ 'is-invalid': (f.otheryear_fees.touched || submitted) && f.otheryear_fees.errors }">
                                                            <div *ngIf="(f.otheryear_fees.touched || submitted) && f.otheryear_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.otheryear_fees.errors?.required">
                                                                    Other Year Fees is required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="coaching_fees">Coaching Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="coaching_fees"
                                                                [ngClass]="{ 'is-invalid': (f.coaching_fees.touched || submitted) && f.coaching_fees.errors }">
                                                            <div *ngIf="(f.coaching_fees.touched || submitted) && f.coaching_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.coaching_fees.errors?.required">
                                                                    Coaching Fees is required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="current_status1">Status</label>
                                                           
                                                            <select id="status" class="normal_background form-control"
                                                                [ngClass]="{ 'is-invalid': (f.current_status1.touched || submitted) && f.current_status1.errors }"
                                                                formControlName="current_status1">
                                                                <option value='' selected disabled>Select Status</option>
                                                                <option value='1'>Active</option>
                                                                <option value='0'>Inactive</option>
                                                               
                                                            </select>
                                                            <div *ngIf="(f.current_status1.touched || submitted) && f.current_status1.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.current_status1.errors?.required">Status is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div> -->

                                            <!-- 2nd & 3rd -->
                                            <!-- <div class="col-sm-12" *ngIf="roleId == '101' && (types=='2' || types=='3')">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="swimming2_name">Name</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="swimming2_name"
                                                                [ngClass]="{ 'is-invalid': (f.swimming2_name.touched || submitted) && f.swimming2_name.errors }">
                                                            <div *ngIf="(f.swimming2_name.touched || submitted) && f.swimming2_name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.swimming2_name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="month_fees">Month Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="month_fees"
                                                                [ngClass]="{ 'is-invalid': (f.month_fees.touched || submitted) && f.month_fees.errors }">
                                                            <div *ngIf="(f.month_fees.touched || submitted) && f.month_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.month_fees.errors?.required">
                                                                    Month Fees is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="year_fees">Year Fees</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                id="year_fees" formControlName="year_fees"
                                                                [ngClass]="{ 'is-invalid': (f.year_fees.touched || submitted) && f.year_fees.errors }">
                                                            <div *ngIf="(f.year_fees.touched || submitted) && f.year_fees.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.year_fees.errors?.required">Two
                                                                    Year Fees is required</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="current_status2">Status</label>
                                                           
                                                            <select id="status" class="normal_background form-control"
                                                                [ngClass]="{ 'is-invalid': (f.current_status2.touched || submitted) && f.current_status2.errors }"
                                                                formControlName="current_status2">
                                                                <option value='' selected disabled>Select Status</option>
                                                                <option value='1'>Active</option>
                                                                <option value='0'>Inactive</option>
                                                               
                                                            </select>
                                                            <div *ngIf="(f.current_status2.touched || submitted) && f.current_status2.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.current_status2.errors?.required">Status is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div> -->
                                            <!-- swimming end-->

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>