<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading
  [show]="loading"
  class="loader"
  [template]="customLoadingTemplate"
></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb cron_main">
            <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
            <li class="breadcrumb-item active">Staff List</li>
            <li class="filler"></li>
            <li class="cron_details">
              Cron start:
              <button class="sync_cls" (click)="sync()">Sync</button>
            </li>
            <li class="cron_details">
              Cron started: {{ staffcron && staffcron._id }}
            </li>
            <li class="cron_details">Cron type: Staff</li>
            <li class="cron_details">
              Records retrived: {{ staffcron && staffcron.records_retrived }}
            </li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                (keyup.enter)="search($event)"
                placeholder="Search Enrollment No., Name, Email, Phone No."
                autocomplete="off"
              />
            </div>
            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="order_tabs">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'active_student' }"
                data-toggle="pill"
                (click)="active_student($event)"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Active</span>

                    <span class="info-box-number">{{
                      student && student.active
                        ? (student && student.active | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-user-check"></i>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'inactive_student' }"
                data-toggle="pill"
                (click)="inactiveStudent($event)"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Inactive</span>
                    <span class="info-box-number">{{
                      student && student.inactive
                        ? (student && student.inactive
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-stop-circle"></i>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >
                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <!-- <th width="10%">Reg. ID</th> -->
                            <th width="10%">Enrollment No.</th>
                            <th width="10%">Name</th>
                            <th width="10%">Gender</th>
                            <th width="10%">DOB</th>
                            <th width="10%">Email</th>
                            <th width="10%">Mobile Number</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container
                            *ngFor="
                              let list of student_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <tr
                              [ngClass]="
                                list.cancel_status == 1 &&
                                list.status == 1 &&
                                list.refund_status == 0 &&
                                list.total_amount > 0
                                  ? 'clr_cls'
                                  : ' '
                              "
                            >
                              <!-- <td>{{ list && list.reg_ID ? list.reg_ID : "-"}}</td> -->
                              <td>
                                {{
                                  list && list.enrollment_Number
                                    ? list.enrollment_Number
                                    : "-"
                                }}
                              </td>
                              <td class="name_cls">
                                {{ list && list.name ? list.name : "-" }}
                              </td>
                              <td>
                                {{ list && list.gender ? list.gender : "-" }}
                              </td>
                              <td>{{ list && list.dob ? list.dob : "-" }}</td>
                              <td>
                                {{ list && list.email ? list.email : "-" }}
                              </td>
                              <td>
                                {{
                                  list && list.phone_no ? list.phone_no : "-"
                                }}
                              </td>
                            </tr>
                          </ng-container>
                          <tr *ngIf="!student_list.length">
                            <td colspan="9" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
