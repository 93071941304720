import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-conference-registration-list',
  templateUrl: './conference-registration-list.component.html',
  styleUrls: ['./conference-registration-list.component.css']
})
export class ConferenceRegistrationListComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  cur_page = 1;
  from = 0;
  perPage = 10;
  pageSize = 10;
  totalPages = 10;
  totalcount: any;
  search_txt = '';
  student_list = [];
  // totalcount: any;
  student: any;








  roleId = localStorage.getItem(`${environment.appName}` + '_role');
  activeclass = "active_student";


  // @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

  pagevalue: any;
  studentcron: any;
  fileType: any;
  file: any;
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;


  constructor(
      private setting: SettingsService,
      private formBuilder: FormBuilder,
      private authenticationService: AuthenticationService,
      private modalService: ModalService,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private _marathon: MarathonService,
      private http: HttpClient,
      private router: Router) { }
  public async reportDown(): Promise<void> {
      this.submitted = true;
      // this.loading = true;
      this.loading = false;

  }
  ngOnInit() {
      localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

      //    this.roleId = '100';
      // this._marathon.getStudentStatusCount().subscribe(data => {
      //     this.student = data.data;
      // })
      this.refresh(true);
      // this._marathon.getConferenceList().subscribe(data => {
      //     this.studentcron = data.data;
      //     this.loading = false;
      // })


  }

  inactiveStudent(e) {

      e.stopPropagation();

      this.activeclass = 'inactive_student';
      localStorage.setItem(`${environment.appName}` + 'activeClick', 'inactive_student');

      this.student_list = [];



      this.loading = true;

      this._marathon.getConferenceregList(1, this.search_txt).subscribe(data => {

          // this._marathon.getconferenceCount().subscribe(data => {
          //     this.student = data.data;
          //     this.loading = false;
          // })
          this.loading = false;
          this.student_list = data.data.docs;
          this.totalcount = data.data.totalDocs;
          this.from = data.data.pagingCounter;
          this.cur_page = data.data.page;
          this.totalPages = data.data.totalPages;
          this.pagevalue = 'inactive_student';

      }, error => {
      });
  }


  active_student(e) {
      e.stopPropagation();

      this.activeclass = "active_student";


      localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

      this.cur_page = 1;

      this.student_list = [];



      this.loading = true;

      this._marathon.getConferenceregList(1, this.search_txt).subscribe(data => {

          // this._marathon.getconferenceCount().subscribe(data => {
          //     this.student = data.data;
          //     this.loading = false;
          // })
          this.loading = false;
          this.student_list = data.data.docs;
          this.totalcount = data.data.totalDocs;
          this.from = data.data.pagingCounter;
          this.cur_page = data.data.page;
          this.totalPages = data.data.totalPages;
          this.pagevalue = 'active_student';


      }, error => {
      });


  }

  public async downloadExcel() {
    console.log("asdf");

            const blob = await this._marathon.getDownloadUser(this.search_txt);
            let binaryData = [];
            binaryData.push(blob);
            const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
            const link = this.downloadZipLink.nativeElement;
            link.href = url;
            link.download = 'registration.xlsx';
            link.click();
    }

  upload() {
    console.log(this.file)
    if (!this.fileType) {
      var that = this;
      that.toastr.error('Please upload excel file');
      return false;
    }
    else if (this.fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
     this.loading=true;
        this._marathon.uploadconferencelist(this.file).subscribe(data => {
        if (data.status.code == 0) {
        this.file = "";
        this.toastr.success(data.status.message)
        $('#upfile').val('');
        this._marathon.getConferenceregList(1, this.search_txt).subscribe(data => {

          // this._marathon.getconferenceCount().subscribe(data => {
          //     this.student = data.data;
          //     this.loading = false;
          // })
          this.loading = false;
          this.student_list = data.data.docs;
          this.totalcount = data.data.totalDocs;
          this.from = data.data.pagingCounter;
          this.cur_page = data.data.page;
          this.totalPages = data.data.totalPages;
          this.pagevalue = 'active_student';


      }, error => {
      });
    }else{
        this.toastr.error(data.status.message)
        this.loading=false
        $('#upfile').val('')
    }
      });

   
    } else {
      var that = this;
      that.toastr.error('only excel file is allowed');
      $('#upfile').val('');
      that.file = "";
      return false;
    }
  }

  download() {
    location.href = '/assets/sample_conference_upload_format.xlsx?' + new Date().getTime();
  }




  handleFileInput(event) {
    this.fileType = event.target.files[0].type;
    this.file = event.target.files[0];
  }



  refresh(reset = false) {

      this.activeclass = "active_student";


      // this._marathon.getconferenceCount().subscribe(data => {
      //     this.student = data.data;
      // })



      this.loading = true;


      this._marathon.getConferenceregList(this.cur_page, this.search_txt).subscribe(data => {

          this.loading = false;
          this.student_list = data.data.docs;
          this.totalcount = data.data.totalDocs;
          this.from = data.data.pagingCounter;
          this.cur_page = data.data.page;
          this.totalPages = data.data.totalPages;
          this.pagevalue = 'active_student';


      }, error => {
      });

  }


  search(e) {
      e.stopPropagation();
      // this._marathon.getStudentStatusCount().subscribe(data => {
      //     this.student = data.data;
      // })
      this.search_txt = e.target.value;

      this.changePage(1);

      this.loading=true;
      //   this.refresh();
      if (this.pagevalue == 'active_student') {

          var that = this;
          that._marathon.getConferenceregList(this.cur_page, this.search_txt).subscribe(data => {
              that.student_list = data.data.docs;
              that.cur_page = data.data.page;
              that.totalcount = data.data.totalDocs;
              that.totalPages = data.data.totalPages;
              that.pagevalue = 'active_student';
              that.from = data.data.pagingCounter;
              this.loading = false;
              // that._marathon.getconferenceCount().subscribe(data => {
              //     that.student = data.data;
              //     this.loading=false;
              // })
          }, error => {
          });

      }

      else if (this.pagevalue == 'inactive_student') {
          this.student_list = [];


          this._marathon.getConferenceregList(this.cur_page, this.search_txt).subscribe(data => {
              this.student_list = data.data.docs;
              this.cur_page = data.data.page;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.totalPages = data.data.totalPages;
              this.pagevalue = 'inactive_student';
              this.loading = false;
              // this._marathon.getconferenceCount().subscribe(data => {
              //     this.student = data.data;
              //     this.loading=false;
              // })
          }, error => {
          });


      }
      else {
          this.refresh();
      }

  }

  changePage(page) {

      this.cur_page = page;
      console.log(this.pagevalue);

      if (this.pagevalue == 'active_student') {

          // this.refresh();
          var that = this;


          that._marathon.getConferenceregList(that.cur_page, that.search_txt).subscribe(data => {
              that.student_list = data.data.docs;
              that.cur_page = data.data.page;
              that.totalcount = data.data.totalDocs;
              that.totalPages = data.data.totalPages;
              that.from = data.data.pagingCounter;
              that.pagevalue = 'active_student';
              this.loading = false;
              // that._marathon.getconferenceCount().subscribe(data => {
              //     that.student = data.data;
              // })
          }, error => {
          });

      }
      else if (this.pagevalue == 'inactive_student') {
          this.student_list = [];



          this._marathon.getConferenceregList(this.cur_page, this.search_txt).subscribe(data => {
              this.student_list = data.data.docs;
              this.cur_page = data.data.page;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.totalPages = data.data.totalPages;
              this.pagevalue = 'inactive_student';
              this.loading = false;
              // this._marathon.getconferenceCount().subscribe(data => {
              //     this.student = data.data;
              // })
          }, error => {
          });


      }
      else {
          this.refresh();
      }
  }



  resetFilter(e) {
      this.search_txt = '';
      $('#search1').val('');
      this.refresh(true);
  }

  sync(){
      var that = this;
      that._marathon.getStudent().subscribe(data=>{
          that.toastr.success("Syncing is done");
      })
  }

}
