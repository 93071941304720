import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';
import { threadId } from 'worker_threads';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-eraya-registration',
  templateUrl: './eraya-registration.component.html',
  styleUrls: ['./eraya-registration.component.css']
})
export class ErayaRegistrationComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  cur_page = 1;
  from = 0;
  perPage = 10;
  pageSize = 10;
  totalPages = 10;
  totalcount: any;
  search_txt = '';
  student_list = [];
  // totalcount: any;
  student: any;
  totalapayment: any;








  roleId = localStorage.getItem(`${environment.appName}` + '_role');
  activeclass = "active_student";


  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

  pagevalue: any;
  studentcron: any;
  fileType: any;
  file: any;
  teamdetails: any;


  constructor(
    private setting: SettingsService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _marathon: MarathonService,
    private http: HttpClient,
    private router: Router) { }
  public async reportDown(): Promise<void> {
    this.submitted = true;
    // this.loading = true;
    this.loading = false;

  }
  ngOnInit() {
    localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

    this._marathon.geterayapaymentcount().subscribe(data => {
      if(data.data&&data.data[0]){
      this.totalapayment = data.data[0].total_amount
      console.log("jk", data)
      }

    })


    this.refresh(true);


  }

  public async downloadExcel() {


    // if(this.roleId == '100'){
    // const blob = await this._marathon.getPaymentListDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt,"fhg");
    const blob = await this._marathon.geterayaDownload();
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'Payment.xlsx';
    link.click();
    // }

  }





  active_student(e) {
    // e.stopPropagation();
    this._marathon.geterayaRegList(1, this.search_txt).subscribe(data => {

      this.student_list = data.data.docs;
      this.from = data.data.pagingCounter;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.pagevalue = 'active_student';


    });
    this.activeclass = "active_student";


    localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

    this.cur_page = 1;

    this.student_list = [];



    this.loading = true;




  }







  view(e, list) {
    console.log(list, e);
    this.teamdetails=[]
    e.stopPropagation();
    this.teamdetails = list.team_members
    // }
    $("#myModal").modal("show");
  }

  closeImage(event:any)
  {
    $("#myModal").modal("hide");
  }

  refresh(reset = false) {

    this.activeclass = "active_student";
    this.loading = true;


    this._marathon.geterayaRegList(this.cur_page, this.search_txt).subscribe(data => {

      this.loading = false;
      console.log(data.data.docs)
      this.student_list = data.data.docs;
      this.totalcount = data.data.totalDocs;
      this.from = data.data.pagingCounter;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.pagevalue = 'active_student';


    }, error => {
    });

  }


  search(e) {
    e.stopPropagation();

    this.search_txt = e.target.value;
    console.log("kl", this.search_txt)

    this.changePage(1);

    this.loading = true;
    //   this.refresh();
    this._marathon.geterayaRegList(this.cur_page, this.search_txt).subscribe(data => {
      this.student_list = data.data.docs;
      this.loading = false;
      this.cur_page = data.data.page;
      this.totalcount = data.data.totalDocs;
      this.totalPages = data.data.totalPages;
      this.pagevalue = 'active_student';
      this.from = data.data.pagingCounter;

    }, error => {
    });




  }

  changePage(page) {

    this.cur_page = page;
    console.log(this.pagevalue);

    if (this.pagevalue == 'active_student') {

      // this.refresh();
      var that = this;


      that._marathon.geterayaRegList(that.cur_page, that.search_txt).subscribe(data => {
        that.student_list = data.data.docs;
        that.cur_page = data.data.page;
        that.totalcount = data.data.totalDocs;
        that.totalPages = data.data.totalPages;
        that.from = data.data.pagingCounter;
        that.pagevalue = 'active_student';

      }, error => {
      });

    }
    else if (this.pagevalue == 'inactive_student') {
      this.student_list = [];



      this._marathon.getInActivephdList(this.cur_page, this.search_txt).subscribe(data => {
        this.student_list = data.data.docs;
        this.cur_page = data.data.page;
        this.totalcount = data.data.totalDocs;
        this.from = data.data.pagingCounter;
        this.totalPages = data.data.totalPages;
        this.pagevalue = 'inactive_student';


      }, error => {
      });


    }
    else {
      this.refresh();
    }
  }



  resetFilter(e) {
    this.search_txt = '';
    $('#search1').val('');
    this.refresh(true);
  }

  sync() {
    var that = this;
    that._marathon.getStudent().subscribe(data => {
      that.toastr.success("Syncing is done");
    })
  }

}

