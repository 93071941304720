<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li
              *ngIf="roleId == '100' || roleId == '101' || roleId == '103' || roleId == '200'"
              class="breadcrumb-item active"
            >
              Payment Logs
            </li>
            <li *ngIf="roleId == '104'" class="breadcrumb-item active">
              Almashine Payment Logs
            </li>
            <li *ngIf="roleId == '102'" class="breadcrumb-item active">
              Coursera Payment Logs
            </li>
            <!-- <li *ngIf="roleId == '104'" class="breadcrumb-item active">
              Almashine Payment Logs
            </li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                class="search1"
                (keyup.enter)="search($event)"
                autocomplete="off"
                placeholder="Search Order ID,Name, Phone No. ,Receipt No."
              />
            </div>

            <div class="col-sm-4">
              <div class="input-box-container">
                <input
                  class="input-box search1"
                  placeholder="YYYY/MM/DD"
                  autocomplete="false"
                  (dateChanged)="filterDate($event)"
                  angular-mydatepicker
                  name="mydate"
                  (click)="dp.toggleCalendar()"
                  readonly
                  [(ngModel)]="model"
                  [options]="myDpOptions"
                  id="search1"
                  name="search1"
                  #dp="angular-mydatepicker"
                />
              </div>
            </div>

            <div class="col-sm-3">
              <select
                id="status"
                class="normal_background form-control"
                (change)="filterStatus($event)"
              >
                <option value="">All</option>
                <option value="0">Success</option>
                <option value="1">Invalid / Failure</option>
              </select>
            </div>

            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download Payment Logs
                    </button>
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >

                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th width="10%">Date</th>
                            <th width="10%">Order ID</th>
                            <th width="10%">Receipt No.</th>
                            <th width="10%">Bank Ref. No</th>
                            <th width="10%">Tracking ID</th>
                            <th
                              width="10%"
                              *ngIf="roleId != '104' && roleId != '102'"
                            >
                              Category
                            </th>
                            <th width="10%">Billing name</th>
                            <th width="10%">Status</th>
                            <th width="10%">Billing Telephone</th>
                            <th width="10%">Amount</th>
                            <th width="5%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            *ngFor="
                              let list of register_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <td>{{ newcreatedDate[x] }}</td>
                            <td>{{ list.order_id ? list.order_id : "-" }}</td>
                            <td>
                              {{ list.receipt_no ? list.receipt_no : "-" }}
                            </td>
                            <td>
                              {{ list.bank_ref_no ? list.bank_ref_no : "-" }}
                            </td>
                            <td>
                              {{ list.tracking_id ? list.tracking_id : "-" }}
                            </td>
                            <td *ngIf="roleId != '104' && roleId != '102'">
                              <span
                                *ngFor="
                                  let cat of list.category;
                                  let dataLen = index
                                "
                              >
                                {{ cat && cat.name ? cat.name : "-" }}
                                <span
                                  *ngIf="
                                    list.category.length > 1 &&
                                    list.category.length - 1 != dataLen
                                  "
                                  >,</span
                                >
                                <span
                                  *ngIf="list.category.length - 1 == dataLen"
                                >
                                </span>
                              </span>
                            </td>
                            <td>
                              {{ list.billing_name ? list.billing_name : "-" }}
                            </td>
                            <td>
                              {{ list.order_status ? list.order_status : "-" }}
                            </td>
                            <td>
                              {{ list.billing_tel ? list.billing_tel : 0 }}
                            </td>
                            <td>
                              ₹&nbsp;&nbsp;{{ (list.amount ? list.amount : "-") | number }}
                            </td>

                            <td >
                              <a
                                [routerLink]="['/payment-view', list._id]"
                              >
                                <i class="fa fa-eye 2x"> </i>
                              </a>
                            </td>
                           
                          </tr>
                          <tr *ngIf="!register_list.length">
                            <td colspan="11" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
