<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading
  [show]="loading"
  class="loader"
  [template]="customLoadingTemplate"
></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Institution List</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-3">
              <input
                type="Search"
                name="search1"
                id="search1"
                (keyup.enter)="search($event)"
                placeholder="Search Name"
                autocomplete="off"
              />
            </div>
            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
            <div class="reset_btn" *ngIf="roleId == '103'">
              <button
                [routerLink]="['/institution-add']"
                class="btn btn-secondary btn-sm add_category"
              >
                Create Institution
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <!-- <div class="order_tabs">
                    <ul class="nav nav-pills">

                        <li class="nav-item"><a class="nav-link" [ngClass]="{ 'active':activeclass=='active_student'} "
                                data-toggle="pill" (click)="active_student($event)">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content"><span class="info-box-text">On Going</span>

                                        <span class="info-box-number">{{category &&
                                            category.ongoing_count ? category &&
                                            category.ongoing_count : 0}}</span>
                                        <span class="info-box-number" *ngIf="!roleId">0
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </li>



                        <li class="nav-item"><a class="nav-link" [ngClass]="{ 'active':activeclass=='inactive_student'}"
                                data-toggle="pill" (click)="inactiveStudent($event)">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content"><span class="info-box-text">Expired</span>
                                        <span class="info-box-number">{{category &&
                                            category.expired_count ? category &&
                                            category.expired_count : 0}}</span>
                                        <span class="info-box-number" *ngIf="!roleId">0
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </li>




                    </ul>

                </div> -->
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >
                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th width="10%">Name</th>
                            <th width="10%">Status</th>
                            <!-- <th width="10%">Start Date</th>
                                                        <th width="10%">End Date</th>
                                                        <th width="10%">Amount</th>
                                                        <th width="10%">GST</th>
                                                        <th width="10%">Total Amount</th> -->
                            <th width="10%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container
                            *ngFor="let list of institution_list; let x = index"
                          >
                            <tr
                              [ngClass]="
                                list.cancel_status == 1 &&
                                list.status == 1 &&
                                list.refund_status == 0 &&
                                list.total_amount > 0
                                  ? 'clr_cls'
                                  : ' '
                              "
                            >
                              <td>{{ list && list.name ? list.name : "-" }}</td>
                              <td>
                                {{
                                  list && list.active && list.active == 1
                                    ? "Active"
                                    : "Inactive"
                                }}
                              </td>
                              <!-- <td>{{ list && list.description ? list.description : "-"}}
                                                            </td>
                                                            <td class="name_cls">{{ list && list.start_date ?
                                                                (list.start_date| date:"dd-MM-yyyy") : "-"}}</td>
                                                            <td class="name_cls">{{ list && list.end_date ?
                                                                (list.end_date | date:"dd-MM-yyyy"): "-"}}</td>


                                                            <td>{{ list && list.amount ? list.amount : "0"}}</td>
                                                            <td>{{ list && list.gst ? list.gst : "0"}} %</td>
                                                            <td>{{ list && list.total_amount ? list.total_amount : "0"}}</td> -->
                              <!-- <td>
                                                                <button class="copy_link_btn"> Copy Link</button>
                                                            </td>-->
                              <td>
                                <a
                                  [routerLink]="['/institution-edit', list._id]"
                                >
                                  <i class="fa fa-pencil-alt"> </i>
                                </a>

                                <!-- <a > <i (click)="deleteQuicPayInstitution(list._id)"
                                                                        class="fa fa-trash-alt"></i>
                                                                </a> -->
                              </td>
                            </tr>
                          </ng-container>
                          <tr *ngIf="!institution_list.length">
                            <td colspan="9" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <nav aria-label="..." class="paginate_nav">
                    <ul class="pagination">
                      <li
                        class="page-item"
                        [ngClass]="{ disable: cur_page === 1 }"
                        (click)="changePage(cur_page - 1)"
                      >
                        <a class="page-link" tabindex="-1">Previous</a>
                      </li>

                      <li class="page-item active">
                        <a class="page-link"
                          >{{ cur_page
                          }}<span class="sr-only">(current)</span></a
                        >
                      </li>
                      <li
                        class="page-item"
                        [ngClass]="{ disable: cur_page === totalPages }"
                        (click)="changePage(cur_page + 1)"
                      >
                        <a class="page-link">Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
