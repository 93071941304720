<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Payment Logs</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                class="search1"
                autocomplete="off"
                (keyup.enter)="search($event)"
                placeholder="Search Order ID,Name, Phone No., Receipt No."
              />
            </div>

            <div class="col-sm-4">
              <div class="input-box-container">
                <input
                  class="input-box search1"
                  placeholder="YYYY/MM/DD"
                  autocomplete="false"
                  (dateChanged)="filterDate($event)"
                  angular-mydatepicker
                  name="mydate"
                  (click)="dp.toggleCalendar()"
                  readonly
                  [(ngModel)]="model"
                  [options]="myDpOptions"
                  id="search1"
                  name="search1"
                  #dp="angular-mydatepicker"
                />
              </div>
            </div>

            <!-- <div class="col-sm-3">
                            <select id="status" class="normal_background form-control" (change)="filterStatus($event)">
                                <option value=''>All</option>
                                <option value="0">Success</option>
                                <option value="1">Invalid / Failure</option>
                            </select>
                        </div> -->

            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download Payment Logs
                    </button>
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >

                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th width="10%">Date</th>

                            <th width="10%">Order ID</th>
                            <th width="10%">Receipt No.</th>
                            <th width="10%">Bank Ref. No</th>
                            <th width="10%">Enrollment Number</th>
                            <th width="10%">User Name</th>
                            <th width="10%">Phone Number</th>
                            <!-- <th width="10%">Tracking ID</th> -->
                            <!-- <th width="10%">Category</th> -->
                            <th width="10%">Billing name</th>
                            <th width="10%">Purpose</th>
                            <th width="10%">Description</th>
                            <th width="10%">Institution</th>
                            <!-- <th width="10%">Status</th> -->
                            <!-- <th width="10%">Billing Telephone</th> -->
                            <th width="10%">Amount</th>
                            <th width="5%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            *ngFor="
                              let list of payment_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <td>
                              {{
                                list.createdAt
                                  ? (list.createdAt | date : "dd-MM-yyyy")
                                  : "-"
                              }}
                            </td>

                            <td>{{ list.order_id ? list.order_id : "-" }}</td>
                            <td>
                              {{ list.receipt_no ? list.receipt_no : "-" }}
                            </td>
                            <td>
                              {{
                                list &&
                                list.transactions &&
                                list.transactions.bank_ref_no
                                  ? list.transactions.bank_ref_no
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                list &&
                                list.users &&
                                list.users.enrollment_Number
                                  ? list.users.enrollment_Number
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                list && list.users && list.users.name
                                  ? list.users.name
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                list && list.users && list.users.phone_no
                                  ? list.users.phone_no
                                  : "-"
                              }}
                            </td>

                            <!-- <td>{{list && list.transactions && list.transactions.tracking_id?list.transactions.tracking_id:'-'}}</td> -->
                            <!-- <td><span *ngFor="let cat of list.category ; let dataLen=index">
                                                                {{cat && cat.name ? cat.name : '-'}}
                                                                <span
                                                                    *ngIf="((list.category).length > 1 )&& (((list.category).length-1) != dataLen)">,</span>
                                                                <span *ngIf="((list.category).length-1) == dataLen">
                                                                </span>
                                                            </span>
                                                        </td> -->
                            <td>
                              {{
                                list.transactions.billing_name
                                  ? list.transactions.billing_name
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                list.orderdetails.quickpay_name
                                  ? list.orderdetails.quickpay_name
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                list.orderdetails.quickpay_dsc
                                  ? list.orderdetails.quickpay_dsc
                                  : "-"
                              }}
                            </td>
                            <!-- <td>{{list.transactions.order_status ? list.transactions.order_status : '-'}}</td> -->
                            <!-- <td>{{list.transactions.billing_tel ? list.transactions.billing_tel: 0}}</td> -->
                            <td>
                              {{
                                list.orderdetails.institute_name
                                  ? list.orderdetails.institute_name
                                  : "-"
                              }}
                            </td>
                            <td>
                              {{
                                (list.orderdetails.total_amount
                                  ? list.orderdetails.total_amount
                                  : "-"
                                ) | number
                              }}
                            </td>
                            <td>
                              <a
                                [routerLink]="[
                                  '/quickpay-payment-view',
                                  list._id
                                ]"
                              >
                                <i class="fa fa-eye 2x"> </i>
                              </a>
                            </td>
                          </tr>
                          <tr *ngIf="!payment_list.length">
                            <td colspan="13" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <nav aria-label="..." class="paginate_nav">
                    <ul class="pagination">
                      <li
                        class="page-item disabled"
                        [ngClass]="{ disable: cur_page === 1 }"
                        (click)="changePage(cur_page - 1)"
                      >
                        <a class="page-link" tabindex="-1">Previous</a>
                      </li>

                      <li class="page-item active">
                        <a class="page-link"
                          >{{ cur_page
                          }}<span class="sr-only">(current)</span></a
                        >
                      </li>
                      <li
                        class="page-item"
                        [ngClass]="{ disable: cur_page === totalPages }"
                        (click)="changePage(cur_page + 1)"
                      >
                        <a class="page-link">Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
