import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { SiteHeaderComponent } from "./_layouts/site-header/site-header.component";
import { routing } from "./app.routing";
import { ModalComponent } from "./_directives";
import { AuthSuperadminGuard, AuthVendorGuard } from "./_guards";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
// tslint:disable-next-line: max-line-length
import {
  AlertService,
  AuthenticationService,
  SettingsService,
  MarathonService,
  ModalService,
  UserService,
  MitService,
} from "./_services";
import { LoginComponent } from "./login";
import { DataTablesModule } from "angular-datatables";
import { LSelect2Module } from "ngx-select2";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPrintModule } from "ngx-print";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { ToastrModule } from "ngx-toastr";
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DragDirective } from "./dnd.directive";
import { ConfirmedValidator } from "./confirmed.validator";
// tslint:disable-next-line: import-spacing
// import { ReportsComponent } from './reports/reports.component'
import { AdhoclistComponent } from "./adhoclist";
import { AdhocdetailsComponent } from "./adhoc-details";
import { PaymentListComponent } from "./payment_list";
import { QuickPayPaymentListComponent } from "./quickpay_payment_list";
import { CategoryListComponent } from "./category_list";
import { QuickPayCategoryListComponent } from "./quickpay_category_list";
import { InstitutionListComponent } from "./institution_list";
import { StudentUserListComponent } from "./student_user_list";
import { StaffUserListComponent } from "./staff_user_list";

import { PaymentViewComponent } from "./payment_view";
import { QuickPayPaymentViewComponent } from "./quickpay_payment_view";
import { CategoryViewComponent } from "./category_view";

import { CookieService } from "ngx-cookie-service";
import { HomeComponent } from "./home";
import { RegisterListComponent } from "./register_list";
import { RegisterViewComponent } from "./register_view";
import { EventViewComponent } from "./event_view";
import { UploadExcelComponent } from "./upload_excel";
import { EditComponent } from "./edit";
import { CategoryEditComponent } from "./category_edit";
import { CategoryAddComponent } from "./category_add";
import { InstitutionAddComponent } from "./institution_add";
import { InstitutionEditComponent } from "./institution_edit";
import { CreatePaymentComponent } from "./create_payment";
import { EditQuickPayCategoryComponent } from "./edit_quickpay_category";

import { AngularEditorModule } from "@kolkov/angular-editor";
import { BulkMailComponent } from "./bulk_mail";
import { DatePipe } from "@angular/common";
import { AngularMyDatePickerModule } from "angular-mydatepicker";

import { HackathonComponent } from "./hackathon";
import { AlmashineRegistrationListComponent } from "./almashine-registration-list/almashine-registration-list.component";
import { NgxPaginationModule } from "ngx-pagination";
import { PhdListComponent } from "./phd-list/phd-list.component";
import { ConferenceListComponent } from "./conference-list/conference-list.component";
import { ConferenceRegistrationListComponent } from "./conference-registration-list/conference-registration-list.component";
import { ConferencePaymentLogsComponent } from "./conference-payment-logs/conference-payment-logs.component";

import { TmaPaiRegListComponent } from "./tmapai_reg_list/tmapai_reg_list.component";
import { KalaFestComponent } from "./kala_fest/kala_fest.component";
import { ChessEventComponent } from "./chess_event/chess_event.component";
import { IqacMitComponent } from "./iqac-mit/iqac-mit.component";
import { TdwfeComponent } from "./tdwfe/tdwfe.component";
import { ConstructionLawComponent } from "./construction-law/construction-law.component";
import { AdhikshanaRegisterListComponent } from "./adhikshana-register-list/adhikshana-register-list.component";
import { ErayaRegistrationComponent } from "./eraya-registration/eraya-registration.component";
import { MujoRegListComponent } from "./mujo-reg-list/mujo-reg-list.component";
import { TechSolsticeNexusComponent } from "./tech-solstice-nexus/tech-solstice-nexus.component";
import { AgileDevopsReglistComponent } from "./agile-devops-reglist/agile-devops-reglist.component";
import { ConferenceComponent } from "./conference/conference.component";
import { MaheHostelsRegListComponent } from "./mahe-hostels-reg-list/mahe-hostels-reg-list.component";
import { DaycareRegListComponent } from "./daycare-reg-list/daycare-reg-list.component";
import { DecryptionInterceptor } from "./_helpers/decryption.interceptor";
import { DecryptionService } from "./_services/decryption.service";
import { BasketballRegListComponent } from "./basketball-reg-list/basketball-reg-list.component";
import { ReflektaComponent } from "./reflekta/reflekta.component";
import { AleyComponent } from "./aley/aley.component";
import { CinaComponent } from "./cina/cina.component";
import { VehicleRegListComponent } from "./vehicle-reg-list/vehicle-reg-list.component";
import { BazzarMicRegListComponent } from "./bazzar-mic-reg-list/bazzar-mic-reg-list.component";
import { CricketRegListComponent } from "./cricket-reg-list/cricket-reg-list.component";
import { TessellateRegListComponent } from "./tessellate-reg-list/tessellate-reg-list.component";
import { FdpRegListComponent } from "./fdp-reg-list/fdp-reg-list.component";
import { GalaRegListComponent } from "./gala-reg-list/gala-reg-list.component";
import { KennyRegListComponent } from "./kenny-reg-list/kenny-reg-list.component";
import { PopconRegListComponent } from "./popcon-reg-list/popcon-reg-list.component";
import { SustainRegListComponent } from "./sustain-reg-list/sustain-reg-list.component";
@NgModule({
  imports: [
    AngularMyDatePickerModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    routing,
    DataTablesModule,
    LSelect2Module,
    // SelectDropDownModule,
    NgxPrintModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
  ],
  declarations: [
    AppComponent,
    ModalComponent,
    LoginComponent,
    SiteHeaderComponent,
    HomeComponent,
    RegisterListComponent,
    RegisterViewComponent,
    EventViewComponent,
    UploadExcelComponent,
    PaymentListComponent,
    QuickPayPaymentListComponent,
    CategoryListComponent,
    QuickPayCategoryListComponent,
    InstitutionListComponent,
    StudentUserListComponent,
    StaffUserListComponent,
    HackathonComponent,
    PaymentViewComponent,
    QuickPayPaymentViewComponent,
    CategoryViewComponent,

    EditComponent,
    CategoryEditComponent,
    CategoryAddComponent,
    InstitutionAddComponent,
    InstitutionEditComponent,
    CreatePaymentComponent,
    EditQuickPayCategoryComponent,

    BulkMailComponent,
    AdhocdetailsComponent,
    AdhoclistComponent,
    DragDirective,
    AlmashineRegistrationListComponent,
    PhdListComponent,
    ConferenceListComponent,
    ConferenceRegistrationListComponent,
    ConferencePaymentLogsComponent,
    TmaPaiRegListComponent,
    ChessEventComponent,
    KalaFestComponent,
    IqacMitComponent,
    TdwfeComponent,
    ConstructionLawComponent,
    AdhikshanaRegisterListComponent,
    ErayaRegistrationComponent,
    MujoRegListComponent,
    TechSolsticeNexusComponent,
    AgileDevopsReglistComponent,
    ConferenceComponent,
    MaheHostelsRegListComponent,
    DaycareRegListComponent,
    BasketballRegListComponent,
    ReflektaComponent,
    AleyComponent,
    CinaComponent,
    VehicleRegListComponent,
    BazzarMicRegListComponent,
    CricketRegListComponent,
    TessellateRegListComponent,
    FdpRegListComponent,
    GalaRegListComponent,
    KennyRegListComponent,
    PopconRegListComponent,
    SustainRegListComponent,
  ],

  providers: [
    AuthSuperadminGuard,
    AuthVendorGuard,
    AlertService,
    AuthenticationService,
    SettingsService,
    ModalService,
    MarathonService,
    UserService,
    MitService,
    CookieService,
    DatePipe,

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DecryptionInterceptor,
      multi: true,
    },
    DecryptionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
