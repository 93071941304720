import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import {
  AuthenticationService,
  MarathonService,
  ModalService,
  SettingsService,
} from "../_services";
declare var jquery: any;
declare var $: any;
declare var alertify: any;
import Swal from "sweetalert2";

@Component({
  selector: "app-almashine-registration-list",
  templateUrl: "./almashine-registration-list.component.html",
  styleUrls: ["./almashine-registration-list.component.css"],
})
export class AlmashineRegistrationListComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  cur_page = 1;
  from = 0;
  perPage = 10;
  pageSize = 10;
  totalPages = 10;
  totalcount: any;
  search_txt = "";
  student_list = [];
  //   totalcount: any;
  updateForm: FormGroup;
  student: any;
  buttonEnable = true;
  media_domain = `${environment.media_domain}`;
  @ViewChild("downloadZipLink", { static: false })
  private downloadZipLink: ElementRef;
  roleId = localStorage.getItem(`${environment.appName}` + "_role");
  activeclass = "active_student";

  // @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

  pagevalue: any;
  profileimage: any;
  userId: any;
  tab: any = "registration";
  reapplytotalcount: any;
  regtotalcount: any;

  constructor(
    private setting: SettingsService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _marathon: MarathonService,
    private http: HttpClient,
    private router: Router
  ) {}
  public async reportDown(): Promise<void> {
    this.submitted = true;
    // this.loading = true;
    this.loading = false;
  }
  ngOnInit() {
    localStorage.setItem(
      `${environment.appName}` + "activeClick",
      "active_student"
    );
    console.log(this.roleId, "jksdhgfkjahsgh");

    //    this.roleId = '100';
    // this._marathon.getStudentStatusCount().subscribe(data => {
    //     this.student = data.data;
    // })
    this.refresh(true);
    this.updateForm = this.formBuilder.group({
      phone: [""],
    });
    if (this.roleId == "104") {
      this._marathon
        .getalmashineregListSearchreapply(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.reapplytotalcount = data.data.totalDocs;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    } else if (this.roleId == "102") {
      this._marathon
        .getCourseraregListSearch(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.reapplytotalcount = data.data.totalDocs;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    }
  }

  viewphone(e, list) {
    e.stopPropagation();
    this.userId = list._id;
    this.updateForm.patchValue({
      phone: list.phone_no,
    });
    console.log(this.updateForm.value);
    $("#myModal6").modal("show");
  }

  mobilenumber() {
    this.buttonEnable = false;
  }

  downloadExcel() {
    if (this.roleId == "104") {
      this._marathon
        .almashinedownload(this.cur_page, this.search_txt)
        .subscribe((fileData) => {
          const blob: any = new Blob([fileData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          let link = document.createElement("a");

          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "Registration");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
    } else if (this.roleId == "102") {
      this._marathon
        .courseradownload(this.cur_page, this.search_txt)
        .subscribe((fileData) => {
          const blob: any = new Blob([fileData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          let link = document.createElement("a");

          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "Registration");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
    }
  }

  downloadImages() {
    this._marathon
      .almashineImagesdownload(this.cur_page, this.search_txt)
      .subscribe((fileData) => {
        const blob: any = new Blob([fileData], { type: "application/zip" });

        let link = document.createElement("a");

        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "images");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  }

  refresh(reset = false) {
    this.activeclass = "active_student";
    this.loading = true;

    if (this.tab == "registration") {
      if (this.roleId == "104") {
        this._marathon
          .getalmashineregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.regtotalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      } else if (this.roleId == "102") {
        this._marathon
          .getCourseraregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.regtotalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      }
    }

    if (this.tab == "reapply") {
      this._marathon
        .getalmashineregListSearchreapply(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.loading = false;
            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    }
  }

  menu(tab) {
    this.tab = tab;
    if (this.tab == "registration") {
      if (this.roleId == "104") {
        this._marathon
          .getalmashineregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      } else if (this.roleId == "102") {
        this._marathon
          .getCourseraregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      }
    }

    if (this.tab == "reapply") {
      this._marathon
        .getalmashineregListSearchreapply(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.loading = false;
            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    }
  }
  closephone(e) {
    e.stopPropagation();
    $("#myModal6").modal("hide");
    this.updateForm.reset();
    this.refresh();
  }

  get f() {
    return this.updateForm.controls;
  }

  onSubmitMobile() {
    var that = this;

    Swal.fire({
      title: "Are you sure want to update this field?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        that.loading = true;
        if (this.roleId == "104") {
          that._marathon
            .updateAlmashine(
              that.userId,
              that.f.phone.value ? that.f.phone.value : ""
            )
            .subscribe(
              (data) => {
                if (data.status.code == 0) {
                  // that.ngOnInit();
                  that.toastr.success(data.status.message);
                  $("#myModal5").modal("hide");
                  $("#myModal6").modal("hide");
                  that.updateForm.reset();
                  that.submitted = false;
                  that.loading = false;
                  that.refresh();
                } else {
                  that.loading = false;
                  that.toastr.error(data.status.message);
                }
              },
              (error) => {
                that.toastr.error(error);
                that.loading = false;
              }
            );
        } else if (this.roleId == "102") {
          that._marathon
            .updateCoursera(
              that.userId,
              that.f.phone.value ? that.f.phone.value : ""
            )
            .subscribe(
              (data) => {
                if (data.status.code == 0) {
                  // that.ngOnInit();
                  that.toastr.success(data.status.message);
                  $("#myModal5").modal("hide");
                  $("#myModal6").modal("hide");
                  that.updateForm.reset();
                  that.submitted = false;
                  that.loading = false;
                  that.refresh();
                } else {
                  that.loading = false;
                  that.toastr.error(data.status.message);
                }
              },
              (error) => {
                that.toastr.error(error);
                that.loading = false;
              }
            );
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  search(e) {
    e.stopPropagation();
    // this._marathon.getStudentStatusCount().subscribe(data => {
    //     this.student = data.data;
    // })
    this.search_txt = e.target.value;

    this.changePage(1);

    this.loading = true;
    //   this.refresh();

    var that = this;
    if (this.tab == "registration") {
      if (this.roleId == "104") {
        this._marathon
          .getalmashineregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      } else if (this.roleId == "102") {
        this._marathon
          .getCourseraregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      }
    }

    if (this.tab == "reapply") {
      this._marathon
        .getalmashineregListSearchreapply(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.loading = false;
            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    }
  }

  viewImage(e, list) {
    console.log(list, e);
    e.stopPropagation();
    console.log(list.alumni_id_image);
    this.profileimage = list.alumni_id_image;
    // }
    $("#myModal").modal("show");
  }

  changePage(page) {
    this.cur_page = page;
    console.log(this.pagevalue);

    // if (this.pagevalue == 'active_student') {

    // this.refresh();
    var that = this;

    if (this.tab == "registration") {
      if (this.roleId == "104") {
        this._marathon
          .getalmashineregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      } else if (this.roleId == "102") {
        this._marathon
          .getCourseraregListSearch(this.cur_page, this.search_txt)
          .subscribe(
            (data) => {
              this.loading = false;
              this.student_list = data.data.docs;
              this.totalcount = data.data.totalDocs;
              this.from = data.data.pagingCounter;
              this.cur_page = data.data.page;
              this.totalPages = data.data.totalPages;
              // this.pagevalue = 'active_student';
            },
            (error) => {}
          );
      }
    }

    if (this.tab == "reapply") {
      this._marathon
        .getalmashineregListSearchreapply(this.cur_page, this.search_txt)
        .subscribe(
          (data) => {
            this.loading = false;
            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            // this.pagevalue = 'active_student';
          },
          (error) => {}
        );
    }

    // }
  }

  closeImage(e) {
    e.stopPropagation();
    $("#myModal").modal("hide");
    this.refresh();
  }

  resetFilter(e) {
    this.search_txt = "";
    $("#search1").val("");
    this.refresh(true);
  }
}
