import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'edit_quickpay_category.component.html',
    styleUrls: ['edit_quickpay_category.component.css']
})
export class EditQuickPayCategoryComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    categoryAddForm: FormGroup;
    types: any
    student = false;
    faculty = false;
    research = false;
    res_data = false;
    todayDate = new Date();
    endDate1= new Date();
    showDateField = false;
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,

        private router: Router) { }

    edit = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    startDate: any;
    endDate: any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');


    ngOnInit() {

        console.log(this.id);

        if (!(this.roleId == '103')) {
            this.router.navigate(['/'])
        }

        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

        this.categoryAddForm = this.formBuilder.group({
            index: [{ value: null, disabled: true }],
            _id: [{ value: null, disabled: true }],
            name: ['', [Validators.required]],
            description: [''],
            collection_fees: ['0'],
            start_date: [''],
            end_date: [''],
            gst: ['0'],
        })

        this._marathon.getSingleQuickCategoryDeatil(this.id).subscribe((data) => {
            this.categoryAddForm.patchValue({
                _id:data.data._id,
                name: data.data.purpose,
                description: data.data.description,
                collection_fees: data.data.amount,
                gst: data.data.gst,
            })

            if (data.data.start_date && data.data.end_date) {
                this.showDateField = true;
                this.onCheckboxChange(true)
                this.categoryAddForm.patchValue({
                    start_date: this.datePipe.transform(data.data.start_date, 'yyyy-MM-dd'),
                    end_date: this.datePipe.transform(data.data.end_date, 'yyyy-MM-dd'),
                })
            }
        })

    }

    goBack() {
        this.location.back();
    }

    get f() { return this.categoryAddForm.controls; }


    onSubmit(form, formWrap) {

        this.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;

        if (this.categoryAddForm.invalid) {
            var that = this;
            that.toastr.error('Please enter all fields');
            return;
        }

        var that = this;

        // name description collection_fees start_date end_date
        alertify.confirm('Edit', 'Are you sure want to edit this record?', function () {

            if((!that.f.start_date.value) || (!that.f.end_date.value))
            {
                that.categoryAddForm.patchValue({
                    start_date:'',
                    end_date: '',
                })
            }

            // if (that.roleId == '99' || that.roleId == '100') {
            that._marathon.quickpayCategoryEdit(
                that.f._id.value,
                that.f.name.value,
                that.f.description.value,
                that.f.start_date.value,
                that.f.end_date.value,
                that.f.collection_fees.value,
                that.f.gst.value,
            ).subscribe(
                data => {
                    if (data.status.code == 0) {
                        that.loading = true;
                        that.toastr.success(data.status.message)
                        that.router.navigate(['/quickpay-category-list']);
                    } else {
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        that.toastr.error(data.status.message)
                    }
                },
                error => {
                    that.toastr.error(error);
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                });
            // } 


        }, function () {
            that.toastr.error('Edit Action Cancelled')
        });


    }

    submit() { }


    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.edit.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }

    cancel(e) {
        e.stopPropagation();
        this.router.navigate(['/quickpay-category-list']);
    }

    onCheckboxChange(e) {
        console.log(e);

        if (e) {

            this.showDateField = true;

            const start_date = this.categoryAddForm.controls['start_date'];
            start_date.setValidators([Validators.required]);
            start_date.updateValueAndValidity();

            const end_date = this.categoryAddForm.controls['end_date'];
            end_date.setValidators([Validators.required]);
            end_date.updateValueAndValidity();
        } else {

            this.showDateField = false;
            const start_date = this.categoryAddForm.controls['start_date'];
            start_date.clearValidators();
            start_date.updateValueAndValidity();
            start_date.reset();

            const end_date = this.categoryAddForm.controls['end_date'];
            end_date.clearValidators();
            end_date.updateValueAndValidity();
            end_date.reset();

        }
    }


    getStartDate(e) {

        const end_date = this.categoryAddForm.controls['end_date'];
        end_date.reset();

        console.log(e);
        this.endDate1 = e;
    }

}
