import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
// import { DatepickerModule } from 'ng2-datepicker';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { element } from 'protractor';

declare var jquery: any;
declare var $: any;
@Component({
    templateUrl: 'payment_list.component.html',
    styleUrls: ['payment_list.component.css']
})
export class PaymentListComponent implements OnInit {

    // @ViewChild('myDpOptions', { static: false }) fromDate: any;
    myDpOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
        // other options are here...
      };

      
    
      myDateInit: boolean = true;
      model: IMyDateModel = null;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cancel_data: any;
    cur_page = 1;
    // totalPages = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    filter_date= null;
    filter_status= null;
    register_list = [];
    cat_array = [];
    category = [];
    cat_data: any;
    t_shirt = [];
    selected_category = null;
    selected_tshirt = null;
    media_domain = environment.media_domain;
    view_image = null;
    show_excel_upload = false;
    dashboad: any;
    type: any;
    pendingstate = false;
 new_page:any;
 createdDate: any;
//  totalcount:any;
    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
    pagevalue:any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    newcreatedDate: any=[];
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        this.loading = true;
        const product = [];
       // const blob = await this._marathon.getDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt);
        let binaryData = [];
     //   binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        // const url = window.URL.createObjectURL(blob);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'registration.xlsx';
        link.click();
        // window.URL.revokeObjectURL(url);
        this.loading = false;
        document.body.classList.remove('jw-modal-open');
        // link.stop();
    }

    ngOnInit() {
        // this._marathon.getDashboard().subscribe(data => {
        //     this.dashboad = data.data;
        // })
        this.refresh(true);

        var that = this;
    }
  
    edit(e, user) {
        location.href = "edit/" + user._id;
        // this.router.navigate(['/edit/' + user._id]);
    }

    

    acceptStatus(e, user) {
        e.stopPropagation();
        console.log(user)
        this._marathon.acceptUpdate(user._id, 1).subscribe(data => {
            console.log(data)
            // this._marathon.getDashboard().subscribe(data => {
            //     this.dashboad = data.data;
            // })
        })
    }
 

    refresh(reset = false) {
        console.log("test",this.roleId)
     
       
        // this._marathon.getDashboard().subscribe(data => {
        //     this.dashboad = data.data;
        // })

        if(this.roleId == '200'){
            this._marathon.getbanglrPayment(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
                this.register_list = data.data.docs;
                this.newcreatedDate=[]
                this.register_list.forEach(element=>{
                    var newdate = element.updatedAt.split('T');
                    this.createdDate = newdate[0];
                    this.newcreatedDate.push(this.createdDate)
                })
                console.log(this.newcreatedDate)
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
            }, error => {
    
            });
        }

        else{


        this._marathon.getPayment(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
            this.register_list = data.data.docs;
            this.newcreatedDate=[]
            this.register_list.forEach(element=>{
                var newdate = element.updatedAt.split('T');
                this.createdDate = newdate[0];
                this.newcreatedDate.push(this.createdDate)
            })
            console.log(this.newcreatedDate)
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
        }, error => {

        });
    }
    }

    uploadExcelToggle(e) {
        this.show_excel_upload = true;
        this.resetFilter(null);
    }

    changeType(e) {

    }

    chooseFile(e) {

    }

    viewImage(e, obj) {
        e.stopPropagation();
        this.view_image = null;
        if (!obj.emp_id_image) {
            return false;
        }
        this.view_image = this.media_domain + obj.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }

    search(e) {

        e.stopPropagation();
        
        this.search_txt = e.target.value;
        if(this.roleId == "200"){
            this._marathon.getbanglrPayment(1,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
                this.register_list = data.data.docs;
                this.newcreatedDate=[]
                this.register_list.forEach(element=>{
                    var newdate = element.updatedAt.split('T');
                    this.createdDate = newdate[0];
                    this.newcreatedDate.push(this.createdDate)
                })
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
              
            }, error => {
    
            });
        }
        // this.refresh();
        else{
        this._marathon.getPayment(1,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
            this.register_list = data.data.docs;
            this.newcreatedDate=[]
            this.register_list.forEach(element=>{
                var newdate = element.updatedAt.split('T');
                this.createdDate = newdate[0];
                this.newcreatedDate.push(this.createdDate)
            })
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
          
        }, error => {

        });
    }
    }

    filterDate(e){
        console.log(e);
        // console.log(e.target.value);
        console.log(e.singleDate.formatted);
        this.filter_date=e.singleDate.formatted
        // this.filter_date=e.target.value;
        this.refresh();
    }

    filterStatus(e){
        this.filter_status=e.target.value;
        if(e.target.value == '')
        {
            this.filter_status=null;
        }
        this.cur_page = 1;
        if(this.roleId == '200'){
            this._marathon.getbanglrPayment(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
                this.register_list = data.data.docs;
                this.newcreatedDate=[]
                this.register_list.forEach(element=>{
                    var newdate = element.updatedAt.split('T');
                    this.createdDate = newdate[0];
                    this.newcreatedDate.push(this.createdDate)
                })
                console.log(this.newcreatedDate)
                this.cur_page = data.data.page;
                this.totalPages = data.data.totalPages;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
            }, error => {
    
            });
        }

        else{


        this._marathon.getPayment(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status).subscribe(data => {
            this.register_list = data.data.docs;
            this.newcreatedDate=[]
            this.register_list.forEach(element=>{
                var newdate = element.updatedAt.split('T');
                this.createdDate = newdate[0];
                this.newcreatedDate.push(this.createdDate)
            })
            console.log(this.newcreatedDate)
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
        }, error => {

        });
    }
    }

    changePage(page) {
        this.cur_page = page;
      
    
            this.refresh();
      
        
    }



    changeFilter(e, type) {
        e.stopPropagation();
        if (type === 'category') {
            if (e.target.value === '-1') {
                this.selected_category = null;
                this.refresh();
            } else {
                this.selected_category = e.target.value;
            }
        } else if (type === 'tshirt') {
            if (e.target.value === '-1') {
                this.selected_tshirt = null;
                this.refresh();
            } else {
                this.selected_tshirt = e.target.value;
            }
        }
        this.refresh();
    }

    resetFilter(e) {
        this.model = null;
        // this.fromDate.value = undefined;
        // this.myDpOptions.clearDate()
        // this.model = {isRange: false, singleDate: {date: { 
        //     year: 2019, 
        //     month: 5, 
        //     day: 14 
        //   }}};
        this.selected_category = null;
        this.selected_tshirt = null;
        this.search_txt = '';
        this.filter_date= null;
        this.filter_status= null;
        $('#search1').val('');
        $('#status').val('');
        $("#search1").val('')
        $("input[type=date]").val("")
        this.refresh(true);
     
    }

    public async downloadExcel() {

      
            // if(this.roleId == '100'){
                // const blob = await this._marathon.getPaymentListDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt,"fhg");
             if(this.roleId == '200'){
                // getbanglrPaymentListDownload
                const blob = await this._marathon.getbanglrPaymentListDownload(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'Payment_logs.xlsx';
                link.click();
             }
             else{
                const blob = await this._marathon.getPaymentListDownload(this.cur_page,this.search_txt,this.roleId,this.filter_date,this.filter_status);
                let binaryData = [];
                binaryData.push(blob);
                const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
                const link = this.downloadZipLink.nativeElement;
                link.href = url;
                link.download = 'Payment_logs.xlsx';
                link.click();
             }
            // }
        
    }

    ngAfterViewInit() {
      
    }


}
