<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/registration-list">Registration List</a></li>
                        <li class="breadcrumb-item active">Attendee Details</li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <section class="content">
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head">Attendee Details</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="attendee_detail_edit">
                        <form class="attendee_det_form">
                            <div class="form_left">

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Email </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.email}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Gender </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.gender}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Date of Birth </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.dob | date : 'dd-MM-yyyy'}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Phone Number </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.phone_no}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Employee Code </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.enrollment_Number ? register.enrollment_Number : '-'}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Institution Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.institution ? register.institution : '-'}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Login Type </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.loginType == 1 ? 'Student' : register &&
                                        register.loginType == 2 ? 'Staff' : register && register.loginType == 3 ?
                                        'Others' : 'Others'}}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>

        <div class="container-fluid page_section" *ngIf="category && category.beneficiary && (category.beneficiary).length > 0">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head">Beneficiary Details</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6" *ngFor="let benf_data of (category.beneficiary)">
                    <div class="attendee_detail_edit">
                        <form class="attendee_det_form">
                            <div class="form_left">

                                <!-- <div class="row">
                                    <div class="col-sm-7">
                                        <label>Id </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data._id}}
                                    </div>
                                </div> -->

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Beneficiary Type</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.benefType}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Gender</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.gender}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>DOB</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.dob}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Under Age</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.under_age}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Address</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.address}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.email}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Phone Number</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{benf_data && benf_data.phone_no}}
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-sm-7">
                                        <label>Email </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.email}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Gender </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.gender}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Date of Birth </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.dob}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Phone Number </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.phone_no}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Login Type </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.loginType == 1 ? 'Student' : register &&
                                        register.loginType == 2 ? 'Staff' : register && register.loginType == 3 ?
                                        'Others' : 'Others'}}
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>

        <div class="container-fluid page_section" *ngIf="activeClick == 'reg' || activeClick == 'outdate'">
            <div class="row" *ngIf="category && category.categoryList">
                
                <div class="col-sm-12"  *ngIf="category.beneficiary && (category.beneficiary).length == 0">
                    <div class="attendee_det_head">Category Details</div>
                </div>

                <div class="col-sm-12"  *ngIf="category.beneficiary && (category.beneficiary).length > 0">
                    <div class="attendee_det_head">Beneficiary Category Details</div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6" *ngFor="let data of (category.categoryList)">
                    <div class="attendee_detail_edit att_detail">
                        <form class="attendee_det_form">
                            <div class="form_left">

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Category Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{data && data.name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Membership Period </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.membershipPeriod}} Months
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7">
                                        <label>Health Problem</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.healthProblem}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101' && category.healthProblem == 'Yes'">
                                    <div class="col-sm-7">
                                        <label>Health Problem Description</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.healthProblem_dsc}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7">
                                        <label>Knows ?</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.known_status}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7">
                                        <label>Level</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.level}}
                                    </div>
                                </div>

                                <!-- <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7" >
                                        <label>Start Date </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.date | date:"dd-MM-yyyy"}}
                                    </div>
                                </div> -->

                                <div class="row" *ngIf="roleId == '100'||roleId == '200' ">
                                    <div class="col-sm-7" >
                                        <label>Start Date </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.createdAt | date:"dd-MM-yyyy"}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7" >
                                        <label>Start Date </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.date | date:"dd-MM-yyyy"}}
                                    </div>
                                </div>

                                <!-- <div class="row" *ngIf="(category.beneficiary).length > 0">
                                    <div class="col-sm-7" >
                                        <label>Start Date </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.date | date:"dd-MM-yyyy"}}
                                    </div>
                                </div> -->

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Valid Till</label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.validTill | date:"dd-MM-yyyy"}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '99' || roleId == '100' || roleId == '200'">
                                    <div class="col-sm-7">
                                        <label>Amount </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        Rs {{(data && data.paid_amount)|number}}
                                    </div>
                                </div>

                                <div class="row" *ngIf="roleId == '101' && register.loginType != 1">
                                    <div class="col-sm-7">
                                        <label>Coaching Fee </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        Rs {{data && data.coaching_fee}}
                                    </div>
                                </div>

                                <!-- <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7">
                                        <label>Fee </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        Rs {{data && data.paid_amount}}
                                    </div>
                                </div> -->

                                <div class="row" *ngIf="roleId == '101'">
                                    <div class="col-sm-7">
                                        <label>Total Amount </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        Rs {{category.amount|number}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Paid Date </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{category.createdAt | date:"dd-MM-yyyy"}}
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </section>

  

    <!-- <section class="content" *ngIf="beneficiary">
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head" *ngIf="beneficiary.length !=0 ">Category Details</div>
                </div>
            </div>
            <div *ngFor="let register of beneficiary; let x=index">
                <div class="row" *ngIf="register.beneficiary == null">
                    <div class="col-sm-6 beneficiary_cls" *ngFor="let cat of register.categoryList">
                        <div class="attendee_detail_edit attendee_detail">
                            <form class="attendee_det_form">
                                <div class="form_left">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Category Name </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{cat.name | titlecase}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Membership Type </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.membershipType == 'PD' ? 'Primary with Dependant' :
                                            register.membershipType == 'D' ? 'Only Dependant': 'Primary'}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Membership Period </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.membershipPeriod}} Months
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="roleId == '101'">
                                        <div class="col-sm-7">
                                            <label>Health Problem</label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.healthProblem}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Amount </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.amount}}
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head" *ngIf="beneficiary.length !=0 ">Beneficiary Details</div>
                </div>
            </div>

            <div class="row" *ngFor="let register of beneficiary; let x=index">
                <div class="col-sm-6 beneficiary_cls" *ngIf="register.beneficiary">
                    <div class="attendee_detail_edit">
                        <form class="attendee_det_form">
                            <div class="form_left">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Beneficiary Type </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.benefType |
                                        titlecase}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Name </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.name}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Email </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.email}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Gender </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.gender}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Date of Birth </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.dob}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Phone Number </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.phone_no}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-7">
                                        <label>Login Type </label>
                                    </div>
                                    <div class="col-sm-1"><span class="input_colon">:</span></div>
                                    <div class="col-sm-4">
                                        {{register && register.beneficiary && register.beneficiary.loginType == 1 ?
                                        'Student' : register && register.beneficiary && register.beneficiary.loginType
                                        == 2 ? 'Staff' : register && register.beneficiary &&
                                        register.beneficiary.loginType == 3 ? 'Others' : 'Others'}}
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>

            </div>

        </div>
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-sm-12">
                    <div class="attendee_det_head" *ngIf="beneficiary.length !=0 ">Beneficiary Category Details</div>
                </div>
            </div>

            <div *ngFor="let register of beneficiary; let x=index">
                <div class="row" *ngIf="register.beneficiary != null">
                    <div class="col-sm-6 beneficiary_cls" *ngFor="let cat of register.category">
                        <div class="attendee_detail_edit">
                            <form class="attendee_det_form">
                                <div class="form_left">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Category Name </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{cat.name | titlecase}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Membership Type </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.membershipType == 'PD' ? 'Primary with Dependant' :
                                            register.membershipType == 'D' ? 'Only Dependant': 'Primary'}}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Membership Period </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.membershipPeriod}} Months
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label>Amount </label>
                                        </div>
                                        <div class="col-sm-1"><span class="input_colon">:</span></div>
                                        <div class="col-sm-4">
                                            {{register.amount}}
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section> -->
</div>