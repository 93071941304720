<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">

    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/quickpay-category-list">Category List</a></li>
                        <li class="breadcrumb-item active">Add Category Details</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>


    <section class="content">
        <div class="container-fluid page_section">
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content reg_view_form">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <div class="register_view_top">
                                            <div class="registartion_vleft">

                                            </div>

                                            <div class="filler"></div>

                                        </div>
                                        <form class="registration_view_form" [formGroup]="categoryAddForm"
                                            #myForm="ngForm" (ngSubmit)="onSubmit(categoryAddForm, myForm)">
                                            <!-- mareena start-->
                                            <div class="col-sm-12" *ngIf="roleId == '103'">
                                                <div class="row">

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee name">Purpose <span class="mandatory_cls"> *</span></label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="name"
                                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">

                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.required">Purpose is required
                                                                </div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.pattern">Empty space is not
                                                                    allowed
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-8">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="description"
                                                                [ngClass]="{ 'is-invalid': (f.description.touched || submitted) && f.description.errors }">

                                                            <div *ngIf="(f.description.touched || submitted) && f.description.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.description.errors?.required">Description
                                                                    is required
                                                                </div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.description.touched || submitted) && f.description.errors?.pattern"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.description.errors?.pattern">Empty space is not
                                                                    allowed
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row checkbox_cls">
                                                    <div class="col-sm-4 ">
                                                        <input type="checkbox" class="checkbox_box" (change)="onCheckboxChange($event)" />
                                                        Active Start & End Date
                                                    </div>
                                                </div>

                                                <div class="row" *ngIf="showDateField">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="start_date">Start Date</label>
                                                            <input autocomplete="off" type="date" class="form-control" min="{{ todayDate | date:'yyyy-MM-dd'}}"
                                                                formControlName="start_date" (change)="getStartDate($event.target.value)"
                                                                [ngClass]="{ 'is-invalid': (f.start_date.touched || submitted) && f.start_date.errors }">
                                                            <div *ngIf="(f.start_date.touched || submitted) && f.start_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.start_date.errors?.required">
                                                                    Start Date is required</div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.phone_no.touched || submitted) && f.phone_no.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.phone_no.errors?.pattern">
                                                               Enter only digits</div>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="current_status">End Date</label>


                                                            <input autocomplete="off" type="date" class="form-control" min="{{ endDate1 | date:'yyyy-MM-dd'}}"
                                                                formControlName="end_date" 
                                                                [ngClass]="{ 'is-invalid': (f.end_date.touched || submitted) && f.end_date.errors }">

                                                            <div *ngIf="(f.end_date.touched || submitted) && f.end_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.end_date.errors?.required">End Date is
                                                                    required</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="one_month_amount">Amount</label>
                                                            <input autocomplete="off" type="number" class="form-control"
                                                                formControlName="collection_fees">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="one_month_amount">GST</label>
                                                            <select id="status" class="form-control" formControlName="gst">
                                                                <option selected [value]="0">No GST</option>
                                                                <option [value]="3" >GST 3%</option>
                                                                <option [value]="18" >GST 18%</option>
                                                                <option [value]="24" >GST 24%</option>
                                                            </select>
                                                           
                                                            <!-- <div *ngIf="(f.gst.touched || submitted) && f.gst.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.gst.errors?.required">
                                                                    Collection Fees is
                                                                    required</div>
                                                            </div> -->

                                                        </div>
                                                    </div>

                                                </div>






                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>