import { Component, OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import {
  AuthenticationService,
  ModalService,
  SettingsService,
} from "../_services";
import { environment } from "../../environments/environment";

declare var jquery: any;
declare var $: any;
@Component({ templateUrl: "login.component.html" })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hide = 1;
  mobile_no = "";
  create_orders: any;
  invoice_repor: any;
  manage_access: any;
  manage_client: any;
  manage_orders: any;
  pro_summary: any;
  manage_products: any;
  manage_users: any;
  abstract_report: any;
  tat_report: any;
  production_orders: any;
  mainAdminId: any;
  is_auth = false;

  home_page: any;
  task_manager: any;
  constructor(
    private setting: SettingsService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  static areEqual(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value);
    for (const i in keys) {
      if (
        i !== "0" &&
        c.value["new_password"] !== c.value["confirm_password"]
      ) {
        return { areEqual: true };
      }
    }
  }
  ngOnInit() {
    this.mainAdminId = localStorage.getItem(
      `${environment.appName}` + `_MainAdminID`
    );

    if (localStorage.getItem(`${environment.appName}` + "_user")) {
      this.is_auth = true;
      this.router.navigate(["/"]);
    } else {
      this.is_auth = false;
    }

    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.maxLength(50), Validators.email],
      ],
      password: ["", Validators.required],
      passwords: new FormGroup(
        {
          new_password: new FormControl(),
          confirm_password: new FormControl(),
        },
        LoginComponent.areEqual
      ),
    });

    // reset login status
    //   this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    //    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    document.body.classList.add("jw-modal-open");
    this.loading = true;
    if (!this.hide) {
      this.setting
        .save(
          this.f.email.value,
          "1",
          this.f.password.value,
          this.f.passwords["controls"].new_password.value
        )
        .subscribe(
          (data) => {
            if (data.status.code == 0) {
              this.submitted = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.success(data.status.message);
              location.href = this.returnUrl;
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    } else {
      this.authenticationService
        .login(this.f.email.value, this.f.password.value)
        .subscribe(
          (data) => {
            // console.log(data, "DDDDaaa");
            this.loading = false;
            this.submitted = false;
            document.body.classList.remove("jw-modal-open");
            if (data.status.code == 0) {
              this.toastr.success(data.status.message);

              if (data.data && data.data.user.roleId == "107") {
                this.router.navigate(["/kala_fest"]);
              } else if (data.data && data.data.user.roleId == "108") {
                this.router.navigate(["/iqac-mit"]);
              } else if (data.data && data.data.user.roleId == "109") {
                this.router.navigate(["/TDWFE"]);
              } else if (data.data && data.data.user.roleId == "110") {
                this.router.navigate(["/Construction-Law-blr"]);
              } else if (data.data && data.data.user.roleId == "111") {
                this.router.navigate(["/Adhikshana"]);
              } else if (data.data && data.data.user.roleId == "119") {
                this.router.navigate(["/Falak"]);
              } else if (data.data && data.data.user.roleId == "113") {
                this.router.navigate(["/Mujo"]);
              } else if (data.data && data.data.user.roleId == "114") {
                this.router.navigate(["/Tech-Solstice-Nexus"]);
              } else if (data.data && data.data.user.roleId == "115") {
                this.router.navigate(["/agile-devops-list"]);
              } else if (data.data && data.data.user.roleId == "116") {
                this.router.navigate(["/conference-reg-list"]);
              } else if (data.data && data.data.user.roleId == "117") {
                this.router.navigate(["/mahe-hostel-reg-list"]);
              } else if (data.data && data.data.user.roleId == "118") {
                this.router.navigate(["/daycare-reg-list"]);
              } else if (data.data && data.data.user.roleId == "121") {
                this.router.navigate(["/basketbal-reg-list"]);
              } else if (data.data && data.data.user.roleId == "123") {
                this.router.navigate(["/faculty-reg-list"]);
              } else if (data.data && data.data.user.roleId == "124") {
                this.router.navigate(["/reflekta-reg-list"]);
              } else if (data.data && data.data.user.roleId == "125") {
                this.router.navigate(["/aley-reg-list"]);
              } else if (data.data && data.data.user.roleId == "126") {
                this.router.navigate(["/cina-reg-list"]);
              } else if (data.data && data.data.user.roleId == "127") {
                this.router.navigate(["/vehicle-reg-list"]);
              } else if (data.data && data.data.user.roleId == "128") {
                this.router.navigate(["/bazaar-mic-reg-list"]);
              } else if (data.data && data.data.user.roleId == "129") {
                this.router.navigate(["/cricket-reg-list"]);
              } else if (data.data && data.data.user.roleId == "130") {
                this.router.navigate(["/tessellate-reg-list"]);
              } else if (data.data && data.data.user.roleId == "131") {
                this.router.navigate(["/fdp-reg-list"]);
              } else if (data.data && data.data.user.roleId == "132") {
                this.router.navigate(["/gala-reg-list"]);
              } else if (data.data && data.data.user.roleId == "133") {
                this.router.navigate(["/kenny-reg-list"]);
              } else if (data.data && data.data.user.roleId == "134") {
                this.router.navigate(["/popcon-reg-list"]);
              } else if (data.data && data.data.user.roleId == "135") {
                this.router.navigate(["/sustain-reg-list"]);
              } else if (data.data && data.data.user.roleId == "136") {
                this.router.navigate(["/hacknite-reg-list"]);
              } else if (data.data && data.data.user.roleId == "137") {
                this.router.navigate(["/arbitration-workshop-reg-list"]);
              }
               else if (data.data && data.data.user.roleId == "200") {
                this.router.navigate(["/"]);
              } else {
                this.router.navigate(["/"]);
              }
            } else {
              this.toastr.error(data.status.message);
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
            }
          },
          (error) => {
            this.toastr.error(error);
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    }
  }
}
