<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading
  [show]="loading"
  class="loader"
  [template]="customLoadingTemplate"
></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb cron_main">
            <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
            <li class="breadcrumb-item active">User List</li>
            <li class="filler"></li>
            <!-- <li class="cron_details">Cron start: <button class="sync_cls" (click)="sync()">Sync</button></li>
                        <li class="cron_details">Cron started: {{studentcron && studentcron._id}}</li>
                        <li class="cron_details">Cron type: Student</li>
                        <li class="cron_details">Records retrived: {{studentcron && studentcron.records_retrived}}</li> -->
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                (keyup.enter)="search($event)"
                placeholder="Search Name, Email, Phone No."
                autocomplete="off"
              />
            </div>
            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="order_tabs">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'active_student' }"
                data-toggle="pill"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Active</span>

                    <span class="info-box-number">
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}
                    </span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-user-check"></i>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'active_student' }"
                data-toggle="pill"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Total Payment</span>

                    <span class="info-box-number">
                      {{
                        totalapayment
                          ? (totalapayment | number : "1.0" : "en-US")
                          : 0
                      }}
                    </span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fas fa-wallet"></i>
                  </div>
                </div>
              </a>
            </li>
            <!-- <li class="nav-item"><a class="nav-link" [ngClass]="{ 'active':activeclass=='inactive_student'}"
                                data-toggle="pill" (click)="inactiveStudent($event)">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content"><span class="info-box-text">Inactive</span>
                                        <span class="info-box-number">{{student &&
                                            student.inactive ? (student &&
                                            student.inactive | number:'1.0':'en-US') : 0}}</span>
                                        <span class="info-box-number" *ngIf="!roleId">0
                                        </span>
                                    </div>
                                    <div _class="info-box-content"><i class="fa fa-stop-circle"></i></div>
                                </div>
                            </a>
                        </li> -->
          </ul>
        </div>
        <div class="row">
          <!-- <div class="col-sm-12">
            <button (click)="downloadExcel('MAHE')" class="btn btn-primary btn-sm mb-2" style="margin-right: 10px">
              Download Excel 
            </button>
           
          </div> -->

          <a
            style="display: none"
            #downloadZipLink
            class="btn btn-primary btn-sm"
          ></a>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <div class="table-responsive">
                      <table class="table table-hover dataTable admin_table">
                        <thead>
                          <tr>
                            <!-- <th width="10%">Reg. ID</th> -->
                            <!-- <th width="150px">Reg Id.</th> -->
                            <th><div style="width: 150px">Name</div></th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>
                              <div style="width: 120px">Reg No./Emp.Id</div>
                            </th>
                            <th>Institution</th>
                            <th>Department</th>
                            <th>USer Type</th>
                            <th>
                              <div style="width: 120px">Vehicle Reg No.</div>
                            </th>
                            <th>
                              <div style="width: 120px">Vehicle Colour</div>
                            </th>
                            <th>
                              <div style="width: 120px">Vehicle Model</div>
                            </th>
                            <th>Approval Email</th>
                            <th>Status</th>
                            <th>Download</th>
                            <!-- <th width="400px">Address</th> -->
                            <!-- <th width="150px">Dated On</th>
                            <th width="150px">MAHE ID/ Roll No.</th>
                            <th width="120px">Category</th>
                            <th>Mahe Staff</th> -->
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container
                            *ngFor="
                              let list of student_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <tr
                              [ngClass]="
                                list.cancel_status == 1 &&
                                list.status == 1 &&
                                list.refund_status == 0 &&
                                list.total_amount > 0
                                  ? 'clr_cls'
                                  : ' '
                              "
                            >
                              <!-- <td>
                                {{ list && list.regId ? list.regId : "-" }}
                              </td> -->

                              <td class="name_cls">
                                {{ list && list.name ? list.name : "-" }}
                              </td>

                              <td>
                                {{ list && list.email ? list.email : "-" }}
                              </td>
                              <td>
                                {{ list && list.mobile ? list.mobile : "-" }}
                              </td>

                              <td>
                                {{
                                  list && list.registrationNo
                                    ? list.registrationNo
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  list && list.institution
                                    ? list.institution
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  list && list.department
                                    ? list.department
                                    : "-"
                                }}
                              </td>
                              <td *ngIf="list.user_type == 'MAHE'">
                                {{ list && list.user_type }}
                              </td>
                              <td *ngIf="list.user_type == 'NON_MAHE'">
                                {{ list && list.user_type }}
                              </td>
                              <td>
                                {{
                                  list && list.vehicle_reg_No
                                    ? list.vehicle_reg_No
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  list && list.vehicle_colour
                                    ? list.vehicle_colour
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  list && list.vehicle_model
                                    ? list.vehicle_model
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{
                                  list.email_for_verification
                                    ? list.email_for_verification
                                    : "-"
                                }}
                              </td>
                              <td *ngIf="list.status == 0">Pending</td>
                              <td *ngIf="list.status == 1">Approved</td>
                              <td *ngIf="list.status == 2">Rejected</td>
                              <td>
                                <i
                                  class="fa fa-download"
                                  (click)="downloadpdf(list)"
                                ></i>
                              </td>
                              <!-- <td>
                                {{ list && list.address ? list.address : "-" }}
                              </td> -->
                              <!-- <td>
                                {{
                                  list && list.dated_on ? list.dated_on : "-"
                                }}
                              </td> -->
                              <!-- <td>
                                {{
                                  list && list.maheId_rollNo
                                    ? list.maheId_rollNo
                                    : "-"
                                }}
                              </td> -->
                              <!-- <td>
                                {{
                                  list && list.category ? list.category : "-"
                                }}
                              </td> -->
                              <!-- <td>
                                {{
                                  list && list.mahe_staff
                                    ? list.mahe_staff
                                    : "-"
                                }}
                              </td> -->
                            </tr>
                          </ng-container>
                          <tr *ngIf="!student_list.length">
                            <td colspan="9" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>

<div
  class="modal fade status_modal"
  id="myModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" (click)="closeImage($event)">×</span>
        </button>
        <div class="status_box flt">
          <!-- <form [formGroup]="updateForm" #myForm="ngForm" (ngSubmit)="onSubmit()"> -->
          <label>Event</label>
          <!-- <div>{{media_domain}}{{profileimage}}</div> -->
          <div class="address_main">
            <ul>
              <li *ngFor="let event of eventlist">{{ event }}</li>
            </ul>
          </div>
          <!-- <div *ngIf="!profileimage" class="profile_image"><img  src="assets/images/defaultp.png" alt="image"></div> -->
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</div>
