import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'edit.component.html',
    styleUrls: ['edit.component.css']
})
export class EditComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    adminForm: FormGroup;
    types = false;
    student = false;
    faculty = false;
    research = false;
    res_data = false;
    //table = false;
    //cancelButton = false;
    // onSubmit=false;
newroleid:any;
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,

        private router: Router) { }

    edit = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    startDate:any;
    endDate:any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
cattype:any;
typecheck:any;
    ngOnInit() {
        console.log("meee",this.roleId)
        if(this.roleId == '99')
        {
            this.roleId = '100';
        }
    //     if(this.roleId == '101'){
    //         this.newroleid =  this.roleId;
    //     }
       if(this.roleId == '200'){
        this._marathon.getbanglrmarenaEdit(this.id,this.roleId).subscribe(data => {
       
            //     this.cattype = data.data && data.data.categories[0] && data.data.categories[0].type;
                 this.cattype = data.data && data.data.categories[0] && data.data.categories[0].type;
                 if(this.cattype == 1){
                     this.typecheck = 1;
                 }else{
                    this.typecheck = 0; 
                 }
                 this.edit = data.data.user;
                 this.types = this.edit.type;
                 if (this.edit.type == "STUDENT") {
                     this.student = true;
                     this.faculty = false;
                 } else if (this.edit.type == "FACULTY") {
                     this.faculty = true;
                     this.student = false;
                 } else {
                     this.student = false;
                     this.faculty = false;
                 }
                 if (this.edit.reasearch_associate == 'Yes') {
                     this.research = true;
                 } else if (this.edit.reasearch_associate == 'No') {
                     this.res_data = true;
                 } else {
                     this.res_data = false;
                     this.research = false;
                 }
     
                 // this.startDate=DatePipe
     
                 this.startDate= this.datePipe.transform(data.data.createdAt, 'yyyy-MM-dd')
                 this.endDate= this.datePipe.transform(data.data.validTill, 'yyyy-MM-dd')
                 
     // this.cattype = 
     
                 this.adminForm.patchValue({
                     index: 1,
                     _id: this.edit._id ? this.edit._id : '-',
                     name: this.edit.name ? this.edit.name : '-',
                     //    reg_id: this.edit.reg_ID ? this.edit.reg_ID : '-',
                     email: this.edit.email ? this.edit.email : '-',
                     address: this.edit.address ? this.edit.address : '-',
                     gender: this.edit.gender ? this.edit.gender : '-',
                     //  t_shirt_size: this.edit.t_shirt_size.name,
                     dob: this.edit.dob ? this.edit.dob : '-',
                     phone_no: this.edit.phone_no ? this.edit.phone_no : '-',
                     start_date: this.startDate ? this.startDate : '-',
                     end_date: this.endDate ? this.endDate : '-',
                     //   em_contact_name: this.edit.em_contact_name ? this.edit.em_contact_name : '-',
                     //   em_contact_num: this.edit.em_contact_num ? this.edit.em_contact_num : '-',
                     //   blood_group: this.edit.blood_group ? this.edit.blood_group : '-',
                     //  type: this.edit.type ? this.edit.type : '-',
                     //  name_of_institute: this.edit.name_of_institute && this.edit.name_of_institute.name ? this.edit.name_of_institute &&  this.edit.name_of_institute.name : '-',
                     //   register_no: this.edit.register_no ? this.edit.register_no : '-',
                     //  course: this.edit.course ? this.edit.course : '-',
                     //  year: this.edit.year ? this.edit.year : '-',
                     ///   reasearch_associate: this.edit.reasearch_associate ? this.edit.reasearch_associate : '-',
                     //   employee_id: this.edit.employee_id ? this.edit.employee_id : '-'
                 });
             }, error => {
     
             });
       }
       else{
        this._marathon.getEdit(this.id,this.roleId).subscribe(data => {
       
            //     this.cattype = data.data && data.data.categories[0] && data.data.categories[0].type;
                 this.cattype = data.data && data.data.categories[0] && data.data.categories[0].type;
                 if(this.cattype == 1){
                     this.typecheck = 1;
                 }else{
                    this.typecheck = 0; 
                 }
                 this.edit = data.data.user;
                 this.types = this.edit.type;
                 if (this.edit.type == "STUDENT") {
                     this.student = true;
                     this.faculty = false;
                 } else if (this.edit.type == "FACULTY") {
                     this.faculty = true;
                     this.student = false;
                 } else {
                     this.student = false;
                     this.faculty = false;
                 }
                 if (this.edit.reasearch_associate == 'Yes') {
                     this.research = true;
                 } else if (this.edit.reasearch_associate == 'No') {
                     this.res_data = true;
                 } else {
                     this.res_data = false;
                     this.research = false;
                 }
     
                 // this.startDate=DatePipe
     
                 this.startDate= this.datePipe.transform(data.data.createdAt, 'yyyy-MM-dd')
                 this.endDate= this.datePipe.transform(data.data.validTill, 'yyyy-MM-dd')
                 
     // this.cattype = 
     
                 this.adminForm.patchValue({
                     index: 1,
                     _id: this.edit._id ? this.edit._id : '-',
                     name: this.edit.name ? this.edit.name : '-',
                     //    reg_id: this.edit.reg_ID ? this.edit.reg_ID : '-',
                     email: this.edit.email ? this.edit.email : '-',
                     address: this.edit.address ? this.edit.address : '-',
                     gender: this.edit.gender ? this.edit.gender : '-',
                     //  t_shirt_size: this.edit.t_shirt_size.name,
                     dob: this.edit.dob ? this.edit.dob : '-',
                     phone_no: this.edit.phone_no ? this.edit.phone_no : '-',
                     start_date: this.startDate ? this.startDate : '-',
                     end_date: this.endDate ? this.endDate : '-',
                     //   em_contact_name: this.edit.em_contact_name ? this.edit.em_contact_name : '-',
                     //   em_contact_num: this.edit.em_contact_num ? this.edit.em_contact_num : '-',
                     //   blood_group: this.edit.blood_group ? this.edit.blood_group : '-',
                     //  type: this.edit.type ? this.edit.type : '-',
                     //  name_of_institute: this.edit.name_of_institute && this.edit.name_of_institute.name ? this.edit.name_of_institute &&  this.edit.name_of_institute.name : '-',
                     //   register_no: this.edit.register_no ? this.edit.register_no : '-',
                     //  course: this.edit.course ? this.edit.course : '-',
                     //  year: this.edit.year ? this.edit.year : '-',
                     ///   reasearch_associate: this.edit.reasearch_associate ? this.edit.reasearch_associate : '-',
                     //   employee_id: this.edit.employee_id ? this.edit.employee_id : '-'
                 });
             }, error => {
     
             });
       }
       
        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        this.adminForm = this.formBuilder.group({
            index: [{ value: null, disabled: true }],
            _id: [{ value: null, disabled: true }],
            name: ['', [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
            //   reg_id: [''],
            //   type: [''],
            gender: [''],
            //   reasearch_associate: [''],
            //   register_no: [''],
            //  category: [''],
            //  year: [''],
            phone_no: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            //  em_contact_name: ['',[Validators.required,Validators.pattern(nonWhitespaceRegExp)]],
            //  em_contact_num: ['',[Validators.required,Validators.pattern(nonWhitespaceRegExp),Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], 
            //   vaccine_status: ['',[Validators.required,Validators.pattern("^[0-9]*$"), Validators.maxLength(14),Validators.minLength(13)]],
            //  attendee_type: [''],
            dob: ['', [Validators.required]],
            start_date: ['', [Validators.required]],
            end_date: ['', [Validators.required]],
            address: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            //  department: [''],
            // designation: [''],
            //  blood_group: ['', [Validators.required,Validators.pattern(nonWhitespaceRegExp)]],
            //  t_shirt_size: [''],
            //  course: [''],
            //  employee_id: [''],
            //  name_of_institute: [''],
        })


    }
    // cancel(e){
    //     alert('fsdf')
    //     e.stopPropagation();
    //     this.router.navigate['/registration-list'];
    // }

    goBack() {
        this.location.back();
    }

    get f() { return this.adminForm.controls; }


    onSubmit(form, formWrap) {
        console.log("hyyyyynavami",this.roleId)
        if(this.roleId == '200'){
            this.submitted = true;
            let id = form.getRawValue()._id;
            let index = form.getRawValue().index;
            if (this.adminForm.invalid) {
                var that = this;
                that.toastr.error('Please enter all fields');
                return;
            }
    
            var that = this;
    
            if (that.id) {
                alertify.confirm('Update', 'Are you sure want to update this record', function () {
                    that._marathon.updatebanglrmarena(that.id,
                        // that.f.name.value,
                        // that.f.email.value,
                        // that.f.gender.value,
                        // that.f.dob.value,
                        // that.f.phone_no.value,
                        // that.f.address.value,
                        // that.f.start_date.value,
                        that.f.end_date.value,
                        that.roleId
                    )
                        .subscribe(
                            data => {
                                if (data.status.code == 0) {
                                    that.loading = true;
                                    that.router.navigate(['/registration-list']);
                                } else {
                                    document.body.classList.remove('jw-modal-open');
                                    that.loading = false;
                                    that.toastr.error(data.status.message)
                                }
                            },
                            error => {
                                that.toastr.error(error);
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                            });
                }, function () {
                    that.toastr.error('Update action cancelled')
                });
            }
        }

        else{

        this.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;
        if (this.adminForm.invalid) {
            var that = this;
            that.toastr.error('Please enter all fields');
            return;
        }

        var that = this;

        if (that.id) {
            alertify.confirm('Update', 'Are you sure want to update this record', function () {
                that._marathon.updatemarena(that.id,
                    // that.f.name.value,
                    // that.f.email.value,
                    // that.f.gender.value,
                    // that.f.dob.value,
                    // that.f.phone_no.value,
                    // that.f.address.value,
                    // that.f.start_date.value,
                    that.f.end_date.value,
                    that.roleId
                )
                    .subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.router.navigate(['/registration-list']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });
            }, function () {
                that.toastr.error('Update action cancelled')
            });
        }
    }

    }

    submit() { }


    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.edit.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e) {
        e.stopPropagation();
        this.router.navigate(['/registration-list']);
    }

    dateee(e)
    {
        console.log(e);
    }
}
