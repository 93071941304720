<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style>
    .version {
        width: 100%;
        text-align: center;
        padding: 0.5rem 0rem;
        position: absolute;
        left: 0px;
        bottom: 0px;
    }

    .version ul {
        padding: 0px;
        margin: 0px;
    }

    .version ul li {
        list-style-type: none;
        display: inline-block;
        margin: 0rem 0.3rem;
        font-size: 0.7rem;
    }

    .login-logo,
    .register-logo {
        font-size: 2.1rem;
        font-weight: 300;
        margin-bottom: 0.2rem;
        text-align: center;
        margin-top: 2.2rem;
    }

    .login-box-msg,
    .register-box-msg {
        margin: 0;
        padding: 0 20px 20px;
        text-align: center;
        font-weight: 600;
        color: rgba(0, 65, 90, 1);
    }

    span.fas.fa-envelope {
        color: rgba(236, 106, 34, 1);
    }

    span.fas.fa-lock {
        color: rgba(236, 106, 34, 1);
    }

    .login_btn:hover {
        background-color: rgba(0, 65, 90, 1);
        border-radius: 5px;
    }

    .login_btn {
        background-color: rgba(236, 106, 34, 1);
        border-radius: 5px;
        margin-top: 15px;
    }

    .first_footer {
        border-top: 1px solid #dee2e6;
        color: #ffffff;
        padding: 1rem;
        float: left;
        text-align: center;
        width: 100%;
        background-color: rgba(0, 65, 90, 1);
        box-shadow: 0 0 15px 0 rgb(18 131 214 / 20%);
    }


    element.style {
        min-height: 413.375px;
    }

    /* body {
        align-items: center;
        background: #e9ecef;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;

        background-image: url("../images/login-bg.png") !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    } */



    .login-page,
    .register-page {
        /* -ms-flex-align: center;
        align-items: center; */
        background: transparent !important;
        /* display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column; */
        /* height: 100vh; */
        /* -ms-flex-pack: center;
        justify-content: center; */
        background-image: url("../images/login-bg.png") !important;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        min-height: calc(100vh - 57px) !important;
    }

    .login-page {
        /* -ms-flex-align: center;
        align-items: center; */
        background: transparent !important;
        /* display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column; */
        /* height: 100vh; */
        /* -ms-flex-pack: center;
        justify-content: center; */
        display: block !important;
        background-image: url("../images/login-bg.png") !important;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        /* min-height: calc(100vh - 57px) !important; */
    }


    .login_box {
        margin: 0% auto;
    }

    /* .login_body {
        min-height: calc(100vh - 57px) !important;
       
    } */
</style>


    <div class="login-box">

        <!-- /.login-logo -->
        <div class="card">
            <div class="login-logo">
                <a href="/login"><img src="assets/images/logo1.png" class="img-responsive" alt="logo"></a>
            </div>
            <div class="card-body login-card-body">
                <p class="login-box-msg" *ngIf="hide">SIGN IN TO START YOUR SESSION</p>
                <p class="login-box-msg" *ngIf="!hide">Change password to login</p>
                <form [formGroup]="loginForm" #myForm="ngForm" (ngSubmit)="onSubmit()" class="">
                    <div class="input-group mb-3" *ngIf="hide">
                        <input type="email" class="form-control" placeholder="Email" formControlName="email"
                            [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.email.errors?.required">Email is required</div>
                        </div>
                        <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email" class="invalid-feedback">
                            <div *ngIf="f.email.errors?.email">Please enter
                                valid email</div>
                        </div>
                        <div *ngIf="(f.email.touched || submitted) && f.email.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.email.errors?.maxlength">Maximum 50 characters are allowed
                            </div>
                        </div>
                    </div>
                    <div class=" " formGroupName="passwords" *ngIf="!hide">
                        <div class="input-group mb-3">
                            <input autocomplete="off" type="password" class="form-control password" id="inputNew"
                                formControlName="new_password" placeholder="New Password" autocomplete="off"
                                [ngClass]="{ 'is-invalid': (f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors }">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                            <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.passwords['controls'].new_password.errors?.required">New Password is
                                    required
                                </div>
                            </div>
                            <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.passwords['controls'].new_password.errors?.maxlength">Maximum 100
                                    characters
                                    are allowed</div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input autocomplete="off" type="password" class="form-control" id="inputConfirm"
                                formControlName="confirm_password" placeholder="Confirm Password" autocomplete="off"
                                [ngClass]="{ 'is-invalid': (f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords['controls'].confirm_password.errors?.required
                        || f.passwords.errors?.areEqual) }" />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.required "
                                class="invalid-feedback">
                                <div *ngIf="f.passwords['controls'].confirm_password.errors?.required">
                                    Confirm Password is required</div>
                            </div>
                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.maxlength "
                                class="invalid-feedback">
                                <div *ngIf="f.passwords['controls'].confirm_password.errors?.maxlength">
                                    Maximum 100 characters are allowed</div>
                            </div>
                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)"
                                class="invalid-feedback">Passwords do not match
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3" *ngIf="hide">
                        <input type="password" class="form-control" placeholder="Password" formControlName="password"
                            [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div *ngIf="(f.password.touched || submitted) && f.password.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.password.errors?.required">Password is required</div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block login_btn">Sign In</button>
                        </div>
                       
                    </div>
                </form>
              
            </div>
           
        </div>
    </div>
    <!-- <div class="version">
    <ul>
        <li>Best viewed in</li>
        <li>Chrome : Version 83 & above,</li>
        <li>Firefox : Version 71 & above,</li>
        <li>Edge : Version 85 & above</li>
    </ul>
</div> -->
    <footer class="first_footer">
        DESIGNED AND DEVELOPED BY CHIPSY IT SERVICES PVT. LTD.
        <script type="text/javascript">
            document.write(new Date().getFullYear());
        </script> 
        <!-- All rights reserved -->
    </footer>
