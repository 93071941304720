import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'institution_list.component.html',
    styleUrls: ['institution_list.component.css']
})
export class InstitutionListComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cur_page = 1;
    totalPages = 1;
    search_txt = '';
    institution_list = [];
    totalcount: any;
    category: any;

    roleId = localStorage.getItem(`${environment.appName}` + '_role');



    // @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

    pagevalue: any;


    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        // this.loading = true;
        this.loading = false;

    }
    ngOnInit() {
        if (!(this.roleId == '103')) {
            this.router.navigate(['/'])
        }


        this.refresh(true);


    }






    refresh(reset = false) {

        this.loading = true;

        var that = this
        that._marathon.getQuickPayInstitutionList(that.cur_page, that.search_txt).subscribe(data => {

            that.loading = false;
            that.institution_list = data.data.docs;
            that.totalcount = data.data.totalDocs;
            that.cur_page = data.data.page;
            that.totalPages = data.data.totalPages;



        }, error => {
        });

    }


    search(e) {
        e.stopPropagation();

        this.search_txt = e.target.value;

        this.changePage(1);

        this.loading = true;


        var that = this;
        that._marathon.getQuickPayInstitutionList(this.cur_page, this.search_txt).subscribe(data => {
            that.loading = false;
            that.institution_list = data.data.docs;
            that.cur_page = data.data.page;
            that.totalcount = data.data.totalDocs;
            that.totalPages = data.data.totalPages;
        }, error => {
        });

    }

    changePage(page) {

        this.cur_page = page;
        console.log(this.pagevalue);




        var that = this;


        that._marathon.getQuickPayInstitutionList(that.cur_page, that.search_txt).subscribe(data => {
            that.institution_list = data.data.docs;
            that.cur_page = data.data.page;
            that.totalcount = data.data.totalDocs;
            that.totalPages = data.data.totalPages;
            that.pagevalue = 'active_student';

        }, error => {
        });
    }


    refesh2() {
        // this.refresh();
        var that = this;
        that._marathon.getQuickPayInstitutionList(that.cur_page, that.search_txt).subscribe(data => {
            that.institution_list = data.data.docs;
            that.cur_page = data.data.page;
            that.totalcount = data.data.totalDocs;
            that.totalPages = data.data.totalPages;
            that.pagevalue = 'active_student';
          
        }, error => {
        });
    }

    resetFilter(e) {
        this.search_txt = '';
        $('#search1').val('');
        this.refresh(true);
    }

    deleteQuicPayInstitution(id) {
        var that = this;
        alertify.confirm('Delete', 'Are you sure want to delete', function () {


            that._marathon.deleteQuicPayInstitutionList(id).subscribe(data => {

                if (data.status.code == 0) {
                    that.toastr.success(data.status.message)
                    that.refesh2();
                }
                else {
                    that.toastr.error(data.status.message)
                }

            })
        }
            , function () {
                that.toastr.error('Delete action cancelled')
            }).set('movable', false)
    }



}



