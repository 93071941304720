<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
 
    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/registration-list">Registration List</a></li>
                        <li class="breadcrumb-item active">Edit</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
   
 
    <section class="content">
        <div class="container-fluid page_section">
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content reg_view_form">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <div class="register_view_top">
                                            <div class="registartion_vleft">
                                                <div class="registartion_id">Registration ID: {{edit && edit.reg_ID}}</div>
                                            </div>
                                            
                                            <div class="filler"></div>
                                            <!-- <div class="total_amount_sec">
                                                <div class="totamt_txt">Total Amount</div>
                                                <div id="amt_underline">
                                                    <div></div>
                                                </div>
                                                <div class="rupee_section">
                                                    <img src="assets/images/rupee-symbol.png" class="img-responsive" alt="rupee_symbol">
                                                    {{edit && edit.total_amount}}
                                                </div>
                                            </div> -->
                                        </div>
                                        <form class="registration_view_form" [formGroup]="adminForm" #myForm="ngForm"
                                            (ngSubmit)="onSubmit(adminForm, myForm)">
                                            <div class="col-sm-12">
                                                <div class="row">

                                                    <!-- <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="type">Membership Category</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="category"
                                                                [ngClass]="{ 'is-invalid': (f.category.touched || submitted) && f.category.errors }" readonly>
                                                            <div *ngIf="(f.category.touched || submitted) && f.category.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.category.errors?.required">Category is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee name">Attendee Name</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="name" disabled
                                                                [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.name.errors?.required">Name is required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.name.errors?.pattern">Empty space is not allowed
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="attendee email">Attendee Email</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="email" disabled
                                                                [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
                                                            <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.email.errors?.required">Email
                                                                    is
                                                                    required</div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.email.touched || submitted) && f.email.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.email.errors?.pattern">Enter valid email pattern</div>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="address">Address</label>
                                                            <input autocomplete="off" type="text" class="form-control" id="address"
                                                                formControlName="address" disabled
                                                                [ngClass]="{ 'is-invalid': (f.address.touched || submitted) && f.address.errors }">
                                                            <div *ngIf="(f.address.touched || submitted) && f.address.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.address.errors?.required">Address is required</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="Gender">Gender</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="gender" disabled
                                                                [ngClass]="{ 'is-invalid': (f.gender.touched || submitted) && f.gender.errors }">
                                                            <div *ngIf="(f.gender.touched || submitted) && f.gender.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.gender.errors?.required">Gender
                                                                    is
                                                                    required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="age">DOB</label>
                                                            <input autocomplete="off" type="date" class="form-control" 
                                                                formControlName="dob" disabled
                                                                [ngClass]="{ 'is-invalid': (f.dob.touched || submitted) && f.dob.errors }">
                                                            <div *ngIf="(f.dob.touched || submitted) && f.dob.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.dob.errors?.required">Date of Birth is
                                                                    required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="phone number">Phone Number</label>
                                                            <input autocomplete="off" type="text" class="form-control"
                                                                formControlName="phone_no" disabled
                                                                [ngClass]="{ 'is-invalid': (f.phone_no.touched || submitted) && f.phone_no.errors }" maxlength="10" minlength="10">
                                                            <div *ngIf="(f.phone_no.touched || submitted) && f.phone_no.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.phone_no.errors?.required">
                                                                    Phone Number is required</div>
                                                            </div>
                                                            <div *ngIf="(f.phone_no.touched || submitted) && f.phone_no.errors?.pattern"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.phone_no.errors?.pattern">
                                                               Enter only digits</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label for="age">Start Date</label>
                                                            <input autocomplete="off" type="date" class="form-control"
                                                                formControlName="start_date" disabled
                                                                [ngClass]="{ 'is-invalid': (f.start_date.touched || submitted) && f.start_date.errors }">
                                                            <div *ngIf="(f.start_date.touched || submitted) && f.start_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.start_date.errors?.required">Start Date is
                                                                    required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4" *ngIf="roleId == '101'">
                                                        <div class="form-group" *ngIf="typecheck == 1">
                                                            <label for="age">End Date</label>
                                                            <input autocomplete="off" type="date" class="form-control"
                                                                formControlName="end_date" disabled
                                                                [ngClass]="{ 'is-invalid': (f.end_date.touched || submitted) && f.end_date.errors }">
                                                            <div *ngIf="(f.end_date.touched || submitted) && f.end_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.end_date.errors?.required">End Date is
                                                                    required</div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="form-group" *ngIf="typecheck != 1">
                                                            <label for="age">End Date</label>
                                                            <input autocomplete="off" type="date" class="form-control"
                                                                formControlName="end_date"
                                                                [ngClass]="{ 'is-invalid': (f.end_date.touched || submitted) && f.end_date.errors }">
                                                            <div *ngIf="(f.end_date.touched || submitted) && f.end_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.end_date.errors?.required">End Date is
                                                                    required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4" *ngIf="roleId != '101'">
                                                        <div class="form-group">
                                                            <label for="age">End Date</label>
                                                            <input autocomplete="off" type="date" class="form-control"
                                                                formControlName="end_date" 
                                                                [ngClass]="{ 'is-invalid': (f.end_date.touched || submitted) && f.end_date.errors }">
                                                            <div *ngIf="(f.end_date.touched || submitted) && f.end_date.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.end_date.errors?.required">End Date is
                                                                    required</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>






                                                <div class="submit_cls">
                                                    <button class="sub_cls" type="submit">Submit</button>
                                                    <button class="can_cls" (click)="cancel($event)">Cancel</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>

