<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Upload Excel</li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="serach_sec">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order-head">Upload Excel</div>
                    </div>
                </div>
                <div class="search_cls" *ngIf="!show_excel_upload">
                    <div class="row">
                        <div class="col-lg-3">
                            <input type="Search" name="search1" id="search1" (keyup.enter)="search($event)"
                                placeholder="Search Name, Email, Phone No.">
                        </div>

                        <!-- <div class="col-lg-3">
                            <select id="status" class="normal_background form-control"
                                (change)="changeFilter($event,'tshirt')">
                                <option value="-1">Select T-shirt size</option>
                                <option *ngFor="let t of t_shirt; let x=index" [value]="t.type">{{t.name}}
                                </option>
                            </select>
                        </div> -->
                        <div class="col-lg-3">
                            <button (click)="uploadExcelToggle($event)" class="btn btn-info btn-height">
                                Upload Excel
                            </button>
                        </div>

                        <div class="col-lg-3">
                            <button (click)="download()" style="cursor: pointer;" class="btn btn-info btn-height">
                                        <!-- <a (click)="download()" style="cursor: pointer;"> -->
                                            <i class="fas fa-download"></i> Download Template
                                        <!-- </a> -->
                                    </button>
                        </div>

                       
                        <!-- <div class="col-sm-12">
                            <div class="reset_btn">
                                <button (click)="resetFilter($event)" class="btn btn-secondary btn-sm">Reset</button>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="col-sm-8" *ngIf="show_excel_upload">
                    <div class="row">
                        <!-- <div class="col-sm-4">
                            <select class="form-control" (change)="changeType($event)">
                                <option>Select Type</option>
                                <option value="student">Student</option>
                                <option value="faculty">Faculty</option>
                            </select>
                        </div> -->

                        <div class="col-sm-4">
                            <input type="file" (change)="chooseFile($event)" class="form-control">
                        </div>

                        <div class="col-sm-4">
                            <input type="button" (click)="uploadExcel()" class="btn btn-primary" value="Upload Excel">
                            <input type="button" (click)="show_excel_upload=false" class="btn btn-danger ml-2"
                                value="Cancel">
                        </div>
                    </div>

                </div>






            </div>
            <div class="order_tabs">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">

                            <div class="tab-content">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Registration ID</td>
                                                        <td>Username</td>
                                                        <td>Details</td>
                                                        <td>Date</td>
                                                        <td>Place</td>
                                                        <td>Amount</td>
                                                    </tr>
                                                    <tr *ngFor="let list of register_list | paginate : {
                                                        itemsPerPage: pageSize,
                                                        currentPage: cur_page,
                                                        totalItems: totalcount}; let x=index">
                                                        <td>{{list.name?list.name:'-'}}</td>
                                                        <td>{{list.email?list.email:'-'}}</td>
                                                        <td>{{list.gender?list.gender:'-'}}</td>
                                                        <td>{{list.age?list.age:'-'}}</td>
                                                        <td>{{list.phone_number?list.phone_number:'-'}}</td>
                                                        <td>
                                                            <a [routerLink]="['/registration-view',list._id]"> <i
                                                                    class="fa fa-eye 2x">
                                                                </i>
                                                            </a>

                                                            <span *ngIf="list.emp_id_image"
                                                                (click)="viewImage($event,list)"><i
                                                                    class="fa fa-image ml-2"></i></span>
                                                        </td>

                                                    </tr>
                                                    <tr *ngIf="!register_list.length">
                                                        <td colspan="7" class="text-center">No Results Found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="paginate">
                                        <p>Showing {{from}} to {{cur_page*10}} of {{totalcount}} entries</p>
                                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                                          (pageChange)="changePage($event)"></pagination-controls>
                                      </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>



    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Employee ID</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img [src]="view_image" class="img-responsive">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
