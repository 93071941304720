import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

declare var jquery: any;
declare var $: any;
@Component({
    templateUrl: 'quickpay_payment_list.component.html',
    styleUrls: ['quickpay_payment_list.component.css']
})

export class QuickPayPaymentListComponent implements OnInit {
    myDpOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
    };

    myDateInit: boolean = true;
    model: IMyDateModel = null;

    loading = false;
    submitted = false;
    cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    filter_date = null;
    // filter_status = null;
    payment_list = [];
    media_domain = environment.media_domain;
    // totalcount: any;
    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        this.loading = true;
       
        let binaryData = [];
        
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
       
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'registration.xlsx';
        link.click();
        
        this.loading = false;
        document.body.classList.remove('jw-modal-open');
       
    }

    ngOnInit() {
        if (!(this.roleId == '103')) {
            this.router.navigate(['/'])
        }
       
        this.refresh(true);
        var that = this;
    }


    refresh(reset = false) {
        // , this.filter_status
        this._marathon.getQuickpaymentPaymentList(this.cur_page, this.search_txt, this.filter_date).subscribe(data => {
            this.payment_list = data.data.docs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
        }, error => {

        });
    }

  
    search(e) {
        e.stopPropagation();

        this.search_txt = e.target.value;
        // , this.filter_date, this.filter_status
        this._marathon.getQuickpaymentPaymentList(1, this.search_txt,this.filter_date).subscribe(data => {
            this.payment_list = data.data.docs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
        }, error => {

        });
    }

    filterDate(e) {

      
        this.filter_date = e.singleDate.formatted
       
        this.refresh();
    }

    // filterStatus(e) {
    //     this.filter_status = e.target.value;
    //     if (e.target.value == '') {
    //         this.filter_status = null;
    //     }
    //     this.refresh();
    // }

    changePage(page) {
        this.cur_page = page;


        this.refresh();


    }
    

    resetFilter(e) {
        this.model = null;
       
        this.search_txt = '';
        this.filter_date = null;
        // this.filter_status = null;
        $('#search1').val('');
        $('#status').val('');
        $("#search1").val('')
        $("input[type=date]").val("")
        this.refresh(true);
        // , this.filter_status
        this._marathon.getQuickpaymentPaymentList(1, this.search_txt, this.filter_date).subscribe(data => {
            this.payment_list = data.data.docs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
        }, error => {

        });

    }

    public async downloadExcel() {
        const blob = await this._marathon.getQuickpaymentPaymentListDownload(this.cur_page, this.search_txt,this.filter_date);
        let binaryData = [];
        binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'Payment_logs.xlsx';
        link.click();
    }

    


}
