import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'institution_add.component.html',
    styleUrls: ['institution_add.component.css']
})
export class InstitutionAddComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    categoryAddForm: FormGroup;
    types: any
    student = false;
    faculty = false;
    research = false;
    res_data = false;
    //table = false;
    //cancelButton = false;
    // onSubmit=false;

    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,

        private router: Router) { }

    edit = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    startDate: any;
    endDate: any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');


    ngOnInit() {

        if(!(this.roleId == '103'))
        {
            this.router.navigate(['/'])   
        }

        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

        this.categoryAddForm = this.formBuilder.group({
            index: [{ value: null, disabled: true }],
            _id: [{ value: null, disabled: true }],
            name: ['',[Validators.required]],
            current_status: ['1',[Validators.required]],
           
        })
    }

    goBack() {
        this.location.back();
    }

    get f() { return this.categoryAddForm.controls; }


    onSubmit(form, formWrap) {

        this.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;

        if (this.categoryAddForm.invalid) {
            var that = this;
            that.toastr.error('Please enter all fields');
            return;
        }

        var that = this;

        // if (that.id) {
            alertify.confirm('Add', 'Are you sure want to add this record?', function () {

                if (that.roleId == '103') {
                    that._marathon.quickPayInstitutionAdd(
                        that.f.name.value,
                        that.f.current_status.value,

                    ).subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.toastr.success(data.status.message)
                                that.router.navigate(['/institution-list']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });
                } 
               
            }, function () {
                that.toastr.error('Add Action Cancelled')
            });
      
    }

    submit() { }


    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.edit.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e) {
        e.stopPropagation();
        this.router.navigate(['/institution-list']);
    }

    dateee(e) {
        console.log(e);
    }
}
