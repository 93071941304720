<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Ad-hoc List</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <!-- <div class="serach_sec">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order-head">Search</div>
                    </div>
                </div>
                <div class="search_cls">
                    <div class="row">
                        <div class="col-sm-4">
                            <input type="Search" name="search1" id="search1" (keyup.enter)="search($event)"
                                placeholder="Search Name, Email, Phone No.">
                        </div>
                        
                        <div class="col-sm-2">
                            <select id="status" class="normal_background form-control"
                                (change)="changeFilter($event,'tshirt')">
                                <option value="-1" disabled>Select T-shirt size</option>
                                <option *ngFor="let t of t_shirt; let x=index" [value]="t.type">{{t.name | titlecase}}
                                </option>
                            </select>
                        </div>

                        <div class="col-sm-3">
                            <select id="status" class="normal_background form-control"
                            (change)="changeFilter($event,'category')">
                            <option value="-1">Select Run Category</option>
                            <option *ngFor="let cat of category; let x=index" [value]="cat.type">
                                {{cat.name | titlecase}}
                            </option>
                            </select>
                        </div>
                      
                        <div class="col-sm-1">
                            <div class="reset_btn">
                                <button (click)="resetFilter($event)" class="btn btn-secondary btn-sm">Reset</button>
                            </div>
                        </div>

                        <div class="col-sm-2"></div>

                    </div>
                </div>



            </div> -->

      <div class="order_tabs">
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <button
                (click)="add()"
                class="btn btn-primary btn-sm mb-2"
                style="margin-right: 10px"
              >
                Add +
              </button>

              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <!-- <th width="10%">Sl No.</th> -->
                            <th width="15%">Registration ID</th>
                            <th width="15%">Username</th>
                            <th width="15%">Details</th>
                            <th width="15%">Date</th>
                            <th width="10%">Place</th>
                            <th width="10%">Amount</th>
                            <th width="10%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            *ngFor="
                              let list of adhoclist
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                            <td>{{ list.regId ? list.regId : "-" }}</td>
                            <td>
                              {{ list.users.name ? list.users.name : "-" }}
                            </td>
                            <td>{{ list.details ? list.details : "-" }}</td>
                            <td>{{ list.date ? list.date : "-" }}</td>
                            <td>{{ list.place ? list.place : "-" }}</td>
                            <td>{{ list.amount ? list.amount : "-" }}</td>

                            <td>
                              <a
                                [routerLink]="['/createdetails']"
                                [queryParams]="{ id: list._id }"
                              >
                                <i class="fa fa-edit 2x edit_cls"> </i>
                              </a>
                              <a (click)="delete($event, list)">
                                <i class="fa fa-trash 2x"> </i>
                              </a>
                            </td>
                          </tr>
                          <tr *ngIf="!adhoclist.length">
                            <td colspan="9" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
