<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/event-list">Event List</a>
            </li>
            <li class="breadcrumb-item active">Amount Recived</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                class="search1"
                (keyup.enter)="search($event)"
                placeholder="Search Name"
              />
            </div>

            <!-- <div class="col-sm-4">
                            <input type="date" name="search1" id="search1" class="search1"
                                (change)="filterDate($event)">
                        </div> -->

            <!-- <div class="col-sm-3"> -->
            <!-- <input type="date" name="search1" id="search1" class="search1"
                                (change)="filterDate($event)"> -->

            <!-- <select  class="form-control">
                                <option value=" ">All</option>
                                <option value="0">Success</option>
                                <option value="1">Invalid</option>
                            
                            </select> -->

            <!-- <select id="status" class="normal_background form-control"
                                (change)="filterStatus($event)">
                                <option value=''>All</option>
                                <option value="0">Success</option>
                                <option value="1">Invalid</option>
                            </select> -->
            <!-- </div> -->

            <div class="col-sm-4">
              <div class="flex flt">
                <div class="reset_btn mr-3">
                  <button
                    (click)="resetFilter($event)"
                    class="btn btn-secondary btn-sm"
                  >
                    Reset
                  </button>
                </div>

                <div
                  class="reset_btn"
                  *ngIf="roleId == '99' || roleId == '100'"
                >
                  <button
                    [routerLink]="['/category-add']"
                    class="btn btn-secondary btn-sm add_category"
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </div>

            <!-- <div class="col-sm-2">
                            <select id="status" class="normal_background form-control"
                                (change)="changeFilter($event,'tshirt')">
                                <option value="-1" disabled>Select T-shirt size</option>
                                <option *ngFor="let t of t_shirt; let x=index" [value]="t.type">{{t.name | titlecase}}
                                </option>
                            </select>
                        </div> -->

            <!-- <div class="col-sm-3">
                            <select id="status" class="normal_background form-control"
                            (change)="changeFilter($event,'category')">
                            <option value="-1">Select Run Category</option>
                            <option *ngFor="let cat of category; let x=index" [value]="cat.type">
                                {{cat.name | titlecase}}
                            </option>
                            </select>
                        </div> -->
            <!--                       
                        <div class="col-sm-1">
                            <div class="reset_btn">
                                <button (click)="resetFilter($event)" class="btn btn-secondary btn-sm">Reset</button>
                            </div>
                        </div> -->

            <!-- <div class="col-sm-2"></div> -->
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <!-- <button (click)="downloadExcel()" class="btn btn-primary btn-sm mb-2"
                                            style="margin-right: 10px;">Download Payment Logs </button> -->
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download Payment Logs
                    </button>
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >

                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th *ngIf="roleId == '99' || roleId == '100'">
                              One Month Amount
                            </th>
                            <!-- <th *ngIf="roleId == '99' || roleId == '100'">Two Month Amount</th>
                                                        <th *ngIf="roleId == '99' || roleId == '100'">Three Month Amount</th>
                                                        <th *ngIf="roleId == '99' || roleId == '100'">Six Month Amount</th> -->
                            <th *ngIf="roleId == '99' || roleId == '100'">
                              Year Amount
                            </th>

                            <th *ngIf="roleId == '101'">
                              Student Half Year Fees
                            </th>
                            <th *ngIf="roleId == '101'">Student Year Fees</th>
                            <th *ngIf="roleId == '101'">
                              Staff Half Year Fees
                            </th>
                            <th *ngIf="roleId == '101'">Staff Year Fees</th>
                            <th *ngIf="roleId == '101'">
                              Other Half Year Fees
                            </th>
                            <th *ngIf="roleId == '101'">Other Year Fees</th>
                            <th *ngIf="roleId == '101'">Coaching Fees</th>
                            <th *ngIf="roleId == '101'">Month Fees</th>
                            <th *ngIf="roleId == '101'">Year Fees</th>

                            <th>Status</th>
                            <th width="100px">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let list of category_list; let x = index">
                            <td>{{ list.name ? list.name : "-" }}</td>
                            <td *ngIf="roleId == '99' || roleId == '100'">
                              {{
                                list.one_month_amount
                                  ? list.one_month_amount
                                  : "-"
                              }}
                            </td>
                            <!-- <td *ngIf="roleId == '99' || roleId == '100'">{{list.two_month_amount?list.two_month_amount:'-'}}</td>
                                                        <td *ngIf="roleId == '99' || roleId == '100'">{{list.three_month_amount?list.three_month_amount:'-'}}</td>
                                                        <td *ngIf="roleId == '99' || roleId == '100'">{{list.six_month_amount?list.six_month_amount:'-'}}</td> -->
                            <td *ngIf="roleId == '99' || roleId == '100'">
                              {{ list.year_amount ? list.year_amount : "-" }}
                            </td>

                            <td *ngIf="roleId == '101'">
                              {{
                                list.studenthalfyear_fees
                                  ? list.studenthalfyear_fees
                                  : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.studentyear_fees
                                  ? list.studentyear_fees
                                  : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.staffhalfyear_fees
                                  ? list.staffhalfyear_fees
                                  : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.staffyear_fees ? list.staffyear_fees : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.otherhalfyear_fees
                                  ? list.otherhalfyear_fees
                                  : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.otheryear_fees ? list.otheryear_fees : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{
                                list.coaching_fees ? list.coaching_fees : "-"
                              }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{ list.month_fees ? list.month_fees : "-" }}
                            </td>
                            <td *ngIf="roleId == '101'">
                              {{ list.year_fees ? list.year_fees : "-" }}
                            </td>

                            <td>
                              {{ list.status == "1" ? "Active" : "Inactive" }}
                            </td>

                            <td>
                              <!-- <a [routerLink]="['/category-view',list._id]"> <i
                                                                    class="fa fa-eye 2x">
                                                                </i>
                                                            </a> -->

                              <a [routerLink]="['/category-edit', list._id]">
                                <i class="fa fa-pencil-alt"> </i>
                              </a>
                            </td>
                          </tr>
                          <tr *ngIf="!category_list.length">
                            <td colspan="12" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <nav aria-label="..." class="paginate_nav">
                    <ul class="pagination">
                      <li
                        class="page-item disabled"
                        [ngClass]="{ disable: cur_page === 1 }"
                        (click)="changePage(cur_page - 1)"
                      >
                        <a class="page-link" tabindex="-1">Previous</a>
                      </li>

                      <li class="page-item active">
                        <a class="page-link"
                          >{{ cur_page
                          }}<span class="sr-only">(current)</span></a
                        >
                      </li>
                      <li
                        class="page-item"
                        [ngClass]="{ disable: cur_page === totalPages }"
                        (click)="changePage(cur_page + 1)"
                      >
                        <a class="page-link">Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
