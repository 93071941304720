<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading
  [show]="loading"
  class="loader"
  [template]="customLoadingTemplate"
></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Registration List</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                (keyup.enter)="search($event)"
                autocomplete="off"
                placeholder="Search Name, Email, Enrollment No, Phone No."
              />
            </div>

            <!-- <div class="col-sm-2">
                            <select id="status" class="normal_background form-control"
                                (change)="changeFilter($event,'tshirt')">
                                <option value="-1">Select T-shirt size</option>
                                <option *ngFor="let t of t_shirt; let x=index" [value]="t.type">{{t.name}}
                                </option>
                            </select>
                        </div> -->

            <!-- <div class="col-sm-3" *ngIf="roleId == '99'">
                            <select id="status" class="normal_background form-control"
                            (change)="changeFilter($event,'category')">
                            <option value="-1">Select Category</option>
                            <option *ngFor="let cat of category; let x=index" [value]="cat.type">
                                {{cat.details | titlecase}}
                            </option>
                            </select>
                        </div> -->

            <div class="col-sm-2" *ngIf="roleId == '100' && ismem">
              <select
                id="status"
                class="normal_background form-control"
                (change)="changeFilter($event, 'category')"
              >
                <option value="-1">Select Category</option>
                <option
                  *ngFor="let cat of category; let x = index"
                  [value]="cat._id"
                >
                  {{ cat.name | titlecase }}
                </option>
              </select>
            </div>

            <div class="col-sm-2" *ngIf="roleId == '101' && ismem">
              <select
                id="status"
                class="normal_background form-control"
                (change)="changeFilter($event, 'category')"
              >
                <option value="-1">Select Category</option>
                <option
                  *ngFor="let cat of $category; let x = index"
                  [value]="cat._id"
                >
                  {{ cat.name | titlecase }}
                </option>
              </select>
            </div>

            <div class="col-sm-2" *ngIf="roleId == '200' && ismem">
              <select
                id="status"
                class="normal_background form-control"
                (change)="changeFilter($event, 'category')"
              >
                <option value="-1">Select Category</option>
                <option
                  *ngFor="let cat of category; let x = index"
                  [value]="cat._id"
                >
                  {{ cat.name | titlecase }}
                </option>
              </select>
            </div>

            <!-- <div class="col-sm-3" *ngIf="roleId == '99'">
                            <select id="status" class="normal_background form-control"
                            (change)="changeFilter($event,'$category')">
                            <option value="-1">Select Category</option>
                            <option *ngFor="let cat of $category; let x=index" [value]="cat.type">
                                {{cat.details | titlecase}}
                            </option>
                            </select>
                        </div> -->
            <div class="col-sm-3">
              <select
                id="att_type"
                class="normal_background form-control"
                (change)="changeFilter($event, 'attendee_type')"
              >
                <option value="-1">Select Attendee Type</option>
                <option
                  *ngFor="let a_type of att_type; let x = index"
                  [value]="a_type.type"
                >
                  {{ a_type.name }}
                </option>
              </select>
            </div>
            <div class="col-sm-2" *ngIf="activeclass == 'reg'">
              <select
                id="member_p"
                class="normal_background form-control"
                (change)="changeFilter($event, 'membership_period')"
              >
                <option [value]="-1">Select Period</option>
                <option [value]="12">12 Months</option>
                <option [value]="6">6 Months</option>
                <option [value]="3">3 Months</option>
                <option [value]="2">2 Months</option>
                <option [value]="1">1 Month</option>
              </select>
            </div>
            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
            <!-- <div class="col-sm-2"></div> -->
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="order_tabs">
          <!-- 
                    <ul class="nav nav-pills convocation_tab flt">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active':activeclass=='reg'} " data-toggle="pill"
                                (click)="reg($event)">
                                <div class="dashbox dash_all flt">
                                    <div class="dashbox_top flt">
                                        <div class="dashbox_left">
                                            <div class="dashbox_count flt" *ngIf="roleId == '100' || roleId == '99'">
                                                {{dashboad && dashboad.register ?
                                                dashboad &&
                                                dashboad.register : 0}}</div>
                                            <div class="dashbox_count flt" *ngIf="roleId == '101'">{{dashboad &&
                                                dashboad.registerswim ? dashboad &&
                                                dashboad.registerswim : 0}}</div>
                                            <div class="dashbox_count flt" *ngIf="!roleId">0</div>
                                            <div class="dashbox_name flt">Registration</div>
                                        </div>
                                        <div class="dashbox_right"><i class="fa fa-list" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="dashbox_btm flt">
                                        More info <i class="fas fa-arrow-circle-right"></i>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="active flt" (click)="listStatus($event, 0)">
                                <div class="dashbox dash_all flt">
                                    <div class="dashbox_top flt">
                                        <div class="dashbox_left">
                                            <div class="dashbox_count flt">{{$totalCount && $totalCount.pending_count ?
                                                $totalCount &&
                                                $totalCount.pending_count : '0'}}</div>
                                            <div class="dashbox_name flt">Pending</div>
                                        </div>
                                        <div class="dashbox_right"><i class="fa fa-list" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="dashbox_btm flt">
                                        More info <i class="fas fa-arrow-circle-right"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="flt" (click)="listStatus($event, 1)">
                                <div class="dashbox dash_approved flt">
                                    <div class="dashbox_top flt">
                                        <div class="dashbox_left">
                                            <div class="dashbox_count flt">{{$totalCount && $totalCount.approved_count ?
                                                $totalCount &&
                                                $totalCount.approved_count : '0'}}</div>
                                            <div class="dashbox_name flt">Approved</div>
                                        </div>
                                        <div class="dashbox_right"><i class="fa fa-check" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="dashbox_btm flt">
                                        More info <i class="fas fa-arrow-circle-right"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="flt" (click)="listStatus($event, 2)">
                                <div class="dashbox dash_hold flt">
                                    <div class="dashbox_top flt">
                                        <div class="dashbox_left">
                                            <div class="dashbox_count flt">{{$totalCount && $totalCount.hold_count ?
                                                $totalCount &&
                                                $totalCount.hold_count : '0'}}</div>
                                            <div class="dashbox_name flt">On Hold</div>
                                        </div>
                                        <div class="dashbox_right"><i class="fa fa-stop-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="dashbox_btm flt">
                                        More info <i class="fas fa-arrow-circle-right"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="flt" (click)="listStatus($event, 4)">
                                <div class="dashbox dash_rejected flt">
                                    <div class="dashbox_top flt">
                                        <div class="dashbox_left">
                                            <div class="dashbox_count flt">{{$totalCount && $totalCount.rejected_count ?
                                                $totalCount &&
                                                $totalCount.rejected_count : '0'}}</div>
                                            <div class="dashbox_name flt">Rejected</div>
                                        </div>
                                        <div class="dashbox_right"><i class="fa fa-times" aria-hidden="true"></i></div>
                                    </div>
                                    <div class="dashbox_btm flt">
                                        More info <i class="fas fa-arrow-circle-right"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul> -->

          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'reg' }"
                data-toggle="pill"
                (click)="reg($event)"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Registration</span>
                    <span
                      class="info-box-number"
                      *ngIf="roleId == '100' || roleId == '99' || roleId == '200'"
                      >{{
                        dashboad && dashboad.register
                          ? (dashboad && dashboad.register
                            | number : "1.0" : "en-US")
                          : 0
                      }}</span
                    >
                    <span class="info-box-number" *ngIf="roleId == '101'">{{
                      dashboad && dashboad.registerswim
                        ? (dashboad && dashboad.registerswim
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-user-check"></i>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item" *ngIf="roleId == '101' || roleId == '100' || roleId == '99'">
             <!-- || roleId == '200 temororily hidden for banglore marena -->

              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'pending' }"
                data-toggle="pill"
                (click)="pending($event)"
              >
                <div class="info-box mb-3">
                  <div lass="info-box-content">
                    <span class="info-box-text">Pending</span>
                    <span
                      class="info-box-number"
                      *ngIf="roleId == '100' || roleId == '99'"
                      >{{
                        dashboad && dashboad.pending
                          ? (dashboad && dashboad.pending
                            | number : "1.0" : "en-US")
                          : 0
                      }}</span
                    >
                    <!-- || roleId == '200 temororily hidden for banglore marena -->
                    <span class="info-box-number" *ngIf="roleId == '101'">{{
                      dashboad && dashboad.swimpending
                        ? (dashboad && dashboad.swimpending
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-list"></i>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item" *ngIf="roleId == '101' || roleId == '100' || roleId == '99'">
             <!-- || roleId == '200 temororily hidden for banglore marena -->

              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'approve' }"
                data-toggle="pill"
                (click)="approve($event)"
              >
                <div class="info-box mb-3">
                  <div lass="info-box-content">
                    <span class="info-box-text">Approved</span>
                    <span
                      class="info-box-number"
                      *ngIf="roleId == '100' || roleId == '99'"
                      >{{
                        dashboad && dashboad.accept
                          ? (dashboad && dashboad.accept
                            | number : "1.0" : "en-US")
                          : 0
                      }}</span
                      
                    >
                    <!-- || roleId == '200 temororily hidden for banglore marena -->

                    <span class="info-box-number" *ngIf="roleId == '101'">{{
                      dashboad && dashboad.swimapprove
                        ? (dashboad && dashboad.swimapprove
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-check"></i>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'outdate' }"
                data-toggle="pill"
                (click)="outDate($event)"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Outdated</span>
                    <span
                      class="info-box-number"
                      *ngIf="roleId == '100' || roleId == '99' || roleId == '200'"
                      >{{
                        dashboad && dashboad.outdate
                          ? (dashboad && dashboad.outdate
                            | number : "1.0" : "en-US")
                          : 0
                      }}</span
                    >
                    <span class="info-box-number" *ngIf="roleId == '101'">{{
                      dashboad && dashboad.outdateswim
                        ? (dashboad && dashboad.outdateswim
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-stop-circle"></i>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item" *ngIf="roleId == '101' || roleId == '100' || roleId == '99'">
            <!-- || roleId == '200 temororily hidden for banglore marena -->

              <a
                class="nav-link"
                [ngClass]="{ active: activeclass == 'reject' }"
                data-toggle="pill"
                (click)="reject($event)"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Rejected</span>
                    <span
                      class="info-box-number"
                      *ngIf="roleId == '100' || roleId == '99'"
                      >{{
                        dashboad && dashboad.rejectMarena
                          ? (dashboad && dashboad.rejectMarena
                            | number : "1.0" : "en-US")
                          : 0
                      }}</span
                    >
                     <!-- || roleId == '200 temororily hidden for banglore marena -->
                    <span class="info-box-number" *ngIf="roleId == '101'">{{
                      dashboad && dashboad.rejectSwim
                        ? (dashboad && dashboad.rejectSwim
                          | number : "1.0" : "en-US")
                        : 0
                    }}</span>
                    <span class="info-box-number" *ngIf="!roleId">0 </span>
                  </div>
                  <div _class="info-box-content">
                    <i class="fa fa-times"></i>
                  </div>
                </div>
              </a>
            </li>

            <!-- <li class="nav-item"><a class="nav-link" [ngClass]="{ 'active':activeclass=='refund'}"  data-toggle="pill" (click)="refund($event)">
                                <div class="info-box mb-3">
                                    <div lass="info-box-content"><span class="info-box-text">Refund</span>
                                        <span class="info-box-number">{{dashboad && dashboad.refund ? dashboad && dashboad.refund : 0}}</span>
                                    </div>
                                </div>
                            </a></li> -->

            <!-- <li class="nav-item"><a class="nav-link" data-toggle="pill" (click)="accept($event)">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content"><span class="info-box-text">Accept</span>
                                       <span class="info-box-number">{{dashboad && dashboad.accept}}</span>
                                    </div>
                                </div>
                            </a></li> -->

            <!-- <li class="nav-item"><a class="nav-link" [ngClass]="{ 'active':activeclass=='cancelD'}"  data-toggle="pill" (click)="cancelD($event)">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content"><span class="info-box-text">Cancel</span>
                                       <span class="info-box-number">{{dashboad && dashboad.cancel ? dashboad && dashboad.cancel : 0}}</span>
                                    </div>
                                </div>
                            </a></li> -->
          </ul>
        </div>
        <div class="row">
          <!-- <div class="col-sm-12">
                        <div class="col-sm-9"></div>
                        <div class="col-sm-3 xl_cls">
                            <button (click)="downloadExcel()" class="btn btn-primary btn-sm mb-2" style="margin-right: 10px;">Download Registration</button>
                            <a style="display:none;" #downloadZipLink class="btn btn-primary btn-sm"></a>
                        </div>
                    </div> -->

          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download {{ downloadStatus }}
                    </button>
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >
                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th width="3%">Enrollment No.</th>
                            <th width="10%">Name</th>
                            <th width="10%">Email</th>
                            <th width="3%">Phone No.</th>
                            <!-- <th width="2%">Employee Code</th> -->
                            <th width="5%">Institution Name</th>
                            <th width="1%" *ngIf="ismem">
                              Membership Category
                            </th>
                            <th width="1%" *ngIf="ismem">Membership Period</th>
                            <th width="1%" *ngIf="ismem && roleId == '101'">
                              Health Issue
                            </th>
                            <th width="2%">Login Type</th>
                            <th width="5%" *ngIf="!ismem">Approval Status</th>
                            <th width="5%" *ngIf="rejectedstatus">Reason</th>
                            <th width="1%" *ngIf="ismem">Beneficiary Name</th>
                            <th width="2%" *ngIf="(ismem && roleId == '100')|| (ismem && roleId == '200')">
                              Start Date
                            </th>
                            <th width="2%" *ngIf="(ismem && roleId == '100')||(ismem && roleId == '200')">
                              End Date
                            </th>
                            <th width="2%" *ngIf="(ismem && roleId == '100')||(ismem && roleId == '200')">
                              Total Amount
                            </th>
                            <th width="4%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container
                            *ngFor="
                              let list of register_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <tr
                              [ngClass]="
                                list.cancel_status == 1 &&
                                list.status == 1 &&
                                list.refund_status == 0 &&
                                list.total_amount > 0
                                  ? 'clr_cls'
                                  : ' '
                              "
                            >
                              <td>
                                {{
                                  list.user && list.user.enrollment_Number
                                    ? list.user && list.user.enrollment_Number
                                    : list.enrollment_Number
                                }}
                              </td>
                              <td class="name_cls">
                                {{
                                  list && list.name
                                    ? list && list.name
                                    : list && list.user.name
                                }}
                              </td>
                              <td class="name_cls">
                                {{
                                  list && list.email
                                    ? list && list.email
                                    : list && list.user && list.user.email
                                }}
                              </td>
                              <td>
                                {{
                                  list && list.phone_no
                                    ? list.phone_no
                                    : list && list.user && list.user.phone_no
                                }}
                              </td>
                              <!-- <td>{{list && list.enrollment_Number ? list.enrollment_Number : (list && list.user && list.user.enrollment_Number ? list.user.enrollment_Number : '-')}}</td> -->
                              <td>
                                {{
                                  list && list.institution
                                    ? list.institution
                                    : list && list.user && list.user.institution
                                    ? list.user.institution
                                    : "-"
                                }}
                              </td>

                              <!-- <td *ngIf="list && list.membership != [] && !list.user && ismem">
                                                                <span *ngFor="let mem of list.membership">
                                                                    <span *ngIf="mem.beneficiary == null">
                                                                        <span *ngFor="let cat of categorylist">
                                                                            {{cat.name ? cat.name: '-'}}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                
                                                            </td> -->
                              <td *ngIf="list && list.category != [] && ismem">
                                <span
                                  *ngFor="
                                    let mem of list.category;
                                    let dataLen = index
                                  "
                                >
                                  {{ mem.name }}
                                  <span
                                    *ngIf="
                                      list.category.length > 1 &&
                                      list.category.length - 1 != dataLen
                                    "
                                  >
                                    ,</span
                                  >
                                  <span
                                    *ngIf="list.category.length - 1 == dataLen"
                                  >
                                  </span>
                                </span>
                              </td>

                              <td
                                *ngIf="
                                  list && list.membershipPeriod != [] && ismem
                                "
                              >
                                <span *ngIf="list.membershipPeriod == 1"
                                  >{{
                                    list.membershipPeriod
                                      ? list.membershipPeriod
                                      : list.membershipPeriod
                                  }}
                                  Month</span
                                >
                                <span *ngIf="list.membershipPeriod != 1"
                                  >{{
                                    list.membershipPeriod
                                      ? list.membershipPeriod
                                      : list.membershipPeriod
                                  }}
                                  Months</span
                                >
                              </td>

                              <td *ngIf="ismem && roleId == '101'">
                                {{
                                  list.healthProblem
                                    ? list.healthProblem
                                    : list.healthProblem
                                }}
                              </td>
                              <!-- <td
                                                                *ngIf="list && list.membership == [] && !list.user && ismem">
                                                                -
                                                            </td> -->

                              <!-- <td *ngIf="list.user && ismem">-</td> -->

                              <td *ngIf="!list.user">
                                {{
                                  list.loginType == 3
                                    ? "Others"
                                    : list.loginType == 2
                                    ? "Staff"
                                    : "Student"
                                }}
                              </td>
                              <td *ngIf="list.user">
                                {{
                                  list.user.loginType == 3
                                    ? "Others"
                                    : list.user.loginType == 2
                                    ? "Staff"
                                    : "Student"
                                }}
                              </td>

                              <td *ngIf="list && list.beneficiary && ismem">
                                {{
                                  list.beneficiary.length != 0
                                    ? list.beneficiary[0].name
                                    : "-"
                                }}
                              </td>
                              <td *ngIf="(ismem && roleId == '100')||(ismem && roleId == '200')">
                                {{
                                  list && list.createdAt
                                    ? (list.createdAt | date : "dd/MM/yyyy")
                                    : "-"
                                }}
                              </td>
                              <td *ngIf="(ismem && roleId == '100')||(ismem && roleId == '200')">
                                {{
                                  list && list.validTill
                                    ? (list.validTill | date : "dd/MM/yyyy")
                                    : "-"
                                }}
                              </td>
                              <td *ngIf="(ismem && roleId == '100')||ismem && roleId == '200'">
                                ₹&nbsp;&nbsp;
                                {{
                                  (list && list.amount ? list.amount : "-")
                                    | number
                                }}
                              </td>

                              <td
                                *ngIf="
                                  (roleId == '100' || roleId == '99') && !ismem
                                "
                              >
                                <button
                                  (click)="status($event, list)"
                                  class="btn_cls approve_btn"
                                  *ngIf="list.marenaStatus == 0"
                                >
                                  Approve
                                </button>
                                <button
                                  (click)="rejectstatus($event, list)"
                                  class="btn_cls"
                                  *ngIf="list.marenaStatus == 0"
                                >
                                  Reject
                                </button>
                                <button
                                  class="btn_cls1"
                                  *ngIf="list.marenaStatus == 1"
                                >
                                  Accepted
                                </button>
                                <button
                                  class="btn_cls3"
                                  *ngIf="list.marenaStatus == 3"
                                >
                                  Rejected
                                </button>
                              </td>

                              <td
                              *ngIf="
                                (roleId == '200') && !ismem
                              "
                            >
                              <button
                                (click)="status($event, list)"
                                class="btn_cls approve_btn"
                                *ngIf="list.marenaStatus == 0"
                              >
                                Approve
                              </button>
                              <button
                                (click)="rejectstatus($event, list)"
                                class="btn_cls"
                                *ngIf="list.marenaStatus == 0"
                              >
                                Reject
                              </button>
                              <button
                                class="btn_cls1"
                                *ngIf="list.marenaStatus == 1"
                              >
                                Accepted
                              </button>
                              <button
                                class="btn_cls3"
                                *ngIf="list.marenaStatus == 3"
                              >
                                Rejected
                              </button>
                            </td>

                              <td *ngIf="roleId == '101' && !ismem">
                                <button
                                  (click)="status($event, list)"
                                  class="btn_cls approve_btn"
                                  *ngIf="list.swimmingStatus == 0"
                                >
                                  Approve
                                </button>
                                <button
                                  (click)="rejectstatus($event, list)"
                                  class="btn_cls"
                                  *ngIf="list.swimmingStatus == 0"
                                >
                                  Reject
                                </button>
                                <button
                                  class="btn_cls1"
                                  *ngIf="list.swimmingStatus == 1"
                                >
                                  Accepted
                                </button>
                                <button
                                  class="btn_cls3"
                                  *ngIf="list.swimmingStatus == 3"
                                >
                                  Rejected
                                </button>
                              </td>
                              <td
                                *ngIf="
                                  (roleId == '100' || roleId == '99' || roleId == '200') &&
                                  !ismem &&
                                  rejectedstatus
                                "
                              >
                                {{ list.marenaRejectReason }}
                              </td>
                              <td
                                *ngIf="
                                  roleId == '101' && !ismem && rejectedstatus
                                "
                              >
                                {{ list.swimRejectReason }}
                              </td>
                              <td *ngIf="!list.user && !ismem">
                                <a
                                  [routerLink]="[
                                    '/registration-view',
                                    list._id
                                  ]"
                                >
                                  <i class="fa fa-eye 2x"> </i>
                                </a>

                                <a
                                  *ngIf="activeclass == 'reg'"
                                  [routerLink]="['/edit', list._id]"
                                >
                                  <i class="fa fa-pencil-alt"> </i>
                                </a>
                                <!-- <a (click)="deleteReg($event,list)"> <i
                                                                        class="fa fa-trash-alt"></i>

                                                                </a> -->
                              </td>
                              <td *ngIf="list.user">
                                <a
                                  [routerLink]="[
                                    '/registration-view',
                                    list._id
                                  ]"
                                >
                                  <i class="fa fa-eye 2x"> </i>
                                </a>

                                <a
                                  *ngIf="activeclass == 'reg'"
                                  [routerLink]="['/edit', list._id]"
                                >
                                  <i class="fa fa-pencil-alt"> </i>
                                </a>
                                <a
                                  *ngIf="
                                    activeclass != 'reg' &&
                                    activeclass != 'outdate'
                                  "
                                  (click)="deleteReg($event, list)"
                                >
                                  <i class="fa fa-trash-alt"></i>
                                </a>
                              </td>
                            </tr>
                          </ng-container>
                          <tr *ngIf="!register_list.length">
                            <td colspan="12" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                  <!-- <nav aria-label="..." class="paginate_nav">
                                        <ul class="pagination">
                                            <li class="page-item" [ngClass]="{'disable':cur_page===1}"
                                                (click)="changePage(cur_page-1)">
                                                <a class="page-link" tabindex="-1">Previous</a>
                                            </li>

                                            <li class="page-item active">
                                                <a class="page-link">{{cur_page}}<span
                                                        class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="page-item" [ngClass]="{'disable':cur_page===totalPages}"
                                                (click)="changePage(cur_page+1)">
                                                <a class="page-link">Next</a>
                                            </li>
                                        </ul>
                                    </nav> -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <div
    class="modal fade"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Employee ID</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img [src]="view_image" class="img-responsive" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade reason_modal"
  id="modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Employee ID</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
      <form [formGroup]="updateForm" #myForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">Reason to reject</div>
        <div class="modal-body">
          <!-- <label>Reason to reject</label> -->
          <textarea
            rows="5"
            formControlName="reason"
            (keyup)="txtChangerj($event)"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="reason_submit"
            [disabled]="buttonEnable"
            [ngClass]="{ disable: buttonEnable == true }"
          >
            Submit
          </button>

          <button type="button" class="reason_close" data-dismiss="modal">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
