import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ModalService,
  AuthenticationService,
  MarathonService,
} from "../../_services";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT } from "@angular/common";

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: "site-header",
  templateUrl: "./site-header.component.html",
})
export class SiteHeaderComponent implements OnInit {
  role: any;
  userType: any;
  superAdminUser = false;
  page: string;
  activeclass1: string;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private marathonService: MarathonService
  ) {}
  adminName = localStorage.getItem(`${environment.appName}` + "_adminName");
  roleId = localStorage.getItem(`${environment.appName}` + "_role");
  companyName = localStorage.getItem(`${environment.appName}` + "companyName");
  activeclass = localStorage.getItem(`${environment.appName}` + "activeClass");
  admin_id: any;
  adminClass: any;
  userId = localStorage.getItem(`${environment.appName}` + "_adminId");
  mainAdminId = localStorage.getItem(`${environment.appName}` + `_MainAdminID`);
  ngOnInit() {
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.adminClass = localStorage.getItem(
      `${environment.appName}` + "AdminClass"
    );
    this.superAdminUser = false;
    this.checkUserDetail();
    this.mainAdminId = localStorage.getItem(
      `${environment.appName}` + `_MainAdminID`
    );
    console.log(this.mainAdminId);
  }

  checkUserDetail() {
    this.userId = localStorage.getItem(`${environment.appName}` + "_adminId");

    // this.marathonService.checkUser(this.userId).subscribe(data =>{
    //   if(data.data.status == 0)
    //   {
    //     this.superAdminUser = true;
    //   }
    //   else{
    //     this.superAdminUser = false;
    //   }
    // })
  }
  marena(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "marena");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    localStorage.setItem(`${environment.appName}` + "_role", "100");
    // localStorage.setItem(`${environment.appName}` + '_role') = '100';
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    // window.location.reload();
    // this.router.navigate(['/']);
    // window.location.reload();
    this.router.ngOnDestroy();
    window.location.href = "/";
    this.page = "";
  }

  swim(e) {
    // this.activeclass = '';
    localStorage.setItem(`${environment.appName}` + "activeClass", "swim");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.roleId = "101";
    localStorage.setItem(`${environment.appName}` + "_role", "101");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    // this.router.navigate(['/']);
    // window.location.reload();
    this.router.ngOnDestroy();
    window.location.href = "/";
    this.page = "";
  }

  bangloremarena(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "bangloremarena"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.roleId = "200";
    localStorage.setItem(`${environment.appName}` + "_role", "200");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    // this.router.navigate(['/']);
    // window.location.reload();
    this.router.ngOnDestroy();
    window.location.href = "/";
    this.page = "";
  }

  adhoc(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "adhoc");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'adhoc';
    this.roleId = "103";
    localStorage.setItem(`${environment.appName}` + "_role", "103");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    // this.router.navigate(['/']);
    // window.location.reload();
    this.router.ngOnDestroy();
    window.location.href = "/";
    this.page = "";
  }

  coursera(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "coursera");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'coursera';
    this.roleId = "102";
    localStorage.setItem(`${environment.appName}` + "_role", "102");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/coursera-dashboard"]);
    this.page = "";
    // window.location.reload();
  }

  // almashine(e)
  // {
  //   localStorage.setItem(`${environment.appName}` + 'activeClass', 'almashine');
  //   this.activeclass = localStorage.getItem(`${environment.appName}` + 'activeClass');
  //   this.page="almashine";

  //   window.location.href = '/almashine-home';
  // }

  almashine(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "almashine");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "104";
    localStorage.setItem(`${environment.appName}` + "_role", "104");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/almashine-dashboard"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  almashinePayment(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "almashine");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "104";
    localStorage.setItem(`${environment.appName}` + "_role", "104");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    //this.router.navigate(["/almashine-dashboard"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  courseraPayment(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "coursera");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.roleId = "102";
    localStorage.setItem(`${environment.appName}` + "_role", "102");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.ngOnDestroy();
    this.page = "";
  }

  phd(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "phd-list");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/phd-list";
  }

  conference(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "conference-list"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/conference-list";
  }

  daycare(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "daycare-reg-list"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/daycare-reg-list";
  }

  hostels(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "hostel-list"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/mahe-hostel-reg-list";
  }
  tmapaireg(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "tmapai_reg_list"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/tmapai_reg_list";
  }

  adhikshana(event: any) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "Adhikshana"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/Adhikshana";
  }

  eraya(event: any) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "Falak");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/Falak";
  }

  basketball(event: any) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "basketball"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/basketbal-reg-list";
  }

  mujo(event: any) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "Mujo");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/Mujo";
  }

  solstice(event: any) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "Tech-Solstice-Nexus"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/Tech-Solstice-Nexus";
  }

  chessreg(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "chess_event"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/chess_event";
  }

  agilereg(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "faculty");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/faculty-reg-list";
  }

  reflekta(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "reflekta");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/reflekta-reg-list";
  }

  aley(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "aley");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/aley-reg-list";
  }

  cina(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "cina");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/cina-reg-list";
  }

  vehicle(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "vehicle");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/vehicle-reg-list";
  }

  bazaar(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "bazaar");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/bazaar-mic-reg-list";
  }

  cricket(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "cricket");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/cricket-reg-list";
  }

  tessellate(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "tessellate"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/tessellate-reg-list";
  }

  fdp(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "fdp");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/fdp-reg-list";
  }

  gala(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "gala");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "132";
    localStorage.setItem(`${environment.appName}` + "_role", "132");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/gala-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  kenny(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "kenny");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "133";
    localStorage.setItem(`${environment.appName}` + "_role", "133");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/kenny-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  popcon(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "popcon");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "134";
    localStorage.setItem(`${environment.appName}` + "_role", "134");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/popcon-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  sustain(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "sustain");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "135";
    localStorage.setItem(`${environment.appName}` + "_role", "135");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/sustain-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }

  hacknite(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "hacknite");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "136";
    localStorage.setItem(`${environment.appName}` + "_role", "136");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/hacknite-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }


  arbitrationworkshop(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "arbitrationworkshop");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    // this.activeclass = 'almashine';
    this.roleId = "137";
    localStorage.setItem(`${environment.appName}` + "_role", "137");
    this.roleId = localStorage.getItem(`${environment.appName}` + "_role");
    this.router.navigate(["/arbitration-workshop-reg-list"]);
    // window.location.reload();
    this.router.ngOnDestroy();
    //window.location.href = "/almashine-dashboard";
    this.page = "";
  }
  conferencereg(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "conference"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/conference-reg-list";
  }
  kalareg(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "kala_fest");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/kala_fest";
  }

  iqac(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "iqac-mit");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/iqac-mit";
  }

  TDWFE(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "TDWFE");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/TDWFE";
  }

  law(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "Construction-Law-blr"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/Construction-Law-blr";
  }
  home() {
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    if (
      this.activeclass == "marena" ||
      this.activeclass == "swim" ||
      this.activeclass == "adhoc" ||
      this.activeclass == "coursera"
    ) {
      this.router.ngOnDestroy();
      window.location.href = "/";
    }
  }

  shouldDisplayAlmashineHome() {
    return this.router.url.includes("dashboard");
  }

  shouldDisplayCourseraHome() {
    return this.router.url.includes("dashboard");
  }

  shouldDisplayAlmashineRegistration() {
    return this.router.url.includes("registration-lists");
  }

  shouldDisplayCourseraRegistration() {
    return this.router.url.includes("registration-lists");
  }

  shouldDisplayAlmashinePaymentLogs() {
    return this.router.url.includes("payment-list");
  }

  shouldDisplayCourseraPaymentLogs() {
    return this.router.url.includes("payment-list");
  }

  logout() {
    var that = this;
    alertify.confirm(
      "Logout",
      "Are you sure want to logout?",
      function () {
        that.authenticationService.logout();
      },
      function () {
        that.toastr.error("Logout action cancelled");
      }
    );
  }

  studentlist(e) {
    localStorage.setItem(
      `${environment.appName}` + "activeClass",
      "studentlist"
    );
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/student-user-list";
  }

  stafflist(e) {
    localStorage.setItem(`${environment.appName}` + "activeClass", "stafflist");
    this.activeclass = localStorage.getItem(
      `${environment.appName}` + "activeClass"
    );
    this.router.ngOnDestroy();
    window.location.href = "/staff-user-list";
  }
}
