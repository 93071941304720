import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
@Component({
    templateUrl: 'register_view.component.html',
    styleUrls: ['register_view.component.css']
})
export class RegisterViewComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    beneficiary:any;
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    register = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    activeClick=localStorage.getItem(`${environment.appName}` + 'activeClick');
    category:any;
    ngOnInit() {
console.log(this.activeClick);
        if(this.roleId == '99')
        {
            this.roleId = '100';
        }

        this.activeClick=localStorage.getItem(`${environment.appName}` + 'activeClick');

        if(this.roleId == '200'){
            if(this.activeClick == 'approve' || this.activeClick == 'pending')
            {
              
                this._marathon.getbanglrRegisterView1(this.id,this.roleId).subscribe(data => {
                    this.register = data.data[0];
                }, error => {
                });
    
            }else if(this.activeClick == 'reject'){
                this._marathon.getbanglrRegisterView2(this.id,this.roleId).subscribe(data => {
                    this.register = data.data[0];
                }, error => {
                });
            }
            else{
                this._marathon.getbanglrRegisterView(this.id,this.roleId).subscribe(data => {
                    this.register = data.data.user;
                    console.log(this.register);
                    
                    this.category = data.data;
                    console.log(this.category);
                    
                    this.beneficiary = data.data[0].membership;
                }, error => {
        
                });
            } 
        }

        else{

        if(this.activeClick == 'approve' || this.activeClick == 'pending')
        {
          
            this._marathon.getRegisterView1(this.id,this.roleId).subscribe(data => {
                this.register = data.data[0];
            }, error => {
            });

        }else if(this.activeClick == 'reject'){
            this._marathon.getRegisterView2(this.id,this.roleId).subscribe(data => {
                this.register = data.data[0];
            }, error => {
            });
        }
        else{
            this._marathon.getRegisterView(this.id,this.roleId).subscribe(data => {
                this.register = data.data.user;
                console.log(this.register);
                
                this.category = data.data;
                console.log(this.category);
                
                this.beneficiary = data.data[0].membership;
            }, error => {
    
            });
        } 
    }
    }

    goBack() {
        this.location.back();
    }

    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.register.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e){}
    
}
