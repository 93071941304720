import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
@Component({
    templateUrl: 'payment_view.component.html',
    styleUrls: ['payment_view.component.css']
})
export class PaymentViewComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    register = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    ngOnInit() {
        
        this.roleId = localStorage.getItem(`${environment.appName}` + '_role');
        if(this.roleId == '200'){
            
            this._marathon.getbanglrPaymentView(this.id).subscribe(data => {
                this.register = data.data;
                console.log(this.register);
            }, error => {
    
            });
        }
        else{
        this._marathon.getPaymentView(this.id).subscribe(data => {
            this.register = data.data;
            console.log(this.register);
        }, error => {

        });
    }
        
    }

    goBack() {
        this.location.back();
    }

    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.register.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e){}
    
}
