import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'category_edit.component.html',
    styleUrls: ['category_edit.component.css']
})
export class CategoryEditComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    adminForm: FormGroup;
    types: any
    student = false;
    faculty = false;
    research = false;
    res_data = false;
    //table = false;
    //cancelButton = false;
    // onSubmit=false;

    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,

        private router: Router) { }

    edit = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    startDate: any;
    endDate: any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');


    ngOnInit() {

        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        this.adminForm = this.formBuilder.group({
            index: [{ value: null, disabled: true }],
            _id: [{ value: null, disabled: true }],

            name: [''],
            // three_month_amount: [''],
            year_amount: [''],
            six_months_amount:['0'],
            // six_month_amount: [''],
            current_status: [''],
            // two_month_amount: [''],
            one_month_amount: [''],

            swimming1_name: [''],
            studenthalfyear_fees: [''],
            studentyear_fees: [''],
            staffhalfyear_fees: [''],
            staffyear_fees: [''],
            otherhalfyear_fees: [''],
            otheryear_fees: [''],
            coaching_fees: [''],
            current_status1: [''],

            b_a_halfyear_fees:[''],
            b_a_year_fees:[''],
            b_u_halfyear_fees:[''],
            b_u_year_fees:[''],


            swimming2_name: [''],
            month_fees: [''],
            year_fees: [''],

            b_a_month_fees:[''],
            b_a_year_fees_a:[''],
            b_u_month_fees:[''],
            b_u_year_fees_a:[''],

            current_status2: [''],
        })


        if (this.roleId == '99' || this.roleId == '100') {

            const name = this.adminForm.controls['name'];
            name.setValidators([Validators.required]);
            name.updateValueAndValidity();

            const one_month_amount = this.adminForm.controls['one_month_amount'];
            one_month_amount.setValidators([Validators.required]);
            one_month_amount.updateValueAndValidity();

            // const two_month_amount = this.adminForm.controls['two_month_amount'];
            // two_month_amount.setValidators([Validators.required]);
            // two_month_amount.updateValueAndValidity();

            // const three_month_amount = this.adminForm.controls['three_month_amount'];
            // three_month_amount.setValidators([Validators.required]);
            // three_month_amount.updateValueAndValidity();

            // const six_month_amount = this.adminForm.controls['six_month_amount'];
            // six_month_amount.setValidators([Validators.required]);
            // six_month_amount.updateValueAndValidity();

            const year_amount = this.adminForm.controls['year_amount'];
            year_amount.setValidators([Validators.required]);
            year_amount.updateValueAndValidity();
           
            const current_status = this.adminForm.controls['current_status'];
            current_status.setValidators([Validators.required]);
            current_status.updateValueAndValidity();

            this._marathon.getMareenaCategoryEdit(this.id).subscribe(data => {

                this.edit = data.data[0];
                this.types = this.edit.type;

                console.log(data.data[0].name);
                if(data.data[0].name=='ALL FACILITIES'){
                const six_months_amount = this.adminForm.controls['six_months_amount'];
                six_months_amount.setValidators([Validators.required]);
                six_months_amount.updateValueAndValidity();
                }
    
                
                // this.startDate = this.datePipe.transform(data.data[0].createdAt, 'yyyy-MM-dd')
                // this.endDate = this.datePipe.transform(data.data[0].validTill, 'yyyy-MM-dd')

                this.adminForm.patchValue({
                    index: 1,
                    _id: this.edit._id ? this.edit._id : '-',
                    name: this.edit.name ? this.edit.name : '-',
                    one_month_amount: this.edit.one_month_amount ? this.edit.one_month_amount : 0,
                    // two_month_amount: this.edit.two_month_amount ? this.edit.two_month_amount : '-',
                    // three_month_amount: this.edit.three_month_amount ? this.edit.three_month_amount : '-',
                    // six_month_amount: this.edit.six_month_amount ? this.edit.six_month_amount : '-',
                    year_amount: this.edit.year_amount ? this.edit.year_amount : 0,
                    six_months_amount: this.edit.six_month_amount ? this.edit.six_month_amount : 0,
                    current_status: JSON.stringify(this.edit.status)

                });
            }, error => {

            });
        }
       else if (this.roleId == '200') {

            const name = this.adminForm.controls['name'];
            name.setValidators([Validators.required]);
            name.updateValueAndValidity();

            const one_month_amount = this.adminForm.controls['one_month_amount'];
            one_month_amount.setValidators([Validators.required]);
            one_month_amount.updateValueAndValidity();

            // const two_month_amount = this.adminForm.controls['two_month_amount'];
            // two_month_amount.setValidators([Validators.required]);
            // two_month_amount.updateValueAndValidity();

            // const three_month_amount = this.adminForm.controls['three_month_amount'];
            // three_month_amount.setValidators([Validators.required]);
            // three_month_amount.updateValueAndValidity();

            // const six_month_amount = this.adminForm.controls['six_month_amount'];
            // six_month_amount.setValidators([Validators.required]);
            // six_month_amount.updateValueAndValidity();

            const year_amount = this.adminForm.controls['year_amount'];
            year_amount.setValidators([Validators.required]);
            year_amount.updateValueAndValidity();
           
            const current_status = this.adminForm.controls['current_status'];
            current_status.setValidators([Validators.required]);
            current_status.updateValueAndValidity();

            this._marathon.getbanglrMareenaCategoryEdit(this.id).subscribe(data => {

                this.edit = data.data[0];
                this.types = this.edit.type;

                console.log(data.data[0].name);
                if(data.data[0].name=='ALL FACILITIES'){
                const six_months_amount = this.adminForm.controls['six_months_amount'];
                six_months_amount.setValidators([Validators.required]);
                six_months_amount.updateValueAndValidity();
                }
    
                
                // this.startDate = this.datePipe.transform(data.data[0].createdAt, 'yyyy-MM-dd')
                // this.endDate = this.datePipe.transform(data.data[0].validTill, 'yyyy-MM-dd')

                this.adminForm.patchValue({
                    index: 1,
                    _id: this.edit._id ? this.edit._id : '-',
                    name: this.edit.name ? this.edit.name : '-',
                    one_month_amount: this.edit.one_month_amount ? this.edit.one_month_amount : 0,
                    // two_month_amount: this.edit.two_month_amount ? this.edit.two_month_amount : '-',
                    // three_month_amount: this.edit.three_month_amount ? this.edit.three_month_amount : '-',
                    // six_month_amount: this.edit.six_month_amount ? this.edit.six_month_amount : '-',
                    year_amount: this.edit.year_amount ? this.edit.year_amount : 0,
                    six_months_amount: this.edit.six_month_amount ? this.edit.six_month_amount : 0,
                    current_status: JSON.stringify(this.edit.status)

                });
            }, error => {

            });
        }
         else if (this.roleId == '101') {
            this._marathon.getSwimmingCategoryEdit(this.id).subscribe(data => {

                this.edit = data.data[0];
                this.types = this.edit.type;
                console.log(this.edit);
                if (this.types == '1') {

                    const swimming1_name = this.adminForm.controls['swimming1_name'];
                    swimming1_name.setValidators([Validators.required]);
                    swimming1_name.updateValueAndValidity();

                    const studenthalfyear_fees = this.adminForm.controls['studenthalfyear_fees'];
                    studenthalfyear_fees.setValidators([Validators.required]);
                    studenthalfyear_fees.updateValueAndValidity();

                    const studentyear_fees = this.adminForm.controls['studentyear_fees'];
                    studentyear_fees.setValidators([Validators.required]);
                    studentyear_fees.updateValueAndValidity();

                    const staffhalfyear_fees = this.adminForm.controls['staffhalfyear_fees'];
                    staffhalfyear_fees.setValidators([Validators.required]);
                    staffhalfyear_fees.updateValueAndValidity();

                    const staffyear_fees = this.adminForm.controls['staffyear_fees'];
                    staffyear_fees.setValidators([Validators.required]);
                    staffyear_fees.updateValueAndValidity();

                    const otherhalfyear_fees = this.adminForm.controls['otherhalfyear_fees'];
                    otherhalfyear_fees.setValidators([Validators.required]);
                    otherhalfyear_fees.updateValueAndValidity();

                    const otheryear_fees = this.adminForm.controls['otheryear_fees'];
                    otheryear_fees.setValidators([Validators.required]);
                    otheryear_fees.updateValueAndValidity();

                    const coaching_fees = this.adminForm.controls['coaching_fees'];
                    coaching_fees.setValidators([Validators.required]);
                    coaching_fees.updateValueAndValidity();

                    const current_status1 = this.adminForm.controls['current_status1'];
                    current_status1.setValidators([Validators.required]);
                    current_status1.updateValueAndValidity();

                    this.adminForm.patchValue({
                        index: 1,
                        _id: this.edit._id ? this.edit._id : '-',
                        swimming1_name: this.edit.name ? this.edit.name : '-',

                        studenthalfyear_fees: this.edit.studenthalfyear_fees ? this.edit.studenthalfyear_fees : '-',
                        studentyear_fees: this.edit.studentyear_fees ? this.edit.studentyear_fees : '-',
                        staffhalfyear_fees: this.edit.staffhalfyear_fees ? this.edit.staffhalfyear_fees : '-',
                        staffyear_fees: this.edit.staffyear_fees ? this.edit.staffyear_fees : '-',

                        b_a_halfyear_fees:this.edit.b_a_halfyear_fees ? this.edit.b_a_halfyear_fees : '',
                        b_a_year_fees:this.edit.b_a_year_fees ? this.edit.b_a_year_fees : '',
                        b_u_halfyear_fees:this.edit.b_u_halfyear_fees ? this.edit.b_u_halfyear_fees : '',
                        b_u_year_fees:this.edit.b_u_year_fees ? this.edit.b_u_year_fees : '',
            


                        otherhalfyear_fees: this.edit.otherhalfyear_fees ? this.edit.otherhalfyear_fees : '-',
                        otheryear_fees: this.edit.otheryear_fees ? this.edit.otheryear_fees : '-',
                        coaching_fees: this.edit.coaching_fees ? this.edit.coaching_fees : '-',
                        current_status1: JSON.stringify(this.edit.status)

                    });
                }
                else if (this.types == '2' || this.types == '3') {


                    const swimming2_name = this.adminForm.controls['swimming2_name'];
                    swimming2_name.setValidators([Validators.required]);
                    swimming2_name.updateValueAndValidity();

                    const month_fees = this.adminForm.controls['month_fees'];
                    month_fees.setValidators([Validators.required]);
                    month_fees.updateValueAndValidity();

                    const year_fees = this.adminForm.controls['year_fees'];
                    year_fees.setValidators([Validators.required]);
                    year_fees.updateValueAndValidity();

                    const current_status2 = this.adminForm.controls['current_status2'];
                    current_status2.setValidators([Validators.required]);
                    current_status2.updateValueAndValidity();

                    this.adminForm.patchValue({
                        index: 1,
                        _id: this.edit._id ? this.edit._id : '',
                        swimming2_name: this.edit.name ? this.edit.name : '',
                        month_fees: this.edit.month_fees ? this.edit.month_fees : '',
                        year_fees: this.edit.year_fees ? this.edit.year_fees : '',

                        b_a_month_fees:this.edit.b_a_month_fees ? this.edit.b_a_month_fees : '',
                        b_a_year_fees_a:this.edit.b_a_year_fees ? this.edit.b_a_year_fees : '',
                        b_u_month_fees:this.edit.b_u_month_fees ? this.edit.b_u_month_fees : '',
                        b_u_year_fees_a:this.edit.b_u_year_fees ? this.edit.b_u_year_fees : '',

                        current_status2: JSON.stringify(this.edit.status)
                    });
                }

            }, error => {

            });
        }





    }

    goBack() {
        this.location.back();
    }

    get f() { return this.adminForm.controls; }


    onSubmit(form, formWrap) {

        this.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;
        console.log(this.adminForm.value);
        if (this.adminForm.invalid) {
            var that = this;
            that.toastr.error('Please enter all fields');
            return;
        }

        var that = this;

        if (that.id) {
            alertify.confirm('Update', 'Are you sure want to update this record', function () {

                if (that.roleId == '99' || that.roleId == '100') {
                    that._marathon.marenaCategoryUpdate(that.id,
                        that.f.name.value,
                        that.f.one_month_amount.value,
                        // that.f.two_month_amount.value,
                        // that.f.three_month_amount.value,
                        // that.f.six_month_amount.value,
                        that.f.year_amount.value,
                        that.f.six_months_amount.value,
                        that.f.current_status.value,
                    ).subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.toastr.success(data.status.message)
                                that.router.navigate(['/category-list']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });

                }
               else if (that.roleId == '200') {
                    that._marathon.banglrmarenaCategoryUpdate(that.id,
                        that.f.name.value,
                        that.f.one_month_amount.value,
                        // that.f.two_month_amount.value,
                        // that.f.three_month_amount.value,
                        // that.f.six_month_amount.value,
                        that.f.year_amount.value,
                        that.f.six_months_amount.value,
                        that.f.current_status.value,
                    ).subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.toastr.success(data.status.message)
                                that.router.navigate(['/category-list']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });

                }

                else if (that.roleId == '101') {

                    // console.log();
                    // b_a_halfyear_fees:[''],
                    // b_a_year_fees:[''],
                    // b_u_halfyear_fees:[''],
                    // b_u_year_fees:[''],
                    if (that.types == '1') {
                        that._marathon.swimmingCategoryUpdate(that.id,
                            that.f.swimming1_name.value,
                            that.f.studenthalfyear_fees.value,
                            that.f.studentyear_fees.value,
                            that.f.staffhalfyear_fees.value,
                            that.f.staffyear_fees.value,
                            that.f.otherhalfyear_fees.value,
                            that.f.otheryear_fees.value,

                            that.f.b_a_halfyear_fees.value,
                            that.f.b_a_year_fees.value,
                            that.f.b_u_halfyear_fees.value,
                            that.f.b_u_year_fees.value,


                            that.f.coaching_fees.value,
                            0,
                            0,

                            0,
                            0,
                           


                            that.f.current_status1.value,
                        ).subscribe(
                            data => {
                                if (data.status.code == 0) {
                                    that.loading = true;
                                    that.toastr.success(data.status.message)
                                    that.router.navigate(['/category-list']);
                                } else {
                                    document.body.classList.remove('jw-modal-open');
                                    that.loading = false;
                                    that.toastr.error(data.status.message)
                                }
                            },
                            error => {
                                that.toastr.error(error);
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                            });
                    } else if (that.types == '2' || that.types == '3') {
// b_a_month_fees:[''],
//             b_a_year_fees_a:[''],
//             b_u_month_fees:[''],
//             b_u_year_fees_a:[''],
console.log(that.f.b_a_month_fees.value);
                        that._marathon.swimmingCategoryUpdate(that.id,
                            // that.f.swimming2_name.value,
                            // that.f.one_month_amount.value,
                            // that.f.two_month_amount.value,
                            // that.f.three_month_amount.value,
                            // that.f.six_month_amount.value,
                            // that.f.year_amount.value,
                            // that.f.current_status.value,
                            that.f.swimming2_name.value,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,

                            0,
                            that.f.b_a_year_fees_a.value,
                            0,
                            that.f.b_u_year_fees_a.value,



                            0,
                            that.f.month_fees.value,
                            that.f.year_fees.value,

                            that.f.b_a_month_fees.value,
                           
                            that.f.b_u_month_fees.value,
                           


                            that.f.current_status2.value,
                        ).subscribe(
                            data => {
                                if (data.status.code == 0) {
                                    that.loading = true;
                                    that.toastr.success(data.status.message)
                                    that.router.navigate(['/category-list']);
                                } else {
                                    document.body.classList.remove('jw-modal-open');
                                    that.loading = false;
                                    that.toastr.error(data.status.message)
                                }
                            },
                            error => {
                                that.toastr.error(error);
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                            });
                    }


                }

            }, function () {
                that.toastr.error('Update Action Cancelled')
            });
        }

    }

    submit() { }


    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.edit.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e) {
        e.stopPropagation();
        this.router.navigate(['/category-list']);
    }

    dateee(e) {
        console.log(e);
    }
}
