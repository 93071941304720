<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Payment List</li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>

    <section class="content">
        <div class="container-fluid page_section">
            <div class="row">
                <div class="col-md-6">
                    <!-- Panel Kitchen Sink -->
                    <div class="panel">
                        <header class="panel-heading">
                            <h3 class="panel-title">
                                Bulk Mail
                            </h3>
                        </header>
                        <div class="panel-body">

                            <form [formGroup]="sendForm" (ngSubmit)="sendSubmit()">
                                <div class="col-md-12">
                                    <label> Subject </label>
                                    <input autocomplete="off" type="text" class="form-control" formControlName="subject"
                                        [ngClass]="{ 'is-invalid': (f.subject.touched || submitted) && f.subject.errors }"
                                        placeholder="Subject">
                                    <div *ngIf="(f.subject.touched || submitted) && f.subject.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.subject.errors?.required">Subject is required</div>
                                    </div>

                                    <label> Attachment </label>
                                    <input autocomplete="off" type="file" class="form-control"
                                        formControlName="attachment"
                                        [ngClass]="{ 'is-invalid': (f.attachment.touched || submitted) && f.attachment.errors }"
                                        placeholder="Attachment"
                                        (change)="handleFileInput($event, $event.target.files)">

                                    <label>Content</label>
                                    <!--  <angular-editor formControlName="content" id="editor2" [(ngModel)]="htmlContent"
                                        aria-placeholder="Enter text here..." [config]="editorConfig">
                                    </angular-editor> -->
                                    <angular-editor id="editor2" aria-placeholder="Enter text here..."
                                        formControlName="content" [config]="config"></angular-editor>
                                    <div *ngIf="(f.content.touched || submitted) && f.content.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.content.errors?.required">Content is required</div>
                                    </div>
                                    <button type="submit" class="btn btn-info mt-4">Send</button>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <div class="col-md-6 new_s">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tb-wrapper">
                                        <table cellspacing="0" id="table"
                                            class="table table-hover dataTable table-striped w-full" datatable
                                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th width="8%">Sl No.</th>
                                                    <th width="20%">Subject</th>
                                                    <th width="20%">Date</th>
                                                    <th width="10%">Sent Count</th>
                                                    <th width="10%">View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of send$;let j=index">
                                                    <td>{{j+1}}</td>
                                                    <td>{{data.subject}}</td>
                                                    <td>{{data.date}}</td>
                                                    <td>{{data.sent}}</td>
                                                    <td>
                                                        <a (click)="viewPopup($event, data)"> <i
                                                                class="fa fa-eye 2x">
                                                            </i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>

    <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Mail Content</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <span [innerHtml]="content"></span>
                </div>
             
            </div>
        </div>
    </div>
</div>