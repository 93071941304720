import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'quickpay_category_list.component.html',
    styleUrls: ['quickpay_category_list.component.css']
})
export class QuickPayCategoryListComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    student_list = [];
    // totalcount: any;
    category: any;
    
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    activeclass = "active_student";


    // @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

    pagevalue: any;


    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        // this.loading = true;
        this.loading = false;

    }
    ngOnInit() {
        if (!(this.roleId == '103')) {
            this.router.navigate(['/'])
        }

        localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

        //    this.roleId = '100';
        // this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
        //     this.category = data.data;
        // })
        this.refresh(true);


    }

    inactiveStudent(e) {

        e.stopPropagation();

        this.activeclass = 'inactive_student';
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'inactive_student');

        this.student_list = [];



        this.loading = true;

        this._marathon.getExpiredQuickCategoryList(1, this.search_txt).subscribe(data => {

            this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                this.category = data.data;
                this.loading = false;
            })

            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'inactive_student';

        }, error => {
        });
    }


    active_student(e) {
        e.stopPropagation();

        this.activeclass = "active_student";


        localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

        this.cur_page = 1;

        this.student_list = [];



        this.loading = true;

        this._marathon.getOngoingQuickCategoryList(1, this.search_txt).subscribe(data => {

            this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                this.category = data.data;
                this.loading = false;
            })

            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'active_student';


        }, error => {
        });


    }



    refresh(reset = false) {

        this.activeclass = "active_student";


        this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
            this.category = data.data;
        })



        this.loading = true;


        this._marathon.getOngoingQuickCategoryList(this.cur_page, this.search_txt).subscribe(data => {

            this.loading = false;
            this.student_list = data.data.docs;
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'active_student';


        }, error => {
        });

    }


    search(e) {
        e.stopPropagation();
        // this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
        //     this.category = data.data;
        // })
        this.search_txt = e.target.value;

        this.changePage(1);

        this.loading=true;
        //   this.refresh();
        if (this.pagevalue == 'active_student') {

            var that = this;
            that._marathon.getOngoingQuickCategoryList(this.cur_page, this.search_txt).subscribe(data => {
                that.student_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.pagevalue = 'active_student';
                that._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    that.category = data.data;
                    this.loading=false;
                })
            }, error => {
            });

        }

        else if (this.pagevalue == 'inactive_student') {
            this.student_list = [];


            this._marathon.getExpiredQuickCategoryList(this.cur_page, this.search_txt).subscribe(data => {
                this.student_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
                this.totalPages = data.data.totalPages;
                this.pagevalue = 'inactive_student';

                this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    this.category = data.data;
                    this.loading=false;
                })
            }, error => {
            });


        }
        else {
            this.refresh();
        }

    }

    changePage(page) {

        this.cur_page = page;
        console.log(this.pagevalue);

        if (this.pagevalue == 'active_student') {

            // this.refresh();
            var that = this;


            that._marathon.getOngoingQuickCategoryList(that.cur_page, that.search_txt).subscribe(data => {
                that.student_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.pagevalue = 'active_student';
                that._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    that.category = data.data;
                })
            }, error => {
            });

        }
        else if (this.pagevalue == 'inactive_student') {
            this.student_list = [];



            this._marathon.getExpiredQuickCategoryList(this.cur_page, this.search_txt).subscribe(data => {
                this.student_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
                this.totalPages = data.data.totalPages;
                this.pagevalue = 'inactive_student';

                this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    this.category = data.data;
                })
            }, error => {
            });


        }
        else {
            this.refresh();
        }
    }


    refesh2()
    {
        if (this.pagevalue == 'active_student') {

            // this.refresh();
            var that = this;


            that._marathon.getOngoingQuickCategoryList(that.cur_page, that.search_txt).subscribe(data => {
                that.student_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.pagevalue = 'active_student';
                that._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    that.category = data.data;
                })
            }, error => {
            });

        }
        else if (this.pagevalue == 'inactive_student') {
            this.student_list = [];



            this._marathon.getExpiredQuickCategoryList(this.cur_page, this.search_txt).subscribe(data => {
                this.student_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
                this.totalPages = data.data.totalPages;
                this.pagevalue = 'inactive_student';

                this._marathon.getQuickCategoryListStatusCount().subscribe(data => {
                    this.category = data.data;
                })
            }, error => {
            });


        }
        else {
            this.refresh();
        }
    }

    resetFilter(e) {
        this.search_txt = '';
        $('#search1').val('');
        this.refresh(true);
    }

    deleteQuickPayCategory(id)
    {
        var that = this;
        alertify.confirm('Delete', 'Are you sure want to delete', function () {


            that._marathon.deleteQuickCategoryList(id).subscribe(data => {

                if(data.status.code == 0)
                {
                    that.toastr.success(data.status.message)
                    that.refesh2();
                }
                else{
                    that.toastr.error(data.status.message)
                }
                
            })
        }
        , function () {
            that.toastr.error('Delete action cancelled')
        }).set('movable', false)
    }



}



