import { Component, OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import {
  AuthenticationService,
  MarathonService,
  ModalService,
  SettingsService,
} from "../_services";
import { environment } from "../../environments/environment";
import { data } from "jquery";

declare var jquery: any;
declare var $: any;
@Component({
  templateUrl: "home.component.html",
  styleUrls: ["home.component.css"],
})
export class HomeComponent implements OnInit {
  loginForm: FormGroup;

  loading = false;
  submitted = false;
  dashboard = null;
  amount: any;
  payment: any;
  homecount: any;
  roleId = localStorage.getItem(`${environment.appName}` + "_role");

  constructor(
    private setting: SettingsService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private _marathon: MarathonService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.roleId == "102") {
      this._marathon.getCourseraDash().subscribe((data) => {
        this.homecount = data.data;
      });
    } else if (this.roleId == "104") {
      this._marathon.getAlmashineDash().subscribe((data) => {
        this.homecount = data.data;
      });
    }
      else if (this.roleId == "200") {
        this._marathon.getbanglrhomeDash().subscribe((data) => {
          this.homecount = data.data;
        });
    } else {
      this._marathon.gethomeDash().subscribe((data) => {
        this.homecount = data.data;
      });

      //based on role id  banglrmarena get the data
      
    }

    // this._marathon.getDashboard().subscribe(data => {
    //     this.payment = data.data.payment;
    // })

    // this._marathon.getAmount().subscribe(data => {
    //     this.amount = data.data && data.data.docs[0] && data.data.docs[0].total;
    // })
  }
}
