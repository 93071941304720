import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { MarathonService } from '../_services';
declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-bulk_mail',
  templateUrl: './bulk_mail.component.html',
  styleUrls: ['./bulk_mail.component.css']
})

export class BulkMailComponent implements OnInit {

  submitted = false;

  cancelButton = false;

  loading = false;

  send$: Observable<any[]>;
  dtOptions: any= {};
  dtTrigger: Subject<any> = new Subject();
  content:any;
  sendForm: FormGroup;

  fileToUpload: File = null;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',

    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],

    sanitize: true,
    toolbarPosition: 'top',
    uploadWithCredentials: false,
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode', 'backgroundColor']
    ]
  }

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private pro: MarathonService) { }

  ngOnInit() {

    this.sendForm = this.formBuilder.group({
      id: [''],
      content: ['', [Validators.required]],
      subject: ['', [Validators.required, Validators.maxLength(500)]],
      attachment: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Subject"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.pro.getSent().subscribe(data => {
      this.send$ = data.data;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

  }

  viewPopup(e,maildata){
    $('#exampleModalCenter').modal('show');
    this.content = maildata.content;
  }

  get f() { return this.sendForm.controls; }
  handleFileInput(event: any, files: FileList) {

    var that = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = new Image;
      if (files[0].size > 2000000) {
        $('[type="file"]').val('');
        that.toastr.error('File size must be less than 2MB');
        return false;
      } else {
        that.fileToUpload = files.item(0);
        img.src = reader.result as string;
      }

    };

    reader.readAsDataURL(files.item(0));

  }
  sendSubmit() {
    this.submitted = true;
    var that = this;
    if (this.sendForm.invalid) {
      return;
    }
    alertify.confirm('Confirm', 'Are you sure want to send mail?', function () {
      that.loading = true;
      that.pro.submitEmail(that.f.subject.value, that.f.content.value, that.fileToUpload).subscribe(data => {
        that.submitted = false;
        that.cancelClick();
        if (data.status.code == 0) {
          that.fileToUpload = null;
          that.toastr.success('Successfully sent');
          that.submitted = false;
          that.loading = false;
          $("#table").dataTable().fnDestroy()
          that.ngOnInit();
          // location.reload();
        } else {
          that.loading = false;
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
        that.submitted = false;
        that.loading = false;
      });
    }, function () {
      that.loading = false;
      // that.toastr.error('Action cancelled');
    });
  }




  cancelClick() {
    // $('.new_s').removeClass('disable');
    this.submitted = false;
    this.sendForm.patchValue({
      id: '',
      subject: '',
      content: '',
      attachment: ''
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
