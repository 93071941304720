<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading
  [show]="loading"
  class="loader"
  [template]="customLoadingTemplate"
></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
            <li *ngIf="roleId == '104'" class="breadcrumb-item active">
              Almashine Registration List
            </li>
            <li *ngIf="roleId == '102'" class="breadcrumb-item active">
              Coursera Registration List
            </li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                (keyup.enter)="search($event)"
                placeholder="Search Enrollment No, Name, Email, Phone No."
                autocomplete="off"
              />
            </div>
            <div class="col-sm-1">
              <div class="reset_btn">
                <button
                  (click)="resetFilter($event)"
                  class="btn btn-secondary btn-sm"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="order_tabs">
          <ul class="nav nav-pills nav_flex">
            <li class="nav-item">
              <a
                class="nav-link"
                [ngClass]="{ active: tab == 'registration' }"
                (click)="menu('registration')"
                data-toggle="pill"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Registration</span>

                    <span class="info-box-number">{{ regtotalcount }}</span>
                    <!-- <span class="info-box-number" *ngIf="!roleId">0
                                        </span> -->
                  </div>

                  <div _class="info-box-content">
                    <i class="fa fa-list"></i>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item" *ngIf="roleId == '104'">
              <a
                class="nav-link"
                [ngClass]="{ active: tab == 'reapply' }"
                (click)="menu('reapply')"
                data-toggle="pill"
              >
                <div class="info-box mb-3">
                  <div _class="info-box-content">
                    <span class="info-box-text">Postal Reapply</span>

                    <span class="info-box-number">{{ reapplytotalcount }}</span>
                    <!-- <span class="info-box-number" *ngIf="!roleId">0
                                    </span> -->
                  </div>

                  <div _class="info-box-content">
                    <i class="fa fa-list"></i>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill"> </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill"> </a>
            </li>

            <li class="dwnld_imgregistn allimg_btun" *ngIf="roleId == '104'">
              <button (click)="downloadImages()" class="btn btn-primary">
                Download All Images
              </button>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download Registration
                    </button>

                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >
                    <div class="table-responsive">
                      <table
                        class="table table-hover dataTable admin_table"
                        style="width: 3000px"
                      >
                        <thead>
                          <tr>
                            <!-- <th width="10%">Reg. ID</th> -->
                            <th *ngIf="roleId == '104'" width="80px">Image</th>
                            <th width="160px">Register Number</th>
                            <th width="250px">Name</th>
                            <th width="120px">Email</th>
                            <th width="150px">Mobile Number</th>
                            <th width="300px">Institution</th>
                            <th width="300px">Branch</th>
                            <th width="400px">Address</th>
                            <th width="80px">Pincode</th>
                            <th width="160px">City</th>
                            <th width="160px">State</th>
                            <th *ngIf="roleId == '104'" width="160px">
                              Collect Card
                            </th>
                            <th width="160px">Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container
                            *ngFor="
                              let list of student_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <tr>
                              <td
                                *ngIf="list.alumni_id_image && roleId == '104'"
                                (click)="viewImage($event, list)"
                                style="cursor: pointer"
                                class="table_image"
                              >
                                <img
                                  src="{{ media_domain }}{{
                                    list.alumni_id_image
                                  }}"
                                  width="80px !important;"
                                  height="60px"
                                />
                                <i class="fa fa-eye 2x"></i>
                              </td>
                              <td>
                                {{
                                  list && list.register_no
                                    ? list.register_no
                                    : "-"
                                }}
                              </td>
                              <td class="name_cls">
                                {{
                                  list && list.alumni_name
                                    ? list.alumni_name
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{ list && list.email ? list.email : "-" }}
                              </td>
                              <td>
                                {{ list && list.phone_no ? list.phone_no : "-"
                                }}<i
                                  class="fa fa-pencil-alt"
                                  aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#myModal6"
                                  (click)="viewphone($event, list)"
                                ></i>
                              </td>
                              <td>
                                {{
                                  list && list.institution
                                    ? list.institution
                                    : "-"
                                }}
                              </td>
                              <td>
                                {{ list && list.branch ? list.branch : "-" }}
                              </td>
                              <td>
                                {{ list && list.address ? list.address : "-" }}
                              </td>
                              <td>
                                {{ list && list.pincode ? list.pincode : "-" }}
                              </td>
                              <td>{{ list && list.city ? list.city : "-" }}</td>
                              <td>
                                {{ list && list.state ? list.state : "-" }}
                              </td>

                              <td
                                *ngIf="
                                  list.collect_card == 'inPerson' &&
                                  roleId == '104'
                                "
                              >
                                In Person
                              </td>
                              <td
                                *ngIf="
                                  list.collect_card == 'courier' &&
                                  roleId == '104'
                                "
                              >
                                Courier
                              </td>

                              <td>
                                {{
                                  list && list.paid_amount
                                    ? list.paid_amount
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </ng-container>
                          <tr *ngIf="!student_list.length">
                            <td colspan="9" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>

  <div
    class="modal fade status_modal"
    id="myModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" (click)="closeImage($event)">×</span>
          </button>
          <div class="status_box flt">
            <!-- <form [formGroup]="updateForm" #myForm="ngForm" (ngSubmit)="onSubmit()"> -->
            <label>Image</label>
            <!-- <div>{{media_domain}}{{profileimage}}</div> -->
            <div *ngIf="profileimage" class="profile_image">
              <img src="{{ media_domain }}{{ profileimage }}" />
            </div>
            <!-- <div *ngIf="!profileimage" class="profile_image"><img  src="assets/images/defaultp.png" alt="image"></div> -->
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Year of Passing-->
  <div
    class="modal fade"
    id="myModal6"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="status_box flt">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" (click)="closephone($event)">×</span>
            </button>
            <form
              [formGroup]="updateForm"
              #myForm="ngForm"
              (keyup)="mobilenumber()"
              (ngSubmit)="onSubmitMobile()"
            >
              <label>Edit Mobile Number</label>
              <input
                formControlName="phone"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="10"
              />
              <!-- <div > -->
              <button type="submit" class="update">Update</button>
              <!-- </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
