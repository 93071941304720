<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <site-header></site-header> -->

<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header breadcrumb_area">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
            <li class="breadcrumb-item active">Hackathon</li>
          </ol>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid page_section">
      <div class="serach_sec">
        <div class="row">
          <div class="col-sm-12">
            <div class="order-head">Search</div>
          </div>
        </div>
        <div class="search_cls">
          <div class="row">
            <div class="col-sm-4">
              <input
                type="Search"
                name="search1"
                id="search1"
                class="search1"
                (keyup.enter)="search($event)"
                autocomplete="off"
                placeholder="Search Team Lead Name, Project Name, Email"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="order_tabs">
        <div class="row">
          <div class="col-sm-12">
            <div class="order_tabs">
              <div class="tab-content">
                <div class="tab-pane active" id="menu1">
                  <div class="order_list">
                    <button
                      (click)="downloadExcel()"
                      class="btn btn-primary btn-sm mb-2"
                      style="margin-right: 10px"
                    >
                      Download
                    </button>
                    <a
                      style="display: none"
                      #downloadZipLink
                      class="btn btn-primary btn-sm"
                    ></a>
                    <span class="total_count"
                      >Total Count :
                      {{
                        totalcount ? (totalcount | number : "1.0" : "en-US") : 0
                      }}</span
                    >

                    <div class="table-responsive">
                      <table
                        class="table"
                        class="table table-hover dataTable admin_table"
                      >
                        <thead>
                          <tr>
                            <th width="15%">Team Lead Name</th>
                            <th width="10%">Department</th>
                            <th width="10%">Institute</th>
                            <th width="10%">Contact No</th>
                            <th width="10%">Email ID</th>
                            <th width="10%">Project Name</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            *ngFor="
                              let list of register_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            "
                          >
                            <td>
                              {{
                                list.team_lead_name ? list.team_lead_name : "-"
                              }}
                            </td>
                            <td>
                              {{ list.department ? list.department : "-" }}
                            </td>
                            <td>{{ list.institute ? list.institute : "-" }}</td>
                            <td>
                              {{
                                list.contact_number ? list.contact_number : "-"
                              }}
                            </td>

                            <td>{{ list.email_id ? list.email_id : "-" }}</td>

                            <td>
                              {{ list.project_name ? list.project_name : "-" }}
                            </td>
                          </tr>
                          <tr *ngIf="!register_list.length">
                            <td colspan="11" class="text-center">
                              No Results Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div class="paginate">
                    <p>
                      Showing {{ from }} to {{ cur_page * 10 }} of
                      {{ totalcount }} entries
                    </p>
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="changePage($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</div>
