import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class MarathonService {
  constructor(private http: HttpClient) { }
  roleId = localStorage.getItem(`${environment.appName}` + "_role");

  getStudent(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl1}${environment.apiPrefix}/student`)
      .pipe(map((user) => user));
  }

  getStaff(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl1}${environment.apiPrefix}/staff`)
      .pipe(map((user) => user));
  }

  getDownload(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excel?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrMarathonDownload(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/download-excel?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }


  getDownloadUser(search: any): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference/downloadexcel-users?search=` +
        search,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDashboard(type: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/dashboard?type=` +
        type
      )
      .pipe(map((user) => user));
  }

  getbangloreDashboard(type: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/dashboard?type=` +
        type
      )
      .pipe(map((user) => user));
  }

  getDownloadswim(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadpending(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelpending?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrDownloadpending(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/download-excelpending?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadpendingswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelpendingswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadoutdate(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-exceloutdate?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrDownloadoutdate(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/download-exceloutdate?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadoutdateswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-exceloutdateswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadapprove(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelapprove?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrDownloadapprove(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/download-excelapprove?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadapproveswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelapproveswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadreject(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    // http://localhost:3000/api/v1/admin/download-excelrejectmarena?page=1&search=&category=null&tshirt=null&attendee_type=null
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelrejectmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrDownloadreject(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    // http://localhost:3000/api/v1/admin/download-excelrejectmarena?page=1&search=&category=null&tshirt=null&attendee_type=null
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/download-excelrejectmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getDownloadrejectswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Promise<Blob> {
    // http://localhost:3000/api/v1/admin/download-excelrejectswim?page=1&search=&category=null&tshirt=null&attendee_type=null
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-excelrejectswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getBeneficiary(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getBeneficiary/` +
        id
      )
      .pipe(map((user) => user));
  }

  getUsersingle(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/userlist/` + id
      )
      .pipe(map((user) => user));
  }

  getadhocSingle(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/adhoc/` + id
      )
      .pipe(map((user) => user));
  }

  getUserdropdown(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/userlist`)
      .pipe(map((user) => user));
  }

  getUserlist(page, search, category, tshirt, attendee_type): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration-list?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getoutdated(page, search, category, tshirt, attendee_type): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getOutdatedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglroutdated(page, search, category, tshirt, attendee_type): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getOutdatedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getoutdatedswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getOutdatedswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getapproveswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getApprovedswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }
  getrejectswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getRejectedswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getUserlistpending(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getPendingmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getUserlistapproved(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getApprovedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglrUserlistapproved(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getApprovedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }
  getUserlistrejected(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getRejectedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglrUserlistrejected(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getRejectedmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getMarathon(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration-list?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period
      )
      .pipe(map((user) => user));
  }

  getbanglrMarathon(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/registration-list?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period
      )
      .pipe(map((user) => user));
  }

  getSwim(
    page,
    search,
    category,
    tshirt,
    attendee_type,
    membership_period
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration-listswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type +
        `&membership_period=` +
        membership_period
      )
      .pipe(map((user) => user));
  }

  getPayment(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getpayment?page=` +
        page +
        "&search=" +
        search +
        "&roleId=" +
        roleId +
        "&date=" +
        filter_date +
        "&status=" +
        filter_status
      )
      .pipe(map((user) => user));
  }


  getbanglrPayment(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getpayment?page=` +
        page +
        "&search=" +
        search +
        "&roleId=" +
        roleId +
        "&date=" +
        filter_date +
        "&status=" +
        filter_status
      )
      .pipe(map((user) => user));
  }

  getAlmashinePayment(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getpayment?page=` +
        page +
        "&search=" +
        search +
        "&roleId=" +
        roleId +
        "&date=" +
        filter_date +
        "&status=" +
        filter_status
      )
      .pipe(map((user) => user));
  }

  getHackathon(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/hackathon?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getMarathonCancel(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getCancel?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglrMarathonCancel(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getCancel?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getMarathonAccept(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getAccept?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglrMarathonAccept(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getAccept?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getMarathonRefund(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getRefund?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getMarathonPending(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getPendingmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getbanglrMarathonPending(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getPendingmarena?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getMarathonPendingswim(
    page,
    search,
    category,
    tshirt,
    attendee_type
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getPendingswim?page=` +
        page +
        "&search=" +
        search +
        `&category=` +
        category +
        `&tshirt=` +
        tshirt +
        `&attendee_type=` +
        attendee_type
      )
      .pipe(map((user) => user));
  }

  getCategory() {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/category`)
      .pipe(map((user) => user));
  }

  getmarenaCategory() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getmarenacategories`
      )
      .pipe(map((user) => user));
  }

  getbanglrmarenaCategory() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getmarenacategories`
      )
      .pipe(map((user) => user));
  }

  getswimCategory() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getswimcategories`
      )
      .pipe(map((user) => user));
  }

  getSent() {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/sent-mail`)
      .pipe(map((user) => user));
  }
  submitEmail(subject, content, attachment) {
    const formData: FormData = new FormData();
    if (attachment) {
      formData.append("attachment", attachment, attachment.name);
    }
    formData.append("subject", subject);
    formData.append("content", content);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/send-mail`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  product_download(
    stock_filter,
    sku_filter,
    main_cat_filter,
    sub_cat_filter
  ): Promise<Blob> {
    var formData = {};
    formData["stock_filter"] = stock_filter;
    formData["sku_filter"] = sku_filter;
    formData["main_cat_filter"] = main_cat_filter;
    formData["sub_cat_filter"] = sub_cat_filter;

    formData["download"] = 1;

    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/excel/product_download?` +
        $.param(formData),
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getCategoryId(ids) {
    var formData = {};
    formData = {
      ids: ids,
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getcategories`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  // getTShirtSize() {
  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/t-shirt`).pipe(map(user => user));
  // }

  // getAmount() {
  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/gettotalCount`).pipe(map(user => user));
  // }

  getRegisterView(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration-lists?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }




  getbanglrRegisterView(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/registration-lists?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }

  getRegisterView1(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/viewPendingandapproved?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }


  getbanglrRegisterView1(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/viewPendingandapproved?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }
  getRegisterView2(id: any, roleId: any) {
    // http://localhost:3000/api/v1/admin/viewRejectedUserDetail?id=62fb3fc01e6f9d20c8dbaa82&roleId=100
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/viewRejectedUserDetail?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }

  getbanglrRegisterView2(id: any, roleId: any) {
    // http://localhost:3000/api/v1/admin/viewRejectedUserDetail?id=62fb3fc01e6f9d20c8dbaa82&roleId=100
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/viewRejectedUserDetail?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }

  getPaymentView(orderID: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/getpaymentsingle?_id=` +
        orderID
      )
      .pipe(map((user) => user));
  }

  getbanglrPaymentView(orderID: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/getpaymentsingle?_id=` +
        orderID
      )
      .pipe(map((user) => user));
  }

  getOldRecords(page, search, tshirt): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/old-records?page=` +
        page +
        "&search=" +
        search +
        `&tshirt=` +
        tshirt
      )
      .pipe(map((user) => user));
  }

  gethomeDash(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/gethomecount?roleId=` +
        this.roleId
      )
      .pipe(map((user) => user));
  }
  getbanglrhomeDash(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/gethomecount?roleId=` +
        this.roleId
      )
      .pipe(map((user) => user));
  }

  getCourseraDash(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/gethomecount?roleId=102`
      )
      .pipe(map((user) => user));
  }

  getAlmashineDash(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/gethomecount?roleId=104`
      )
      .pipe(map((user) => user));
  }

  // getShirt(): Observable<any> {
  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getshirtcount`).pipe(map(user => user));
  // }

  acceptUpdate(id: any, accept_status: any) {
    const formData: FormData = new FormData();
    formData.append("accept_status", accept_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/accept/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  acceptStatus(id: any, marenaStatus: any) {
    const formData: FormData = new FormData();
    formData.append("marenaStatus", marenaStatus);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/status/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  acceptbanglrStatus(id: any, marenaStatus: any) {
    const formData: FormData = new FormData();
    formData.append("marenaStatus", marenaStatus);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/status/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  acceptStatusswim(id: any, swimmingStatus: any) {
    const formData: FormData = new FormData();
    formData.append("swimmingStatus", swimmingStatus);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimstatus/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  rejectStatus(id: any, marenaStatus: any, reason: any) {
    console.log(id);
    const formData: FormData = new FormData();
    formData.append("marenaStatus", marenaStatus);
    formData.append("marenaRejectReason", reason);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/status/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  rejectbanglrStatus(id: any, marenaStatus: any, reason: any) {
    console.log(id);
    const formData: FormData = new FormData();
    formData.append("marenaStatus", marenaStatus);
    formData.append("marenaRejectReason", reason);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/status/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  rejectStatusswim(id: any, swimmingStatus: any, reason: any) {
    const formData: FormData = new FormData();
    formData.append("swimmingStatus", swimmingStatus);
    formData.append("swimRejectReason", reason);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimstatus/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  canelUpdate(id: any, cancel_status: any, status: any, refund_status: any) {
    const formData: FormData = new FormData();
    formData.append("cancel_status", cancel_status);
    formData.append("status", status);
    formData.append("refund_status", refund_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/cancel/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  statusUpdate(id: any, status: any, refund_status: any, cancel_status: any) {
    const formData: FormData = new FormData();
    formData.append("status", status);
    formData.append("refund_status", refund_status);
    formData.append("cancel_status", cancel_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/status/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  refundUpdate(id: any, refund_status: any, status: any) {
    const formData: FormData = new FormData();
    formData.append("refund_status", refund_status);
    formData.append("status", status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/refund/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  uploadExcel(file) {
    var formData = new FormData();
    formData.append("file", file);
    // formData.append('type', type);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/upload-excel`,
        formData
      )
      .pipe(map((user) => user));
  }
  //old-records

  getEdit(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration-lists?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }

  getbanglrmarenaEdit(id: any, roleId: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/registration-lists?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(map((user) => user));
  }

  download(search = "", filter = null, statefilter = null): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/report?search=` +
        search +
        `&filter=` +
        String(filter) +
        "&statefilter=" +
        statefilter,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  // this.cur_page,this.search_txt,this.roleId,this.filter_date
  getPaymentListDownload(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/downloadpaymentlogs?page=` +
        page +
        "&search=" +
        search +
        `&roleId=` +
        roleId +
        `&date=` +
        filter_date +
        `&status=` +
        filter_status,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbanglrPaymentListDownload(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/downloadpaymentlogs?page=` +
        page +
        "&search=" +
        search +
        `&roleId=` +
        roleId +
        `&date=` +
        filter_date +
        `&status=` +
        filter_status,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getAlmashinePaymentListDownload(
    page,
    search,
    roleId,
    filter_date,
    filter_status
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/downloadpaymentlogs?page=` +
        page +
        "&search=" +
        search +
        `&roleId=` +
        roleId +
        `&date=` +
        filter_date +
        `&status=` +
        filter_status,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getHackathonListDownload(): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/hackathon/download`,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  // getDownloadcancel(page, search, category, tshirt, attendee_type): Promise<Blob> {
  //     var formData = {};
  //     const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelcancel?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
  //         { responseType: 'blob' as 'json' }).toPromise();
  //     return file;
  // }

  // getDownloadrefund(page, search, category, tshirt, attendee_type): Promise<Blob> {
  //     var formData = {};
  //     const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-excelrefund?page=` + page + '&search=' + search + `&category=` + category + `&tshirt=` + tshirt + `&attendee_type=` + attendee_type, formData,
  //         { responseType: 'blob' as 'json' }).toPromise();
  //     return file;
  // }

  update(
    id: any,
    name: any,
    email: any,
    gender: any,
    age: any,
    category: any,
    phone_number: any,
    blood_group: any,
    t_shirt_size: any,
    em_contact_name: any,
    em_contact_num: any,
    type: any,
    vaccine_status: any,
    attendee_type: any,
    name_of_institute: any,
    reasearch_associate: any,
    register_no: any,
    year: any,
    course: any,
    employee_id: any,
    designation: any,
    department: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("age", age);
    formData.append("category", category);
    formData.append("phone_number", phone_number);
    formData.append("blood_group", blood_group);
    formData.append("t_shirt_size", t_shirt_size);
    formData.append("em_contact_name", em_contact_name);
    formData.append("em_contact_num", em_contact_num);
    formData.append("type", type);
    formData.append("vaccine_status", vaccine_status);
    formData.append("attendee_type", attendee_type);
    formData.append("name_of_institute", name_of_institute);
    formData.append("reasearch_associate", reasearch_associate);
    formData.append("register_no", register_no);
    formData.append("year", year);
    formData.append("course", course);
    formData.append("employee_id", employee_id);
    formData.append("designation", designation);
    formData.append("department", department);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  // name: any,email: any, gender:any,dob:any,phone_no:any,address:any,start_date:any,

  updatemarena(id: any, end_date: any, roleId: any) {
    const formData: FormData = new FormData();
    // formData.append('name', name);
    // formData.append('email', email);
    // formData.append('gender', gender);
    // formData.append('dob', dob);
    // formData.append('phone_no', phone_no);
    // formData.append('address', address);
    // formData.append('start_date', start_date);
    formData.append("validTill", end_date);
    // formData.append('roleId', roleId);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/registration?id=` +
        id +
        `&roleId=` +
        roleId,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updatebanglrmarena(id: any, end_date: any, roleId: any) {
    const formData: FormData = new FormData();
    formData.append("validTill", end_date);
    // formData.append('roleId', roleId);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/registration?id=` +
        id +
        `&roleId=` +
        roleId,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updateadhoc(
    id: any,
    userId: any,
    details: any,
    place: any,
    date: any,
    amount: any
  ) {
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("details", details);
    formData.append("date", date);
    formData.append("amount", amount);
    formData.append("place", place);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/updateAdhoc/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  saveadhoc(userId: any, details: any, place: any, date: any, amount: any) {
    const formData: FormData = new FormData();
    formData.append("userId", userId);
    formData.append("details", details);
    formData.append("date", date);
    formData.append("amount", amount);
    formData.append("place", place);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/createadhoc`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getAdhoc(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getAdhoc`)
      .pipe(map((user) => user));
  }

  deleteadhoc(id: string) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/adhoc/` + id
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  delete(id: string) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/user/` + id
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  delete1(id: string, roleId: any) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/membership?id=` +
        id +
        `&roleId=` +
        roleId
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getMareenaCategoryDetail(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getbanglrMareenaCategoryDetail(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/marenacategory/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getMareenaCategoryEdit(id: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/view/` +
        id
      )
      .pipe(map((user) => user));
  }

  getbanglrMareenaCategoryEdit(id: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/marenacategory/view/` +
        id
      )
      .pipe(map((user) => user));
  }

  marenaCategoryAdd(
    name: any,
    one_month_amount: any,
    year_amount: any,
    current_status: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("one_month_amount", one_month_amount);
    formData.append("year_amount", year_amount);
    formData.append("status", current_status);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/add`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  banglrmarenaCategoryAdd(
    name: any,
    one_month_amount: any,
    year_amount: any,
    current_status: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("one_month_amount", one_month_amount);
    formData.append("year_amount", year_amount);
    formData.append("status", current_status);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/marenacategory/add`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  marenaCategoryUpdate(
    id: any,
    name: any,
    one_month_amount: any,
    year_amount: any,
    six_months_amount: any,
    current_status: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("one_month_amount", one_month_amount);
    // formData.append('two_month_amount', two_month_amount);
    // formData.append('three_month_amount', three_month_amount);
    // formData.append('six_month_amount', six_month_amount);
    formData.append("year_amount", year_amount);
    formData.append("six_month_amount", six_months_amount);
    formData.append("status", current_status);
    // formData.append('roleId', roleId);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marenacategory/update/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }


  banglrmarenaCategoryUpdate(
    id: any,
    name: any,
    one_month_amount: any,
    year_amount: any,
    six_months_amount: any,
    current_status: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("one_month_amount", one_month_amount);
    // formData.append('two_month_amount', two_month_amount);
    // formData.append('three_month_amount', three_month_amount);
    // formData.append('six_month_amount', six_month_amount);
    formData.append("year_amount", year_amount);
    formData.append("six_month_amount", six_months_amount);
    formData.append("status", current_status);
    // formData.append('roleId', roleId);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/marena-blr/marenacategory/update/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }


  getSwimmingCategoryDetail(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getSwimmingCategoryEdit(id: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/view/` +
        id
      )
      .pipe(map((user) => user));
  }

  swimmingCategoryUpdate(
    id: any,
    name: any,
    studenthalfyear_fees: any,
    studentyear_fees: any,
    staffhalfyear_fees: any,
    staffyear_fees: any,
    otherhalfyear_fees: any,
    otheryear_fees: any,
    b_a_halfyear_fees: any,
    b_a_year_fees: any,
    b_u_halfyear_fees: any,
    b_u_year_fees: any,
    coaching_fees: any,
    month_fees: any,
    year_fees: any,
    b_a_month_fees: any,
    b_u_month_fees: any,
    current_status: any
  ) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("studenthalfyear_fees", studenthalfyear_fees);
    formData.append("studentyear_fees", studentyear_fees);
    formData.append("staffhalfyear_fees", staffhalfyear_fees);
    formData.append("staffyear_fees", staffyear_fees);
    formData.append("otherhalfyear_fees", otherhalfyear_fees);
    formData.append("otheryear_fees", otheryear_fees);
    formData.append("b_a_halfyear_fees", b_a_halfyear_fees);
    formData.append("b_a_year_fees", b_a_year_fees);
    formData.append("b_u_halfyear_fees", b_u_halfyear_fees);
    formData.append("b_u_year_fees", b_u_year_fees);
    formData.append("coaching_fees", coaching_fees);
    formData.append("month_fees", month_fees);
    formData.append("year_fees", year_fees);
    formData.append("b_a_month_fees", b_a_month_fees);
    formData.append("b_u_month_fees", b_u_month_fees);
    formData.append("status", current_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/update/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  checkUser(id: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/swimmingcategory/list?id=` +
        id
      )
      .pipe(map((user) => user));
  }

  getStudentStatusCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/students-count`
      )
      .pipe(map((user) => user));
  }

  getActiveStudentList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/active-students?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getalmashineregList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/listAllAlumni?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getalmashineregListSearch(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/searchAlumniAbove200?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getCourseraregListSearch(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/coursera/searchAlumniAbove200Coursera?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getalmashineregListSearchreapply(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/searchAlumni200?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  updateAlmashine(userid: any, phone) {
    var formData = {
      phone_no: phone,
    };
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/updateAlumnusDetail?id=` +
        userid,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updateCoursera(userid: any, phone) {
    var formData = {
      phone_no: phone,
    };
    return this.http
      .put<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/coursera/updateAlumnusCoursera?id=` +
        userid,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  uploadphdlist(file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append("file", file, file.name);
    }
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/phd-student-excel-add`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  uploadconferencelist(file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append("file", file, file.name);
    }
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/bangalore-conference/excel-import`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getphdCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/phd-students-count`
      )
      .pipe(map((user) => user));
  }

  getconferenceCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference/get-count`
      )
      .pipe(map((user) => user));
  }

  getActivephdList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/phd-active-students?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getInActivephdList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/phd-inactive-students?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  almashinedownload(page, search): Observable<any> {
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/download-almashine-registration-excel?search=` +
        search,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
    return file;
  }

  courseradownload(page, search): Observable<any> {
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/downloadExcelCoursera?search=` +
        search,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
    return file;
  }

  almashineImagesdownload(page, search): Observable<any> {
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/downloadAlumniImage?search=` +
        search,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
    return file;
  }

  getInActiveStudentList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/inactive-students?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getAlmashineRegisterCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/almashine/registeredCount`
      )
      .pipe(map((user) => user));
  }

  //staff
  getStaffStatusCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/staff-count`
      )
      .pipe(map((user) => user));
  }

  getStaffcron(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/latestCron?cronType=STAFF`
      )
      .pipe(map((user) => user));
  }

  getStudentcron(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/latestCron?cronType=STUDENT`
      )
      .pipe(map((user) => user));
  }

  getConferenceList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference/get-users?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  gettmapaiRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/tmapai/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getchessRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Chess2024/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }


  getmujoRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MUJO/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getagileRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ansysLumerical/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getbazaarRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/BazaarMic/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getcricketRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/StaffCricket2025/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  gettessellateRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Tessellate/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getvehicleRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/vehicleRegistrations/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getagleyRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Aley/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getcinaRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/CinA/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getbasketballRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MBL2024/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getdaycareRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MAGU/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getconferenceRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference2024/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getsolsticeRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TechSolsticeNexus/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }


  getkalafestRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/kala-fest/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  gethostelsRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MaheHostelsBengaluruCampus/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getreflektaRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/reflekta/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getadhikshanaRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/adhikshana/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  geterayaRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Falak/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getiqacRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/iqac/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  gettdwfeRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TDWFE/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getlawRegList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ConstructionLawSchoolBlr/list?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getkalafestpaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/kala-fest/count`
      )
      .pipe(map((user) => user));

  }
  gethostelpaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MaheHostelsBengaluruCampus/count`
      )
      .pipe(map((user) => user));

  }

  getreflektapaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/reflekta/count`
      )
      .pipe(map((user) => user));

  }

  getadhikshanapaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/adhikshana/count`
      )
      .pipe(map((user) => user));

  }

  geterayapaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Falak/count`
      )
      .pipe(map((user) => user));

  }

  getiqacpaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/iqac/count`
      )
      .pipe(map((user) => user));

  }

  gettdwfepaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TDWFE/count`
      )
      .pipe(map((user) => user));

  }

  getlawpaymentcount() {
    // /api/v1/admin/kala-fest/count
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ConstructionLawSchoolBlr/count`
      )
      .pipe(map((user) => user));

  }

  getchesspaymentcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Chess2024/count`
      )
      .pipe(map((user) => user));

  }

  getmujopaymentcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MUJO/count`
      )
      .pipe(map((user) => user));

  }

  getagilecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ansysLumerical/count`
      )
      .pipe(map((user) => user));

  }

  getaglecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Aley/count`
      )
      .pipe(map((user) => user));

  }
  getbazaarcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/BazaarMic/count`
      )
      .pipe(map((user) => user));

  }

  getcricketcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/StaffCricket2025/count`
      )
      .pipe(map((user) => user));

  }

  gettessellatecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Tessellate/count`
      )
      .pipe(map((user) => user));

  }

  getcinacount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/CinA/count`
      )
      .pipe(map((user) => user));

  }

  getvehiclecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/vehicleRegistrations/count`
      )
      .pipe(map((user) => user));

  }

  getbasketballcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MBL2024/count`
      )
      .pipe(map((user) => user));

  }

  getdaycarecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MAGU/count`
      )
      .pipe(map((user) => user));

  }

  getconferencecount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference2024/count`
      )
      .pipe(map((user) => user));

  }

  getsolsticepaymentcount() {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TechSolsticeNexus/count`
      )
      .pipe(map((user) => user));

  }

  getConferenceregList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference/get-payment?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getActiveStaffList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/active-staffs?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  getInActiveStaffList(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/edu/inactive-staffs?page=` +
        page +
        "&search=" +
        search
      )
      .pipe(map((user) => user));
  }

  //event
  quickpayCategoryAdd(
    name: any,
    description: any,
    start_date: any,
    end_date: any,
    collection_fees: any,
    gst: any
  ) {
    const formData: FormData = new FormData();
    formData.append("purpose", name);
    formData.append("description", description);
    formData.append("amount", collection_fees);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("gst", gst);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/create`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getSingleQuickCategoryDeatil(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/` + id
      )
      .pipe(map((user) => user));
  }

  quickpayCategoryEdit(
    id: any,
    name: any,
    description: any,
    start_date: any,
    end_date: any,
    collection_fees: any,
    gst: any
  ) {
    const formData: FormData = new FormData();
    formData.append("purpose", name);
    formData.append("description", description);
    formData.append("amount", collection_fees);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("gst", gst);
    return this.http
      .patch<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/update/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  //quick category list
  getQuickCategoryListStatusCount(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/count`
      )
      .pipe(map((user) => user));
  }

  getOngoingQuickCategoryList(page, search): Observable<any> {
    let status = 1;
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay?page=` +
        page +
        "&search=" +
        search +
        "&status=" +
        status
      )
      .pipe(map((user) => user));
  }

  getExpiredQuickCategoryList(page, search): Observable<any> {
    let status = 0;
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay?page=` +
        page +
        "&search=" +
        search +
        "&status=" +
        status
      )
      .pipe(map((user) => user));
  }

  deleteQuickCategoryList(id: any) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/delete/` +
        id
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getQuickpaymentPaymentList(page, search, filter_date): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/paymentlogs/list?page=` +
        page +
        "&search=" +
        search +
        "&date=" +
        filter_date
      )
      .pipe(map((user) => user));
  }

  getQuickpaymentPaymentListDownload(page, search, filter_date): Promise<Blob> {
    var formData = {};
    const file = this.http
      .post<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/paymentlogs/download?page=` +
        page +
        "&search=" +
        search +
        `&date=` +
        filter_date,
        formData,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  //quick pay institution
  quickPayInstitutionAdd(name: any, current_status: any) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("active", current_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/institution/create`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  quickPayInstitutionUpdate(id: any, name: any, current_status: any) {
    const formData: FormData = new FormData();
    formData.append("name", name);
    formData.append("active", current_status);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/institution/update/` +
        id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getQuickPayInstitutionList(page, search): Observable<any> {
    var status = null;
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/institution?page=` +
        page +
        "&search=" +
        search +
        "&status=" +
        status
      )
      .pipe(map((user) => user));
  }

  deleteQuicPayInstitutionList(id: any) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/institution/delete/` +
        id
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getSingleQuickPayInstitutionDeatil(id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/institution/` + id
      )
      .pipe(map((user) => user));
  }

  getQuickpayPaymentView(orderID: any) {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/quickpay/paymentlogs/list/` +
        orderID
      )
      .pipe(map((user) => user));
  }


  getkalafestDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/kala-fest/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  gethostelDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MaheHostelsBengaluruCampus/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getreflektaDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/reflekta/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getadhikshanaDownload(
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/adhikshana/download-excel
          `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  geterayaDownload(
  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Falak/download-excel
            `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }


  getiqacDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/iqac/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  gettdwfeDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TDWFE/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getlawDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ConstructionLawSchoolBlr/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getchessDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Chess2024/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getmujoDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MUJO/download-excel


            `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getagileDownload(type: any

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/ansysLumerical/download-excel?user_type=` + type,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getagleyDownload(type: any

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Aley/download-excel?user_type=` + type,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getbazaarDownload(type: any

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/BazaarMic/download-excel?user_type=` + type,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getcricketDownload(type: any

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/StaffCricket2025/download-excel` ,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  gettessellateDownload(type: any

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/Tessellate/download-excel` ,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getcinaDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/CinA/download-excel`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }


  getvehicleDownload(

    ): Promise<Blob> {
      var formData = {};
      const file = this.http
        .get<Blob>(
          `${environment.apiUrl}${environment.apiPrefix}/admin/vehicleRegistrations/download-excel`,
          { responseType: "blob" as "json" }
        )
        .toPromise();
      return file;
    }

    getvehiclepdfDownload(id:any

      ): Promise<Blob> {
        var formData = {};
        const file = this.http
          .get<Blob>(
            `${environment.apiUrl}${environment.apiPrefix}/admin/vehicleRegistrations/download-pdf?id=`+id,
            { responseType: "blob" as "json" }
          )
          .toPromise();
        return file;
      }

  getbasketballDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MBL2024/download-excel
    
    
                `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getdaycareDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/MAGU/download-excel
    
    
                `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  getconferenceDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/conference2024/download-excel
    
    
                `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  }

  gettechsolsticeDownload(

  ): Promise<Blob> {
    var formData = {};
    const file = this.http
      .get<Blob>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/TechSolsticeNexus/download-excel
  
  
              `,
        { responseType: "blob" as "json" }
      )
      .toPromise();
    return file;
  } s
}
