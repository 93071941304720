import { Component, OnInit, ɵɵqueryRefresh,ViewChild,ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';

declare var jquery: any;
declare var alertify: any;
declare var $: any;
@Component({
    templateUrl: 'upload_excel.component.html',
    styleUrls: ['upload_excel.component.css']
})
export class UploadExcelComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;

    cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    register_list = [];
    category = [];
    t_shirt = [];
    selected_category = null;
    selected_tshirt = null;
    media_domain = environment.media_domain;
    view_image = null;
    show_excel_upload = false;
    type = null;
    selected_file = null;
    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private router: Router) { }

        public async reportDown(): Promise<void> {
   
            this.submitted = true;
           
        this.loading = true;
        const product = [];
        
        
        // const blob = await this.pro.product_download(
        //   this.stock_filter,
        // this.sku_filter,
        // this.main_cat_filter,
        // this.sub_cat_filter
        
        
        //         );
        let binaryData = [];
        // binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
        // const url = window.URL.createObjectURL(blob);
        
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'products.xlsx';
        link.click();
        
        // window.URL.revokeObjectURL(url);
        this.loading = false;
        document.body.classList.remove('jw-modal-open');
        // link.stop();
        }

    ngOnInit() {
        this.refresh(true);
    }

    download() {
        location.href = '/assets/excel.xlsx?' + new Date().getTime();
      }

    refresh(reset = false) {
        this._marathon.getOldRecords(this.cur_page, this.search_txt, this.selected_tshirt).subscribe(data => {
            this.register_list = data.data.docs;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
        }, error => {

        });

        if (reset) {
            this._marathon.getCategory().subscribe(data => {
                this.category = data.data;
            }, error => {

            });

            // this._marathon.getTShirtSize().subscribe(data => {
            //     this.t_shirt = data.data;
            // }, error => {

            // });
        }
    }

    uploadExcelToggle(e) {
        this.show_excel_upload = true;
        this.resetFilter(null);
    }

    changeType(e) {
        this.type = e.target.value;
    }

    chooseFile(e) {
        this.selected_file = null;
        this.selected_file = e.target.files[0];
    }

    viewImage(e, obj) {
        e.stopPropagation();
        this.view_image = null;
        if (!obj.emp_id_image) {
            return false;
        }
        this.view_image = this.media_domain + obj.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }

    search(e) {
        this.search_txt = e.target.value;
        this.refresh();
    }

    changePage(page) {
        this.cur_page = page;
        this.refresh();
    }

    uploadExcel() {
        if (!this.selected_file) {
            this.toastr.error('Fields are required');
            return false;
        }
        alertify.confirm('Please Confirm', 'Are you sure you want to upload?', () => {
            this.loading = true;
            this._marathon.uploadExcel(this.selected_file).subscribe(data => {
                this.loading = false;
                if (data.status.code == 0) {
                    this.toastr.success(data.status.message);
                    this.resetFilter(null);
                    this.refresh();
                } else {
                    this.toastr.error(data.status.message);
                }
            }, error => {

            });
        }, () => {

        });
    }

    changeFilter(e, type) {
        e.stopPropagation();
        if (type === 'tshirt') {
            if (e.target.value === '-1') {
                this.selected_tshirt = null;
                this.refresh();
            } else {
                this.selected_tshirt = e.target.value;
            }
        }
        this.refresh();
    }

    resetFilter(e) {
        this.selected_category = null;
        this.selected_tshirt = null;
        this.search_txt = '';
        $('#search1').val('');
        this.refresh(true);
    }

}
