<ng-template #customLoadingTemplate> </ng-template>
<ngx-loading [show]="loading" class="loader" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header breadcrumb_area">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <ol class="breadcrumb cron_main">
                        <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
                        <li class="breadcrumb-item active">User List</li>
                        <li class="filler"></li>
                    </ol>
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid page_section">
            <div class="serach_sec">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order-head">Search</div>
                    </div>
                </div>
                <div class="search_cls">
                    <div class="row">
                        <div class="col-sm-4">
                            <input type="Search" name="search1" id="search1" (keyup.enter)="search($event)"
                                placeholder="Search Name, Email, Phone No." autocomplete="off" />
                        </div>
                        <div class="col-sm-1">
                            <div class="reset_btn">
                                <button (click)="resetFilter($event)" class="btn btn-secondary btn-sm">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="order_tabs">
                <div class="order_tabs">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: activeclass == 'active_student' }"
                                data-toggle="pill">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content">
                                        <span class="info-box-text">Active</span>

                                        <span class="info-box-number">
                                            {{
                                            totalcount
                                            ? (totalcount
                                            | number : "1.0" : "en-US")
                                            : 0
                                            }}
                                        </span>
                                        <span class="info-box-number" *ngIf="!roleId">0 </span>
                                    </div>
                                    <div _class="info-box-content">
                                        <i class="fa fa-user-check"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: activeclass == 'active_student' }"
                                data-toggle="pill">
                                <div class="info-box mb-3">
                                    <div _class="info-box-content">
                                        <span class="info-box-text">Total Payment</span>

                                        <span class="info-box-number">
                                            {{
                                            totalapayment
                                            ? (totalapayment
                                            | number : "1.0" : "en-US")
                                            : 0
                                            }}
                                        </span>
                                        <span class="info-box-number" *ngIf="!roleId">0 </span>
                                    </div>
                                    <div _class="info-box-content">
                                        <i class="fas fa-wallet"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <button (click)="downloadExcel('')" class="btn btn-primary btn-sm mb-2"
                            style="margin-right: 10px">
                            Download Excel
                        </button>
                      

                    </div>

                    <a style="display: none" #downloadZipLink class="btn btn-primary btn-sm"></a>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order_tabs">
                            <div class="tab-content">
                                <div class="tab-pane active" id="menu1">
                                    <div class="order_list">

                                        <div class="table-responsive">
                                            <table class="table table-hover dataTable admin_table">
                                                <thead>
                                                    <tr>

                                                        <th>Name</th>
                                                        <th>Email ID/Learner ID</th>
                                                        <th>Mobile No.</th>
                                                        <th>Registration Number</th>
                                                        <th>College Name</th>
                                                        <th>Event</th>
                                                        <th>User Type</th>
                                                        <!-- <th width="400px">Address</th> -->
                                                        <!-- <th width="150px">Dated On</th>
                            <th width="150px">MAHE ID/ Roll No.</th>
                            <th width="120px">Category</th>
                            <th>Mahe Staff</th> -->
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <ng-container *ngFor="
                              let list of student_list
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: cur_page,
                                      totalItems: totalcount
                                    };
                              let x = index
                            ">
                                                        <tr>
                                                            <td class="name_cls">
                                                                {{ list && list.name ? list.name : "-" }}
                                                            </td>

                                                            <td>
                                                                {{ list&& list.email ? list.email: "-" }}
                                                            </td>
                                                            <td>
                                                                {{ list && list.mobile ? list.mobile : "-" }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                list && list.registration_number
                                                                ? list.registration_number
                                                                : "-"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{ list && list.college_name ? list.college_name
                                                                : "-" }}
                                                            </td>
                                                            
                                                            <td>
                                                                {{
                                                                list && list.event_name
                                                                ? list.event_name
                                                                : "-"
                                                                }}
                                                            </td>
                                                            
                                                            <td>
                                                                {{
                                                                list && list.user_type
                                                                ? list.user_type
                                                                : "-"
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <tr *ngIf="!student_list.length">
                                                        <td colspan="9" class="text-center">
                                                            No Results Found
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="paginate">
                                        <p>
                                            Showing {{ from }} to {{ cur_page * 10 }} of
                                            {{ totalcount }} entries
                                        </p>
                                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                                            (pageChange)="changePage($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
</div>
