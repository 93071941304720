import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';
declare var $: any;

@Component({
  selector: 'app-tessellate-reg-list',
  templateUrl: './tessellate-reg-list.component.html',
  styleUrls: ['./tessellate-reg-list.component.css']
})
export class TessellateRegListComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  cur_page = 1;
  from = 0;
  perPage = 10;
  pageSize = 10;
  totalPages = 10;
  totalcount: any;
  search_txt = '';
  student_list = [];
  // totalcount: any;
  student: any;
  totalapayment: any;
  roleId = localStorage.getItem(`${environment.appName}` + '_role');
  activeclass = "active_student";
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

  pagevalue: any;
  studentcron: any;
  fileType: any;
  file: any;
  eventlist: any;


  constructor(
    private setting: SettingsService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _marathon: MarathonService,
    private http: HttpClient,
    private router: Router) { }
  public async reportDown(): Promise<void> {
    this.submitted = true;
    // this.loading = true;
    this.loading = false;

  }
  ngOnInit() {
    localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

    this._marathon.gettessellatecount().subscribe(data => {
      this.totalapayment = data.data[0].total_amount

    })

    this.refresh(true);


  }


  public async downloadExcel(type: any) {

    const blob = await this._marathon.gettessellateDownload(type);
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'Payment.xlsx';
    link.click();
    // }

  }




  refresh(reset = false) {

    this.activeclass = "active_student";
    this.loading = true;


    this._marathon.gettessellateRegList(this.cur_page, this.search_txt).subscribe(data => {
      this.loading = false;

      this.student_list = data.data.docs;
      this.totalcount = data.data.totalDocs;
      this.from = data.data.pagingCounter;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.pagevalue = 'active_student';


    }, error => {
    });

  }


  search(e) {
    e.stopPropagation();
    this.search_txt = e.target.value;
    this.changePage(1);
    //   this.loading = true;
    //  this.refresh()




  }

  changePage(page) {

    this.cur_page = page;
    this.refresh()

  }





  resetFilter(e) {
    this.search_txt = '';
    $('#search1').val('');
    this.refresh(true);
  }



}





