import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, MarathonService, ModalService, SettingsService } from '../_services';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
    templateUrl: 'adhoc-details.component.html',
    styleUrls: ['adhoc-details.component.css']
})
export class AdhocdetailsComponent implements OnInit {
    registration_form: FormGroup;
    loading = false;
    submitted = false;
    adminForm: FormGroup;
    types = false;
    student = false;
    faculty = false;
    research = false;
    res_data = false;
    dropdownuser = [];
    singleAdhoc: any;
    regId : any;
    userId: any;
    //table = false;
    //cancelButton = false;
    // onSubmit=false;

    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private _marathon: MarathonService,
        private location: Location,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    edit = null;
    id = this.route.snapshot.paramMap.get('id');
    media_domain = environment.media_domain;
    view_image = null;
    ngOnInit() {
        // alert(this.id)
        this.registration_form = this.formBuilder.group({
            index: [{ value: null, disabled: true }],
            _id: [{ value: null, disabled: true }],
            regid: ['', [Validators.required]],
            details: ['', [Validators.required]],
            date: ['', [Validators.required]],
            amount: ['', [Validators.required]],
            place: ['', [Validators.required]]
        });

        this._marathon.getUserdropdown().subscribe(data => {
            this.dropdownuser = data.data;
        })


        this.route.queryParams.subscribe(params => {
            this.id = params['id'];
            if (this.id) {
                this._marathon.getadhocSingle(this.id).subscribe(data => {
                    this.singleAdhoc = data.data;
                    this.regId = this.singleAdhoc.regId;
                    this.userId = this.singleAdhoc.userId;
                    this.registration_form.patchValue({
                        index: 1,
                        _id: this.singleAdhoc._id,
                        regid: this.regId,
                        details: this.singleAdhoc.details,
                        amount: this.singleAdhoc.amount,
                        date: this.singleAdhoc.date,
                        place: this.singleAdhoc.place,
                    });
                })
            }
        })


        // this._marathon.getUsersingle(this.id).subscribe(data => {

        //     this.edit = data.data;
        //     this.types = this.edit.type;
        //     if (this.edit.type == "STUDENT") {
        //         this.student = true;
        //         this.faculty = false;
        //     } else if (this.edit.type == "FACULTY") {
        //         this.faculty = true;
        //         this.student = false;
        //     } else {
        //         this.student = false;
        //         this.faculty = false;
        //     }
        //     if (this.edit.reasearch_associate == 'Yes') {
        //         this.research = true;
        //     } else if (this.edit.reasearch_associate == 'No') {
        //         this.res_data = true;
        //     } else {
        //         this.res_data = false;
        //         this.research = false;
        //     }
        //     this.registration_form.patchValue({
        //         index: 1,
        //         _id: this.edit._id ? this.edit._id : '-',
        //         name: this.edit.name ? this.edit.name : '-',
        //         email: this.edit.email ? this.edit.email : '-',
        //         gender: this.edit.gender ? this.edit.gender : '-',
        //         dob: this.edit.dob ? this.edit.dob : '-',
        //         phone_no: this.edit.phone_no ? this.edit.phone_no : '-',
        //     });
        // }, error => {

        // });
        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/


    }

    changeRegistration(e, t) {
        console.log(e)
    }

    goBack() {
        this.location.back();
    }

    get f() { return this.registration_form.controls; }


    onSubmit(form, formWrap) {
      
        this.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;
        console.log(this.registration_form)
        if (this.registration_form.invalid) {
            var that = this;
            that.toastr.error('Please enter all fields');
            return;
        }

        var that = this;
        console.log(that.regId)
        if (that.id) {
            alertify.confirm('Update', 'Are you sure want to update this record', function () {
                that._marathon.updateadhoc(that.id,
                    that.userId,
                    that.f.details.value,
                    that.f.place.value,
                    that.f.date.value,
                    that.f.amount.value
                )
                    .subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.router.navigate(['/adhoclist']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });
            }, function () {
                that.toastr.error('Update action cancelled')
            });
        } else {
            alertify.confirm('Create', 'Are you sure want to create this record', function () {
                that._marathon.saveadhoc(
                    that.f.regid.value,
                    that.f.details.value,
                    that.f.place.value,
                    that.f.date.value,
                    that.f.amount.value
                )
                    .subscribe(
                        data => {
                            if (data.status.code == 0) {
                                that.loading = true;
                                that.router.navigate(['/adhoclist']);
                            } else {
                                document.body.classList.remove('jw-modal-open');
                                that.loading = false;
                                that.toastr.error(data.status.message)
                            }
                        },
                        error => {
                            that.toastr.error(error);
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                        });
            }, function () {
                that.toastr.error('Create action cancelled')
            });
        }

    }

    submit() { }


    openImage() {
        this.view_image = null;
        this.view_image = this.media_domain + this.edit.emp_id_image;
        $('#exampleModalCenter').modal('show');
    }
    cancel(e) {
        e.stopPropagation();
        this.router.navigate(['/adhoclist']);
    }
}
