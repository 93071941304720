import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as buffer from 'buffer';

@Injectable()
export class DecryptionService {
  decrypt(et: any): any {
    if (et && (et.data)) {
      var data = et.data;
      let a = data + '='
      const Buffer = buffer.Buffer.from(a, 'base64');
      let base64 = Buffer.toString('utf-8');
      const db = buffer.Buffer.from(base64.split('.')[1], 'base64');
      let s = db.toString('utf-8');
      const bytes = CryptoJS.AES.decrypt(base64.split('.')[0], s);
      const finalText = bytes.toString(CryptoJS.enc.Utf8);
      
      return JSON.parse(finalText);
    }else{
      return {data : {} ,status : {}}
    }
  }
}