import { Component, OnInit, ɵɵqueryRefresh, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, SettingsService, MarathonService } from '../_services';
import { environment } from '../../environments/environment';
import { data } from 'jquery';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { element } from 'protractor';
declare var $: any;

@Component({
    selector: 'app-vehicle-reg-list',
    templateUrl: './vehicle-reg-list.component.html',
    styleUrls: ['./vehicle-reg-list.component.css']
})
export class VehicleRegListComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    cur_page = 1;
    from = 0;
    perPage = 10;
    pageSize = 10;
    totalPages = 10;
    totalcount: any;
    search_txt = '';
    student_list = [];
    // totalcount: any;
    student: any;
    totalapayment: any;
    roleId = localStorage.getItem(`${environment.appName}` + '_role');
    activeclass = "active_student";
    @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;

    pagevalue: any;
    studentcron: any;
    fileType: any;
    file: any;
    eventlist: any;


    constructor(
        private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private _marathon: MarathonService,
        private http: HttpClient,
        private router: Router) { }
    public async reportDown(): Promise<void> {
        this.submitted = true;
        // this.loading = true;
        this.loading = false;

    }
    ngOnInit() {
        localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

        this._marathon.getvehiclecount().subscribe(data => {
            this.totalapayment = data.data[0].total_amount

        })

        this.refresh(true);


    }


    public async downloadExcel(type: any) {


        // if(this.roleId == '100'){
        // const blob = await this._marathon.getPaymentListDownload(this.cur_page, this.search_txt, this.selected_category, this.selected_tshirt,"fhg");
        const blob = await this._marathon.getvehicleDownload();
        let binaryData = [];
        binaryData.push(blob);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = 'Payment.xlsx';
        link.click();
        // }

    }

    public async downloadpdf(list) {

        const blob = await this._marathon.getvehiclepdfDownload(list._id);
                const url = new Blob([blob], { type: 'application/pdf' });

                var downloadURL = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = downloadURL;
                link.download = "details.pdf";
                link.click();
           

    }




    active_student(e) {
        e.stopPropagation();

        this.activeclass = "active_student";


        localStorage.setItem(`${environment.appName}` + 'activeClick', 'active_student');

        this.cur_page = 1;

        this.student_list = [];



        this.loading = true;

        this._marathon.getvehicleRegList(1, this.search_txt).subscribe(data => {

            this.student_list = data.data.docs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'active_student';


        }, error => {
        });


    }








    event(event: any) {
        this.eventlist = event.event_name;
        $("#myModal").modal("show");
    }
    closeImage(e) {
        e.stopPropagation();
        $("#myModal").modal("hide");
        this.refresh();
    }

    refresh(reset = false) {

        this.activeclass = "active_student";
        this.loading = true;


        this._marathon.getvehicleRegList(this.cur_page, this.search_txt).subscribe(data => {

            this.loading = false;
            this.student_list = data.data.docs;
            console.log(this.student_list)
            this.totalcount = data.data.totalDocs;
            this.from = data.data.pagingCounter;
            this.cur_page = data.data.page;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'active_student';


        }, error => {
        });

    }


    search(e) {
        e.stopPropagation();

        this.search_txt = e.target.value;
        console.log("kl", this.search_txt)

        this.changePage(1);

        this.loading = true;
        //   this.refresh();
        this._marathon.getvehicleRegList(this.cur_page, this.search_txt).subscribe(data => {
            this.student_list = data.data.docs;
            this.loading = false;
            this.cur_page = data.data.page;
            this.totalcount = data.data.totalDocs;
            this.totalPages = data.data.totalPages;
            this.pagevalue = 'active_student';
            this.from = data.data.pagingCounter;

        }, error => {
        });




    }

    changePage(page) {

        this.cur_page = page;
        console.log(this.pagevalue);

        if (this.pagevalue == 'active_student') {

            // this.refresh();
            var that = this;


            that._marathon.getvehicleRegList(that.cur_page, that.search_txt).subscribe(data => {
                that.student_list = data.data.docs;
                that.cur_page = data.data.page;
                that.totalcount = data.data.totalDocs;
                that.totalPages = data.data.totalPages;
                that.from = data.data.pagingCounter;
                that.pagevalue = 'active_student';

            }, error => {
            });

        }
        else if (this.pagevalue == 'inactive_student') {
            this.student_list = [];



            this._marathon.getvehicleRegList(this.cur_page, this.search_txt).subscribe(data => {
                this.student_list = data.data.docs;
                this.cur_page = data.data.page;
                this.totalcount = data.data.totalDocs;
                this.from = data.data.pagingCounter;
                this.totalPages = data.data.totalPages;
                this.pagevalue = 'inactive_student';


            }, error => {
            });


        }
        else {
            this.refresh();
        }
    }



    resetFilter(e) {
        this.search_txt = '';
        $('#search1').val('');
        this.refresh(true);
    }

    sync() {
        var that = this;
        that._marathon.getStudent().subscribe(data => {
            that.toastr.success("Syncing is done");
        })
    }

}



